import { GameElementCategory } from "@shared/game-engine";

import { ComponentPicker } from "@app/editor";

const SUBTYPE_LIST = [GameElementCategory.STATIC];
const COMPONENT_LIST = ["shape"];

export default function EditorElementsPicker() {
  return <ComponentPicker builtin subtypes={SUBTYPE_LIST} uuids={COMPONENT_LIST} mode='flatten' search />;
}
