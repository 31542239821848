import { Box, Typography, useTheme } from "@mui/material";

import { type GameDefinitionDto } from "@shared/api-client";

import { BannerModal, Button, CompoundImage } from "@app/components";
import { useTranslation } from "@app/hooks";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import GamepadIcon from "@mui/icons-material/Gamepad";

interface Props {
  open: boolean;
  onClose: () => void;
  onEdit?: () => void;
  onPlay?: () => void;
  onClone?: () => void;
  onDelete?: () => void;
  game: GameDefinitionDto;
}

export function ProjectModal({ open, onClose, game, onEdit, onPlay, onClone, onDelete }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <BannerModal open={open} onClose={onClose} title={game.name}>
      {(game.fgImage || game.bgImage) && (
        <CompoundImage
          fg={game.fgImage}
          bg={game.bgImage}
          title={game.name}
          sx={{ height: 390, borderRadius: 1, overflow: "hidden" }}
        />
      )}
      <Box display='flex' flexDirection='column' p={theme.spacing(4)}>
        <Box display='flex' alignItems='flex-start'>
          <Box flexGrow={1}>
            <Typography variant='h6'>{game.name}</Typography>
            <Typography variant='subtitle1'>{game.category}</Typography>
          </Box>
          <Box flexShrink={1} display='flex' flexDirection='column' gap={1} alignItems='flex-end'>
            <Box display='flex' flexDirection='row' gap={1}>
              {onEdit && (
                <Button variant='outlined' onClick={onEdit} startIcon={<EditIcon />}>
                  {t("projects.edit")}
                </Button>
              )}
              {onPlay && (
                <Button variant='contained' onClick={onPlay} sx={{ whiteSpace: "nowrap" }} startIcon={<GamepadIcon />}>
                  {t("projects.play")}
                </Button>
              )}
            </Box>
            <Box sx={{ zoom: 0.825 }} display='flex' flexDirection='column' gap={1} alignItems='flex-end'>
              {onClone && (
                <Button variant='text' onClick={onClone} endIcon={<AddIcon />}>
                  {t("projects.clone")}
                </Button>
              )}
              {onDelete && (
                <Button variant='text' color='error' onClick={onDelete} endIcon={<DeleteForeverIcon />}>
                  {t("projects.delete")}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography variant='subtitle2' mt={theme.spacing(4)}>
            {game.description}
          </Typography>
        </Box>
      </Box>
    </BannerModal>
  );
}
