import { useContext } from "react";

import { EditorContext } from "@app/editor";

// Requires EditorContext
export function useEditor() {
  const context = useContext(EditorContext);

  if (!context) {
    throw new Error("Editor context not available");
  }

  return context;
}
