import { Box, Typography, useTheme } from "@mui/material";

import { useTranslation } from "@app/hooks";

import UfitoImg from "@shared/branding/imagery/art/ufito-cientifico-1.png";

export function ToDo() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' height='90%'>
      <img src={UfitoImg} height='200' />
      <Typography textAlign='center' display='block' variant='h3' sx={{ margin: theme.spacing(8, 0, 0, 0) }}>
        {t("todo.title")}
      </Typography>
      <Typography textAlign='center' display='block' variant='h4' sx={{ margin: theme.spacing(2, 0, 0, 0) }}>
        {t("todo.description")}
      </Typography>
    </Box>
  );
}
