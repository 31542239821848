import { Box, Container, useTheme } from "@mui/material";

import { ErrorContent, type ErrorContentProps } from "@app/components";

export function FullScreenError(props: ErrorContentProps) {
  const theme = useTheme();

  return (
    <Container>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        sx={{ padding: theme.spacing(5), height: "100vh" }}
      >
        <ErrorContent {...props} />
      </Box>
    </Container>
  );
}
