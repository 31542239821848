import { Box, Typography, useTheme } from "@mui/material";

import { type GameDefinitionDto } from "@shared/api-client";

import { BannerModal, Button, CompoundImage } from "@app/components";
import { useTranslation } from "@app/hooks";
import { useCallback } from "react";
import { Link, useNavigate } from "@app/router";
import { GameStatus } from "@app/types";

import GamepadIcon from "@mui/icons-material/Gamepad";
import EditIcon from "@mui/icons-material/Edit";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: (template: GameDefinitionDto) => Promise<GameDefinitionDto>;
  template: GameDefinitionDto;
}

export function TemplateModal({ open, onClose, onConfirm, template }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const doConfirm = useCallback(async () => {
    if (!onConfirm) return;
    return onConfirm?.(template);
  }, [onConfirm, template]);

  const active = template.status === GameStatus.PUBLISHED;

  return (
    <BannerModal
      open={open}
      onClose={onClose}
      title={t(template.uuid ? "create.fromTemplate" : "create.createGame").toString()}
    >
      {(template.fgImage || template.bgImage) && (
        <CompoundImage
          fg={template.fgImage}
          bg={template.bgImage}
          title={template.name}
          sx={{ height: 390, borderRadius: 1, overflow: "hidden" }}
        />
      )}
      <Box display='flex' flexDirection='column' p={theme.spacing(4)}>
        <Box display='flex' alignItems='flex-start'>
          <Box flexGrow={1}>
            <Typography variant='h6'>{template.name}</Typography>
            <Typography variant='subtitle1'>{template.category}</Typography>
          </Box>
          <Box flexShrink={1}>
            {active && template.uuid ? (
              <Link
                to={{ pathname: "/preview/:uuid/:version" }}
                params={{ uuid: template.uuid, version: String(template.version) }}
                target='theufolab-preview'
              >
                <Button variant='outlined' sx={{ whiteSpace: "nowrap" }} startIcon={<GamepadIcon />}>
                  {t("create.previewTemplate")}
                </Button>
              </Link>
            ) : null}
            {active && (
              <Button
                variant='contained'
                startIcon={<EditIcon />}
                onClick={doConfirm}
                onClickSuccess={(project) =>
                  // Jump to the Creation Tour page
                  project &&
                  navigate("/dashboard/projects/:uuid/v/:version/tour", {
                    params: { uuid: project.uuid, version: String(project.lastVersion || project.currentVersion || 1) }
                  })
                }
                sx={{ whiteSpace: "nowrap", ml: 2 }}
              >
                {t(template.uuid ? "create.useTemplate" : "create.createGame")}
              </Button>
            )}
            {!active && (
              <Button variant='outlined' disabled sx={{ whiteSpace: "nowrap" }}>
                {t("create.comingSoon")}
              </Button>
            )}
          </Box>
        </Box>
        <Box>
          <Typography variant='subtitle2' mt={theme.spacing(4)}>
            {template.description}
          </Typography>
        </Box>
      </Box>
    </BannerModal>
  );
}
