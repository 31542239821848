import isNil from "lodash/isNil";
import { type Dispatch, type SetStateAction, useState, useEffect } from "react";

type PersistMode = "local" | "session";

function getStorage(mode: PersistMode) {
  if (mode === "local") {
    return localStorage;
  } else {
    return sessionStorage;
  }
}

/**
 * Hook to use a state that is persisted in local storage
 *
 * @param key - The key to use for the local storage
 * @param defaultValue - The default value to use if the key is not found in local storage
 * @param mode - The mode to use for the local storage: "local" or "session". Defaults to "local".
 * @returns A tuple with the state and a function to set the state
 */
export function usePersistedState<T>(
  key: string,
  defaultValue?: T,
  mode: PersistMode = "local"
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(() => {
    try {
      const value = getStorage(mode).getItem(key);
      return !isNil(value) ? JSON.parse(value) : defaultValue;
    } catch (err) {
      console.warn(`Invalid persisted state for key ${key}. Resetting to default value.`);
      return defaultValue;
    }
  });

  useEffect(() => {
    if (isNil(state)) {
      getStorage(mode).removeItem(key);
    } else {
      getStorage(mode).setItem(key, JSON.stringify(state));
    }
  }, [key, state, mode]);

  return [state, setState];
}
