export interface UrlSettings {
  assetBaseUrl: string;
  pluginBaseUrl: string;
  version?: string | number;
}

/**
 * Get the thumbnail URL.
 * - Replace the asset:// or plugin:// prefix with the assetBaseUrl or pluginBaseUrl.
 * - Replace the {key} with the value from the obj (only works first level).
 *
 * @param uri
 * @param obj
 * @param settings
 * @returns
 */
export function getAssetUrl(
  uri: string,
  obj?: Record<string, unknown>,
  settings?: UrlSettings
): string {
  return uri
    .replace(/^asset:\/\//, settings?.assetBaseUrl || "") // TODO: Reference ASSET_PROTOCOL
    .replace(/^plugin:\/\//, settings?.pluginBaseUrl || "") // TODO: Create PLUGIN_PROTOCOL
    .replace(/\{([a-z0-9_\-.]+)\}/gi, (match: string, key: string) => {
      return obj ? getAssetUrl(String(obj[key]), obj, settings) : match;
    })
    .concat(settings?.version ? `?v=${settings.version}` : "");
}
