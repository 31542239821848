/**
 * Basic Markdown to HTML conversion. Supported only a subset of Markdown language:
 * - line breaks
 * - **bold**
 * - *italic*
 * - `code`
 * - color?
 *
 * Escapes HTML tags.
 *
 * @param md
 * @returns
 */
export function renderMD(md: string): string {
  return (
    md
      // escape HTML tags
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")

      // line breaks
      .replace(/\n/g, "<br>")

      // bold
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")

      // italic
      .replace(/\*(.*?)\*/g, "<em>$1</em>")

      // code inline/blocks
      .replace(/`(.*?)`/g, "<code>$1</code>")
      .replace(/```(.*?)```/g, "<code>$1</code>")
  );
}
