import { type ReactNode } from "react";
import { Box, ButtonGroup, Tooltip, Typography, useTheme } from "@mui/material";

import { type MenuItem } from "@app/types";
import { Button } from "@app/components";
import { Link } from "@app/router";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface Props {
  title: ReactNode;
  actions?: MenuItem[];
  info?: ReactNode;
  onClick?: () => void;
}

export function SectionTitle({ title, actions = [], info, onClick }: Props) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: theme.spacing(6, 0, 4, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        "&:first-of-type": {
          marginTop: 0
        },
        cursor: onClick ? "pointer" : "default"
      }}
      onClick={onClick}
    >
      <Typography
        display='block'
        variant='h4'
        sx={{
          fontWeight: 200,
          fontSize: "1.7rem"
        }}
      >
        {title}
      </Typography>
      <ButtonGroup>
        {actions.map((action) => (
          <Button
            key={action.key}
            variant='text'
            onClick={
              action.onClick
                ? (e) => {
                    action.onClick?.();
                    e.stopPropagation();
                  }
                : undefined
            }
          >
            {action.link ? (
              <Link to={{ pathname: action.link } as never}>
                {action.icon} {action.label}
              </Link>
            ) : (
              <>
                {action.icon} {action.label}
              </>
            )}
          </Button>
        ))}
      </ButtonGroup>
      {info && (
        <Tooltip title={info}>
          <InfoOutlinedIcon />
        </Tooltip>
      )}
    </Box>
  );
}
