import { Outlet } from "react-router-dom";
import { Container, Tab, Tabs } from "@mui/material";

import { useAppContext, useTranslation } from "@app/hooks";
import { type Path, useNavigate, useParams } from "@app/router";

import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ColorLensIcon from "@mui/icons-material/ColorLens";

export default function CreatePage() {
  const { uuid, version } = useParams("/dashboard/projects/:uuid/v/:version/settings");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentMatchedRoute } = useAppContext();

  const handleTabChange = (_event: React.SyntheticEvent, value: Path) => {
    navigate(value, { params: { uuid, version } });
  };

  return (
    <Container>
      <Tabs centered onChange={handleTabChange} value={currentMatchedRoute}>
        <Tab
          label={t("editProject.general.menu")}
          icon={<SettingsApplicationsIcon />}
          iconPosition='start'
          value='/dashboard/projects/:uuid/v/:version/tour/general'
          aria-controls='settings-tab-general'
        />
        <Tab
          label={t("editProject.canvas.menu")}
          icon={<ColorLensIcon />}
          iconPosition='start'
          value='/dashboard/projects/:uuid/v/:version/tour/canvas'
          aria-controls='settings-tab-canvas'
          disabled
        />
      </Tabs>

      <Outlet />
    </Container>
  );
}
