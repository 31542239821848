import { Typography, useTheme } from "@mui/material";
import { type FallbackProps } from "react-error-boundary";

import { ApiRestError } from "@shared/api-client";
import { useTranslation } from "@app/hooks";

import UfitoImg from "@shared/branding/imagery/art/ufito-cientifico-1.png";

export interface ErrorContentProps extends FallbackProps {
  error: Error | ApiRestError;
}

export function ErrorContent({ error }: ErrorContentProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <img src={UfitoImg} height='200' />
      <Typography textAlign='center' display='block' variant='h1' sx={{ margin: theme.spacing(8, 0, 0, 0) }}>
        {error instanceof ApiRestError ? error.status : 500}
      </Typography>
      <Typography textAlign='center' display='block' variant='h2' sx={{ margin: theme.spacing(2, 0, 0, 0) }}>
        {error?.message || t("500.title")}
      </Typography>
      {error instanceof ApiRestError && error.requestId ? (
        <Typography textAlign='center' display='block' variant='caption' sx={{ margin: theme.spacing(2, 0, 0, 0) }}>
          &lt;{error.requestId}&gt;
        </Typography>
      ) : null}
    </>
  );
}
