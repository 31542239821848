import { Box, Container, Typography, useTheme } from "@mui/material";

import { useTranslation } from "@app/hooks";

import UfitoImg from "@shared/branding/imagery/art/ufito-dubitativo-1.png";

export default function NotFoundPage() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        sx={{ padding: theme.spacing(5), height: "100vh" }}
      >
        <img src={UfitoImg} height='200' />
        <Typography textAlign='center' display='block' variant='h1' sx={{ margin: theme.spacing(8, 0, 0, 0) }}>
          404
        </Typography>
        <Typography textAlign='center' display='block' variant='h2' sx={{ margin: theme.spacing(2, 0, 0, 0) }}>
          {t("404.title")}
        </Typography>
      </Box>
    </Container>
  );
}
