import type { JsonSchema, JsonUISchema } from "@shared/form-builder";

export const GameScoringSchema: JsonSchema = {
  type: "object",
  title: "Puntuaciónes (Deprecado)",
  description:
    "Configuración de puntuaciónes para el juego. Este es un campo deprecado y no se recomienda su uso. Usar acciones en objetos y nivel en su lugar.",
  properties: {
    requestClue: {
      type: "number",
      title: "A restar al solicitar pista",
      minimum: 0
    },
    collectItem: {
      type: "number",
      title: "A sumar al recoger un objeto del inventario",
      minimum: 0
    },
    unlockSuccess: {
      type: "number",
      title: "A sumar al desbloquear un puzzle con éxito",
      minimum: 0
    },
    unlockFailed: {
      type: "number",
      title: "A restar al fallar al desbloquear un puzzle",
      minimum: 0
    },
    endSecondsLeft: {
      type: "number",
      title: "A sumar al finalizar, por cada segundo restante",
      minimum: 0
    },
    completeGame: {
      type: "number",
      title: "A sumar al completar el juego",
      minimum: 0
    }
  }
};

export const GameScoringUISchema: JsonUISchema = {
  type: "VerticalLayout",
  elements: [
    // Unused
    // {
    //   type: "HorizontalLayout",
    //   elements: [
    //     {
    //       type: "Control",
    //       scope: "#/properties/requestClue",
    //       options: { icon: "settings", flatStyle: true }
    //     },
    //     {
    //       type: "Control",
    //       scope: "#/properties/collectItem",
    //       options: { icon: "settings", flatStyle: true }
    //     }
    //   ]
    // },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/unlockSuccess",
          options: { icon: "settings", flatStyle: true }
        },
        {
          type: "Control",
          scope: "#/properties/unlockFailed",
          options: { icon: "settings", flatStyle: true }
        }
      ]
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/endSecondsLeft",
          options: { icon: "settings", flatStyle: true }
        },
        {
          type: "Control",
          scope: "#/properties/completeGame",
          options: { icon: "settings", flatStyle: true }
        }
      ]
    }
  ]
};
