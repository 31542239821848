import { ApiRestClient } from "../ApiRest";
import { GameCategoryDto, GameDefinitionDto } from "../dtos";

type Routes = {
  "/templates": GameDefinitionDto;
  "/categories": GameCategoryDto;
};

export class DashboardApiClient extends ApiRestClient<Routes> {
  constructor(baseUrl: string) {
    super(baseUrl.replace(/\/$/, "") + "/");
  }
}
