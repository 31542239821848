import type { JsonSchema } from "@shared/form-builder";
import { type GameDataInfoDto } from "@shared/api-client";
import { getGameFlowSchema } from "./game.flow.schema";
import { getGameTimerSchema } from "./game.timer.schema";
import { GameScoringSchema } from "./game.scoring.schema";
import { GameScreenSchema } from "./game.screen.schema";
import { GameThemeSchema } from "./game.theme.schema";

export function getGameDataSchema(gameInfo?: GameDataInfoDto): JsonSchema {
  return {
    type: "object",
    properties: {
      gameflow: getGameFlowSchema(gameInfo),
      timer: getGameTimerSchema(gameInfo),
      scoring: GameScoringSchema,
      screem: GameScreenSchema,
      theme: GameThemeSchema
    }
  };
}

// UI Schema to be defined
