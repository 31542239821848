import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { Box, Card, IconButton, Tooltip, Typography, alpha, styled } from "@mui/material";
import { useDrag, useDrop } from "react-dnd";

import { Prompt } from "@app/components";
import { useAppContext, useHorizontalScroll, useTranslation } from "@app/hooks";

import { DEFAULT_SCREEN, type GameLayerContent } from "@shared/game-engine";
import { type GameLevelTreeDto } from "@shared/api-client";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import DeleteIcon from "@mui/icons-material/Delete";
import CloneIcon from "@mui/icons-material/FileCopy";
import EditIcon from "@mui/icons-material/Edit";
import { useEditor } from "../useEditor";
import { type EditMode } from "../types";

const COMPONENT_HEIGHT = 80;

/**
 * Flatten the level tree into a list of tuples with the level id and the level data
 *
 * @param levelIds
 * @param tree
 * @returns
 */

function levelSorter(levelIds: string[], tree?: GameLevelTreeDto, initialLevelId?: string): string[] {
  if (!tree?.length || !initialLevelId) {
    return levelIds;
  }

  const pending = new Set(levelIds);
  const sorted: string[] = [];

  const visit = (levelId: string) => {
    if (!pending.has(levelId)) {
      return;
    }

    pending.delete(levelId);
    sorted.push(levelId);

    const node = tree.find((node) => node.levelId === levelId);

    if (!node) {
      return;
    }

    node.childrenIds.forEach(visit);
  };

  visit(initialLevelId);

  return [...sorted, ...pending];
}

/**
 * Check if a level is referenced in the tree
 *
 * @param levelId
 * @param tree
 * @returns
 */
function isLevelReferenced(levelId: string, tree?: GameLevelTreeDto): boolean {
  if (!tree) {
    return false;
  }

  const node = tree.find((node) => node.levelId === levelId);

  if (!node) {
    return false;
  }

  return node.parentIds.length > 0;
}

interface Props {
  mode: EditMode;
}

const LevelsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  justifyContent: "stretch",
  height: "100%",

  "&.overlays": {
    BackgroundColor: alpha(theme.palette.primary.main, 0.2)
  }
}));

const Actions = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(1),
  borderRight: `1px solid ${theme.palette.divider}`
}));

const ScrollContainer = styled("div")(() => ({ theme }) => ({
  flex: 1,
  width: "100%",
  overflowX: "auto",
  overflowY: "hidden",
  scrollbarColor: `${theme.palette.primary.dark} transparent`
}));

const LevelList = styled(Box)(() => ({
  margin: 0,
  height: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap"
}));

const LevelItemBox = styled(Box)(({ theme }) => ({
  height: "100%",
  flex: "0 0 auto",
  padding: theme.spacing(2, 2),
  border: `0px solid ${theme.palette.divider}`,

  transition: theme.transitions.create(["backgroundColor", "transform", "border"], {
    duration: theme.transitions.duration.shortest
  }),

  "&.is-selected": {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: "-2px",

    ">.MuiCard-root:hover": {
      transform: "none"
    }
  },

  "&.has-reference .level-card": {
    backgroundColor: alpha(theme.palette.warning.light, 0.5)
  },

  "&.is-start .level-card": {
    backgroundColor: alpha(theme.palette.success.light, 0.5),
    fontWeight: "bold"
  },

  "&.is-finish .level-card": {
    backgroundColor: alpha(theme.palette.success.light, 0.5)
  },

  "&.is-game-over .level-card": {
    backgroundColor: alpha(theme.palette.error.light, 0.5)
  },

  "&.is-dragging": {
    opacity: 0.5
  },

  "&.hover-left": {
    borderLeftWidth: "32px"
  }
}));

const LevelCard = styled(Card)(({ theme }) => ({
  height: `${COMPONENT_HEIGHT}px`,
  minWidth: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1),
  cursor: "pointer",

  transition: theme.transitions.create(["transform", "backgroundColor"], {
    duration: theme.transitions.duration.shortest
  }),

  "&:hover": {
    transform: "scale(1.03)"
  }
}));

const LevelFooter = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center"
}));

const LevelImage = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "contain"
}));

interface LevelPickerItemProps {
  id: string;
  index: number;
  level?: Pick<GameLayerContent, "_thumbnail" | "translations">;
  selected: boolean;
  hoverLeft?: boolean;
  onClone: (id: string) => void;
  onDelete: (id: string) => void;
  onRename: (id: string) => void;
  onDragMove: (dragIndex: number, hoverIndex: number) => void;
  onDragEnd: () => void;
}

interface DragItem {
  index: number;
  id: string;
}

function LevelPickerItem({
  id,
  index,
  level,
  selected,
  hoverLeft,
  onClone,
  onDelete,
  onRename,
  onDragMove,
  onDragEnd
}: LevelPickerItemProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { gameData, gameDataInfo, setLevel } = useEditor();
  const { t, language } = useTranslation();

  const [move, setMove] = useState<string>();

  // Drag level
  const [{ isDragging }, drag] = useDrag<DragItem, void, { isDragging: boolean }>(() => ({
    type: "level-picker",
    item: () => ({ index, id }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    end: () => setMove(undefined)
  }));

  const [, drop] = useDrop<DragItem>(() => ({
    accept: "level-picker",

    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;

      // Scroll into view
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      const isLeft = hoverClientX < hoverMiddleX;

      // Time to actually perform the action
      // Using string to avoid extra useEffect calls
      setMove([dragIndex, index + (isLeft ? 0 : 1)].join("|"));
    }
  }));

  // Actual move
  useEffect(() => {
    if (move) {
      const [dragIndex, hoverIndex] = move.split("|").map(Number);
      onDragMove(dragIndex, hoverIndex);
    } else {
      onDragEnd();
    }
  }, [move, onDragEnd, onDragMove]);

  const screen = gameData?.screen || DEFAULT_SCREEN;
  const isReferenced = isLevelReferenced(id, gameDataInfo?.levelTree);
  const isStartLevel = gameData?.gameflow?.initialLevelId === id;
  const isGameOverLevel = gameData?.gameflow?.gameOverLevelId === id;
  const isFinishLevel = gameData?.gameflow?.finishLevelId === id;
  const isProtected = isStartLevel || isGameOverLevel || isFinishLevel;

  const classes = [
    selected && "is-selected",
    isReferenced && "has-reference",
    isStartLevel && "is-start",
    isGameOverLevel && "is-game-over",
    isFinishLevel && "is-finish",
    isDragging && "is-dragging",
    hoverLeft && "hover-left"
  ].filter(Boolean) as string[];

  const handleClone = useCallback(() => {
    onClone(id);
  }, [id, onClone]);

  const handleRename = useCallback(() => {
    onRename(id);
  }, [id, onRename]);

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  const handleSwitchLevel = useCallback(() => {
    setLevel(id);
  }, [id, setLevel]);

  useEffect(() => {
    if (selected && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [gameData, selected]);

  const cardSx = useMemo(() => ({ width: (COMPONENT_HEIGHT / screen.height) * screen.width }), [screen]);

  drag(drop(ref));

  return (
    <LevelItemBox key={id} className={classes.join(" ")} ref={ref}>
      <LevelCard onClick={handleSwitchLevel} sx={cardSx} className='level-card'>
        {level?._thumbnail ? (
          <LevelImage src={level._thumbnail} alt={id} />
        ) : (
          <QuestionMarkIcon fontSize='large' sx={{ opacity: 0.3 }} />
        )}
      </LevelCard>
      <LevelFooter sx={{ zoom: 0.8 }}>
        <Typography variant='body1' align='left'>
          {1 + index} | {level?.translations?.[language]?.name || id}
        </Typography>
        <Box>
          <IconButton title={t("editor.renameLevel").toString()} onClick={handleRename}>
            <EditIcon fontSize='small' color='disabled' />
          </IconButton>
          <IconButton title={t("editor.cloneLevel").toString()} onClick={handleClone}>
            <CloneIcon fontSize='small' color='disabled' />
          </IconButton>
          {!isProtected && (
            <IconButton onClick={handleDelete} color='error' title={t("editor.deleteLevel").toString()}>
              <DeleteIcon fontSize='small' />
            </IconButton>
          )}
        </Box>
      </LevelFooter>
    </LevelItemBox>
  );
}

export function LevelPicker({ mode }: Props) {
  const { notify, confirm } = useAppContext();
  const { level, overlay, gameData, gameDataInfo, updateGameData } = useEditor();
  const { t } = useTranslation();
  const scroll = useHorizontalScroll();

  const [cloneLevel, setCloneLevel] = useState<string>();
  const [renameLevel, setRenameLevel] = useState<string>();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [isDragging, setIsDragging] = useState(false);
  const [levelDrag, setLevelDrag] = useState<[number, number]>();
  const [sortedLevels, setSortedLevels] = useState<string[]>([]);

  const selected = mode === "overlay" ? level?.data?.overlay : level?.id;
  const levelTree = gameDataInfo?.levelTree || undefined;
  const targetLayer = mode === "overlay" ? overlay : level;

  // Does nothing. It's just to prevent the animation back when drop
  // a level at the end of the list
  const [, drop] = useDrop<DragItem>(() => ({
    accept: "level-picker"
  }));

  useEffect(() => {
    if (mode === "overlay") {
      setSortedLevels(Object.keys(gameData?.overlays || {}));
    } else {
      const actualLevels = Object.keys(gameData?.levels || {});

      if (gameData?.levelOrder) {
        // User defined order. Remove invalid ones. Append any missing levels at the end
        const order = Array.from(
          new Set([...gameData.levelOrder.filter((lev) => actualLevels.includes(lev)), ...actualLevels])
        );
        setSortedLevels(order);
      } else {
        // Automatic order based on the level tree
        setSortedLevels(levelSorter(actualLevels, levelTree, gameData?.gameflow?.initialLevelId));
      }
    }
  }, [gameData?.gameflow?.initialLevelId, gameData?.levels, gameData?.levelOrder, gameData?.overlays, levelTree, mode]);

  const handleDragMove = useCallback((dragIndex: number, hoverIndex: number) => {
    setLevelDrag([dragIndex, hoverIndex]);
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleCreateLevel = useCallback(
    async (id: string) => {
      if (!id) return;

      try {
        await targetLayer?.create(id, {
          copyFrom: cloneLevel,
          // Populate current overlay to the new level
          overlay: mode === "level" ? level?.data?.overlay : undefined
        });

        setShowCreateModal(false);
      } catch (error) {
        notify(t("editor.errors.createLevelError", { error }), "error");
      }
    },
    [cloneLevel, level?.data?.overlay, mode, notify, t, targetLayer]
  );

  const handleCloneLevel = useCallback(async (id: string) => {
    setCloneLevel(id);
    setShowCreateModal(true);
  }, []);

  const handleRenameLevel = useCallback(
    async (newId: string) => {
      if (!newId || !renameLevel) return;

      try {
        await targetLayer?.rename(renameLevel, newId);
        setRenameLevel(undefined);
      } catch (error) {
        notify(t("editor.errors.renameLevelError", { error }), "error");
      }
    },
    [renameLevel, targetLayer, notify, t]
  );

  const handleDeleteLevel = useCallback(
    async (id: string) => {
      try {
        const isReferenced = isLevelReferenced(id, levelTree);

        await confirm({
          title: t("editor.deleteLevel"),
          content: t(isReferenced ? "editor.deleteLevelConfirmWithWarning" : "editor.deleteLevelConfirm", { id })
        });

        await targetLayer?.remove(id);
      } catch (error) {
        if (!error) {
          // User cancelled
          return;
        }

        notify(t("editor.deleteError", { error }), "error");
      }
    },
    [levelTree, confirm, t, targetLayer, notify]
  );

  // Level order update and save
  useEffect(() => {
    if (mode !== "level" || isDragging || !levelDrag) {
      return;
    }

    const [dragIndex, hoverIndex] = levelDrag;

    // Shortcut. No change
    if (dragIndex === hoverIndex) {
      setLevelDrag(undefined);
      return;
    }

    const newOrder: string[] = [];
    for (let i = 0; i < Math.max(sortedLevels.length, hoverIndex + 1); i++) {
      if (i === dragIndex) {
        continue;
      }

      if (i === hoverIndex) {
        newOrder.push(sortedLevels[dragIndex]);
      }

      newOrder.push(sortedLevels[i]);
    }

    // Update the level order
    setSortedLevels(newOrder.filter(Boolean));
    setLevelDrag(undefined);
    updateGameData({ levelOrder: newOrder.filter(Boolean) });
  }, [isDragging, levelDrag, mode, sortedLevels, updateGameData]);

  return (
    <LevelsContainer className={mode}>
      <Actions>
        <Tooltip title={t("editor.createLevel")}>
          <IconButton aria-label={t("editor.createLevel").toString()} onClick={() => setShowCreateModal(true)}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>
        <Box flexGrow={1} />
      </Actions>
      <ScrollContainer ref={scroll}>
        <LevelList ref={drop}>
          {sortedLevels.map((id, index) => (
            <LevelPickerItem
              // index must be included so that the item is re-rendered when the order changes (because of useDrag)
              key={`${id}-${index}`}
              id={id}
              index={index}
              level={mode === "overlay" ? gameData?.overlays?.[id] : gameData?.levels?.[id]}
              selected={id === selected}
              onClone={handleCloneLevel}
              onDelete={handleDeleteLevel}
              onRename={setRenameLevel}
              onDragMove={handleDragMove}
              onDragEnd={handleDragEnd}
              // Do not indicate if same level is dragged over itself
              hoverLeft={levelDrag?.[1] === index && levelDrag?.[0] !== index && levelDrag?.[0] + 1 !== index}
            />
          ))}
          <Box flexBasis='90px' flexGrow={0} flexShrink={0} />
        </LevelList>
      </ScrollContainer>
      <Prompt
        open={showCreateModal}
        title={cloneLevel ? t("editor.cloneLevel").toString() : t("editor.createLevel").toString()}
        message={cloneLevel ? t("editor.cloneLevelMessage", { id: cloneLevel }) : t("editor.createLevelMessage")}
        onSubmit={handleCreateLevel}
        initialValue={cloneLevel?.replace(/-\d+$/, (_, n) => `-${parseInt(n, 10) + 1}`)}
        onCancel={() => {
          setShowCreateModal(false);
          setCloneLevel(undefined);
        }}
        confirmText={cloneLevel ? t("common.clone").toString() : t("common.create").toString()}
      />
      <Prompt
        open={!!renameLevel}
        title={t("editor.renameLevel").toString()}
        message={t("editor.renameLevelMessage", { id: renameLevel })}
        initialValue={renameLevel}
        onSubmit={handleRenameLevel}
        onCancel={() => setRenameLevel(undefined)}
        confirmText={t("common.rename").toString()}
      />
    </LevelsContainer>
  );
}
