import { Box, CircularProgress, useTheme } from "@mui/material";

export function PageLoading() {
  const theme = useTheme();

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      sx={{ padding: theme.spacing(5), height: "100%" }}
    >
      <CircularProgress />
    </Box>
  );
}
