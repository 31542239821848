import { useState, useEffect } from "react";
import { Typography, useTheme } from "@mui/material";
import { Link } from "@app/router";

import { Button } from "@app/components";
import { useQuery, useBackend, useTranslation } from "@app/hooks";

export default function VerifyEmailPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { auth } = useBackend();
  const query = useQuery();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // not ready
    if (!auth || !query || !t) return;

    setError("");

    auth
      .post("/verify-email", {
        token: query.get("token"),
        email: query.get("email")
      })
      .then(() => {
        setSuccess(true);
      })
      .catch((error: unknown) => {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError(t("verifyEmail.error").toString());
        }
      });
  }, [auth, query, t]);

  if (error) {
    return (
      <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 2, 0) }}>
        {error}
      </Typography>
    );
  }

  if (!success) {
    return (
      <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 2, 0) }}>
        {t("verifyEmail.loading")}
      </Typography>
    );
  }

  return (
    <>
      <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 2, 0) }}>
        {t("verifyEmail.success")}
      </Typography>

      <Typography variant='body2' sx={{ marginBottom: theme.spacing(6) }}>
        {t("verifyEmail.successMessage")}
      </Typography>

      <Link to='/auth/login'>
        <Button variant='contained' color='primary' fullWidth>
          {t("verifyEmail.continue")}
        </Button>
      </Link>
    </>
  );
}
