import { type ReactNode, useEffect, useState } from "react";

import { type HasUpdatedAt } from "@shared/game-engine";
import { Form, type JsonSchema, type JsonUISchema } from "@shared/form-builder";
import { PropertiesModal } from "@shared/form-builder/common/PropertiesModal";

import { Button } from "@app/components";
import { useTranslation } from "@app/hooks";

import { ToolbarButton } from "./ToolbarButton";

interface Props<T> {
  icon: ReactNode;
  buttonTitle: string;
  modalTitle: ReactNode;
  data: T;
  schema: JsonSchema;
  uiSchema?: JsonUISchema;
  forceOpen?: boolean;
  formContext?: Record<string, unknown>;
  onSubmit: (data: T) => Promise<boolean>;
  onClose?: () => void;
}

export function ToolbarButtonModalForm<T extends HasUpdatedAt>(props: Props<T>) {
  const { icon, buttonTitle, modalTitle, schema, uiSchema, data, onSubmit, onClose, formContext } = props;
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [currentData, setCurrentData] = useState(data);

  useEffect(() => {
    if (data._updatedAt !== currentData._updatedAt) {
      setCurrentData(data);
      setIsDirty(false);
    }
  }, [currentData._updatedAt, data]);

  useEffect(() => {
    if (props.forceOpen) {
      setIsOpen(true);
    }
  }, [props.forceOpen]);

  const handleCancel = () => {
    setCurrentData(data);
    setIsOpen(false);
    if (isOpen) {
      onClose?.();
    }
  };

  const handleSubmit = async () => {
    if (await onSubmit(currentData)) {
      setIsOpen(false);
      onClose?.();
    }
  };

  return (
    <>
      <ToolbarButton icon={icon} title={buttonTitle} onClick={() => setIsOpen(true)} />
      <PropertiesModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          onClose?.();
        }}
        title={modalTitle}
        withTabs
        footer={
          <>
            <Button onClick={handleCancel} variant='contained' color='info'>
              {isDirty ? t("common.cancel") : t("common.close")}
            </Button>
            <Button onClick={handleSubmit} variant='contained' disabled={!isDirty}>
              {t("common.apply")}
            </Button>
          </>
        }
      >
        <Form
          data={data}
          schema={schema}
          uiSchema={uiSchema}
          onChange={(data) => {
            setCurrentData(data);
            setIsDirty(true);
          }}
          contextData={formContext}
        />
      </PropertiesModal>
    </>
  );
}
