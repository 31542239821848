import { AssetType } from "../consts";

export const ASSET_PROTOCOL = "asset://";

export interface Asset {
  type: AssetType;
  path: string;

  name?: string;
  mimeType?: string;
  size?: number;
}
