import { Box, styled } from "@mui/material";

import { Button } from "@app/components/ui/Button";
import { Modal } from "@app/components/layout/Modal";
import { usePersistedState, useTranslation } from "@app/hooks";

import overlayDemo from "@shared/branding/demo/overlay-demo.webm";

const StyledVideo = styled("video")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  width: "100%"
}));

const StyledContent = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(4),
  fontSize: "1.2em",
  textAlign: "center"
}));

export function OverlayTour() {
  const [seen, setSeen] = usePersistedState("overlay-tour", false, "local");
  const { t } = useTranslation();

  return (
    <Modal open={!seen} onClose={() => setSeen(true)} title={t("tours.overlay.title")}>
      <StyledContent>
        <StyledVideo src={overlayDemo} autoPlay loop muted />
        {t("tours.overlay.content")}
      </StyledContent>
      <Box textAlign='center' mb={4}>
        <Button variant='contained' onClick={() => setSeen(true)}>
          {t("common.accept")}
        </Button>
      </Box>
    </Modal>
  );
}
