import { CircularProgress, styled } from "@mui/material";

const FullScreenLoadingWrapper = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    position: "fixed",
    top: 0,
    left: 0,
    color: theme.palette.text.primary,
    backgroundColor: "rgba(255, 255, 255, 0.5)"
  };
});

export const FullScreenLoading = () => {
  return (
    <FullScreenLoadingWrapper>
      <CircularProgress />
    </FullScreenLoadingWrapper>
  );
};
