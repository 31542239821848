import { type ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";

import type { JsonSchema, JsonUISchema } from "@shared/form-builder";
import { type PluginManifest } from "@shared/utils/plugins";
import {
  type GameDataInterface,
  SUPPORTED_LANGUAGES,
  type GameElement,
  convertLegacyActions,
  GameElementCategory,
  type GameActionsMap,
  type TransitionSettings,
  type Translations,
  DEFAULT_TRANSITIONS
} from "@shared/game-engine";

import { AssetPickerModal, type AssetPickerModalProps, ToolbarButton, useEditor } from "@app/editor";
import { type OneOf } from "@app/types";
import { getTransitionsSchema, getTransitionsUISchema } from "@app/schemas/transitions.schema";
import { getTranslationsSchema, getTranslationsUISchema } from "@app/schemas/translations.schema";
import { getElementPropertiesSchema, getElementPropertiesUISchema } from "@app/schemas/element.properties.schema";
import { getActionsSchema, getActionsUISchema } from "@app/schemas/actions.schema";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { useAppContext, useTranslation } from "@app/hooks";

import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatFontIcon from "@mui/icons-material/Title";
import FormatFontSizeIcon from "@mui/icons-material/FormatSize";
import OpacityIcon from "@mui/icons-material/Opacity";
import ForegroundColor from "@mui/icons-material/FormatColorText";
import BackgroundColor from "@mui/icons-material/FormatColorFill";
import SettingsIcon from "@mui/icons-material/Settings";
import AnimationIcon from "@mui/icons-material/Animation";
import ActionsIcon from "@mui/icons-material/AdsClick";
import StrokeStyleIcon from "@mui/icons-material/LineStyle";
import StrokeWidthIcon from "@mui/icons-material/LineWeight";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import LanguageIcon from "@mui/icons-material/Language";

import { EditorButtonGroup } from "./EditorButtonGroup";
import { InputColorControl } from "./Controls/InputColorControl";
import { InputNumberControl } from "./Controls/InputNumberControl";
import { DropdownControl } from "./Controls/DropdownControl";

import { ToolbarButtonModalForm } from "./ToolbarButtonModalForm";

interface Props {
  element: GameElement;
  manifest: PluginManifest;
  onUpdate: (element: Partial<GameElement>) => void;
  forceOpenProperties?: boolean;
  forceOpenAnimations?: boolean;
  forceOpenActions?: boolean;
  advanced?: boolean;
  onCloseProperties?: () => void;
}

type TextAlign = "left" | "center" | "right" | "justify";
type Positioning =
  | "top left"
  | "top center"
  | "top right"
  | "center left"
  | "center center"
  | "center right"
  | "bottom left"
  | "bottom center"
  | "bottom right";

const alignIconsMap: Record<TextAlign, ReactElement> = {
  left: <FormatAlignLeftIcon />,
  center: <FormatAlignCenterIcon />,
  right: <FormatAlignRightIcon />,
  justify: <FormatAlignJustifyIcon />
};

type PropertiesWithRoot<T> = T & {
  _root: GameElement<T>; // deprecated
  __element: GameElement<T>;
  __game?: GameDataInterface;
  _updatedAt?: number;
};

interface GameElementWithRoot<T> extends GameElement<T> {
  properties: PropertiesWithRoot<T>;
}

const calculatePropertiesData = function <T>(
  element: GameElement<T>,
  gameData?: GameDataInterface
): GameElementWithRoot<T> {
  const clone = cloneDeep(element);

  // Normalize actions. Convert legacy actions to the new format
  const actions = Array.isArray(clone.actions) ? convertLegacyActions(clone) : clone.actions;

  return {
    ...clone,
    actions,

    properties: {
      ...clone.properties,

      // Hack. Add the _root to allow the properties form
      // to be able to edit the root properties. _gamedata
      // is also added to allow the properties form to access
      // the game data.
      _updatedAt: clone._updatedAt,
      _root: clone, // deprecated
      __element: clone,
      __game: gameData
    }
  };
};

export function ComponentToolbarButtons(props: Props) {
  // To be used within the form context
  const editorContext = useEditor();
  const { notify } = useAppContext();

  const {
    element,
    manifest,
    onUpdate,
    forceOpenProperties,
    forceOpenActions,
    forceOpenAnimations,
    advanced,
    onCloseProperties
  } = props;
  const { toolbar } = manifest || {};
  const { t, ts } = useTranslation();

  const [properties, setProperties] = useState<PropertiesWithRoot<unknown>>();
  const [propertiesSchema, setPropertiesSchema] = useState<JsonSchema>();
  const [propertiesUISchema, setPropertiesUISchema] = useState<JsonUISchema | false>();
  const [actions, setActions] = useState<GameActionsMap>();
  const [assetPickerOpen, setAssetPickerOpen] = useState<Partial<AssetPickerModalProps>>();

  const getValue = useCallback(
    function <T>(path: string): T {
      return path
        .replace(/^#\//, "")
        .split(/\//g)
        .reduce((acc, key) => {
          return acc && typeof acc === "object" ? acc[key] : undefined;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, element as any);
    },
    [element]
  );

  const getSchema = useCallback(
    function <T>(path: string): T {
      return path
        .replace(/^#\//, "")
        .split(/\//g)
        .reduce((acc, key) => {
          return acc && typeof acc === "object" ? acc[key] : undefined;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, manifest.schema as any);
    },
    [manifest.schema]
  );

  const doUpdate = useCallback(
    function (path: string, value: unknown) {
      const keys: string[] = path.replace(/^#\//, "").split(/\//g);
      const updates = cloneDeep(element);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let current: any = updates;
      const lastKey = keys.pop();

      if (!lastKey) {
        // nowhere to assign!
        return;
      }

      keys.forEach((key) => {
        if (!current[key]) {
          current[key] = {};
        }
        current = current[key];
      });
      current[lastKey] = value;

      // We only care about properties and stage
      onUpdate({ properties: updates.properties, stage: updates.stage });
    },
    [element, onUpdate]
  );

  useEffect(() => {
    setProperties(calculatePropertiesData(element, editorContext.gameData).properties);
    setPropertiesSchema(getElementPropertiesSchema(manifest, element, editorContext.gameDataInfo, t));
    setPropertiesUISchema(getElementPropertiesUISchema(manifest, t));
  }, [element, manifest, editorContext.gameDataInfo, t, editorContext.gameData]);

  // Translate legacy actions
  useEffect(() => {
    if (!element.actions) {
      setActions({});
      return;
    }

    const actionMap: GameActionsMap =
      Array.isArray(element.actions) || typeof element.actions === "string"
        ? convertLegacyActions(element)
        : element.actions;

    // Map legacy actions
    if (actionMap.legacy && actionMap.legacy.length > 0) {
      if (element.type === GameElementCategory.LOCK || element.type === GameElementCategory.MINIGAME) {
        // Legacy is mapped to "success"
        actionMap.success = actionMap.legacy;
        delete actionMap.legacy;
      } else {
        // Legacy is mapped to "click"
        actionMap.click = actionMap.legacy;
        delete actionMap.legacy;
      }
    }

    setActions(actionMap);
  }, [element]);

  const handleCloseAssetPicker = useCallback(() => setAssetPickerOpen(undefined), []);
  const handleUploadAsset = useCallback(
    (files: File[]) => {
      editorContext.uploadAssets(files).then(() => {
        editorContext.reloadAssets();
        notify(t("common.uploadSuccess"), "success");
      });
    },
    [editorContext, notify, t]
  );

  const bold = toolbar?.bold ? getValue<boolean>(toolbar.bold) : null;
  const italic = toolbar?.italic ? getValue<boolean>(toolbar.italic) : null;
  const underline = toolbar?.underline ? getValue<boolean>(toolbar.underline) : null;
  const textAlign = toolbar?.textAlign ? getValue<string>(toolbar.textAlign) : null;
  const fontSize = toolbar?.fontSize ? getValue<string>(toolbar.fontSize) : null;
  const fontFamily = toolbar?.fontFamily ? getValue<string>(toolbar.fontFamily) : null;
  const fgColor = toolbar?.fgColor ? getValue<string>(toolbar.fgColor) : null;
  const bgColor = toolbar?.bgColor ? getValue<string>(toolbar.bgColor) : null;
  const strokeWidth = toolbar?.strokeWidth ? getValue<number>(toolbar.strokeWidth) : null;
  const strokeStyle = toolbar?.strokeStyle ? getValue<string>(toolbar.strokeStyle) : null;
  const positioning = toolbar?.positioning ? getValue<Positioning>(toolbar.positioning) : null;
  const fitting = toolbar?.fitting ? getValue<string>(toolbar.fitting) : null;

  const hasProperties = properties && propertiesSchema && propertiesUISchema !== false;
  const hasTranslations = !!manifest?.features.translationFields?.length;
  const hasActions = !!Object.keys(manifest?.actions || {}).length;

  const supportedTriggers = manifest.actions || {};

  const handlePropertiesSubmit = useCallback(
    async ({ _root, __element, __game, ...properties }: PropertiesWithRoot<unknown>) => {
      onUpdate({ ..._root, ...__element, properties });

      // Update game data if needed
      if (__game) {
        editorContext.updateGameData(__game);
      }
      return true;
    },
    [editorContext, onUpdate]
  );

  const handleTransitionsSubmit = useCallback(
    async (transitions: TransitionSettings) => {
      onUpdate({ transitions });
      return true;
    },
    [onUpdate]
  );

  const handleActionsSubmit = useCallback(
    async (data: Partial<GameElement>) => {
      onUpdate(data);
      return true;
    },
    [onUpdate]
  );

  const handleTranslationsSubmit = useCallback(
    async (data: Translations) => {
      onUpdate({ translations: data });
      return true;
    },
    [onUpdate]
  );

  const formContext = useMemo(
    () => ({
      manifest,
      t,
      editorContext,
      modals: {
        setAssetPickerOpen
      }
    }),
    [manifest, t, editorContext]
  );

  return (
    <>
      {/* Component controls */}
      <EditorButtonGroup>
        {hasProperties && (
          <ToolbarButtonModalForm
            icon={<SettingsIcon color='action' />}
            buttonTitle={ts("editor.properties")}
            modalTitle={t("editor.propertiesModalTitle", { name: manifest.name })}
            data={properties}
            schema={propertiesSchema}
            uiSchema={propertiesUISchema}
            onSubmit={handlePropertiesSubmit}
            forceOpen={forceOpenProperties}
            onClose={onCloseProperties}
            formContext={formContext}
          />
        )}
        <ToolbarButtonModalForm
          icon={<AnimationIcon color='success' />}
          buttonTitle={ts("editor.elementEffects")}
          modalTitle={t("editor.elementEffectsModalTitle")}
          data={element.transitions || DEFAULT_TRANSITIONS}
          schema={getTransitionsSchema()}
          uiSchema={getTransitionsUISchema()}
          onSubmit={handleTransitionsSubmit}
          forceOpen={forceOpenAnimations}
          onClose={onCloseProperties}
          formContext={formContext}
        />
        {(hasActions || advanced) && (
          <ToolbarButtonModalForm
            icon={<ActionsIcon color='error' />}
            buttonTitle={ts("editor.actions")}
            modalTitle={t("editor.actionsModalTitle")}
            data={{ actions, condition: element.condition, _updatedAt: element._updatedAt }}
            schema={getActionsSchema(supportedTriggers)}
            uiSchema={getActionsUISchema(supportedTriggers, [], advanced)}
            onSubmit={handleActionsSubmit}
            forceOpen={forceOpenActions}
            onClose={onCloseProperties}
            formContext={formContext}
          />
        )}
      </EditorButtonGroup>

      {/* Translations controls */}
      {hasTranslations && advanced && (
        <EditorButtonGroup>
          <ToolbarButtonModalForm
            icon={<LanguageIcon />}
            buttonTitle={ts("editor.translations")}
            modalTitle={t("editor.translationsModalTitle")}
            data={element.translations || {}}
            schema={getTranslationsSchema(manifest?.features.translationFields || [], SUPPORTED_LANGUAGES)}
            uiSchema={getTranslationsUISchema(manifest?.features.translationFields || [], SUPPORTED_LANGUAGES)}
            onSubmit={handleTranslationsSubmit}
            formContext={formContext}
          />
        </EditorButtonGroup>
      )}

      {/* Color & style controls */}
      <EditorButtonGroup>
        <InputNumberControl
          icon={<OpacityIcon />}
          label={t("editor.opacity")}
          min={0}
          max={100}
          step={1}
          multiplier={100}
          value={element.stage.opacity}
          onChange={(value) => doUpdate("#/stage/opacity", value)}
          withSlider
          inputOnFocus
        />
        {toolbar && fgColor !== null && (
          <InputColorControl
            icon={<ForegroundColor />}
            label={t("editor.foregroundColor")}
            value={fgColor}
            onChange={(value) => doUpdate(toolbar.fgColor!, value)}
            inputOnFocus
          />
        )}
        {toolbar && bgColor !== null && (
          <InputColorControl
            icon={<BackgroundColor />}
            label={t("editor.backgroundColor")}
            value={bgColor}
            onChange={(value) => doUpdate(toolbar.bgColor!, value)}
            inputOnFocus
          />
        )}
        {toolbar && strokeWidth !== null && (
          <InputNumberControl
            icon={<StrokeWidthIcon />}
            label={t("editor.strokeWidth")}
            min={0}
            max={50}
            step={1}
            value={strokeWidth}
            onChange={(value) => doUpdate(toolbar.strokeWidth!, value)}
            withSlider
          />
        )}
        {toolbar && strokeStyle !== null && (
          <DropdownControl
            label={t("editor.strokeStyle")}
            icon={<StrokeStyleIcon />}
            value={strokeStyle}
            items={getSchema<OneOf[]>(`${toolbar.strokeStyle}/oneOf`).map(({ const: value, title: label }) => ({
              value,
              label
            }))}
            onChange={(value) => doUpdate(toolbar.strokeStyle!, value)}
          />
        )}
      </EditorButtonGroup>

      {/* Text controls */}
      {toolbar &&
        (bold !== null ||
          italic !== null ||
          underline !== null ||
          fontFamily !== null ||
          fontSize !== null ||
          textAlign !== null) && (
          <EditorButtonGroup>
            {bold !== null && (
              <ToolbarButton
                active={!!bold}
                icon={<FormatBoldIcon />}
                title={t("editor.bold")}
                onClick={() => doUpdate(toolbar.bold!, !bold)}
              />
            )}
            {italic !== null && (
              <ToolbarButton
                active={!!italic}
                icon={<FormatItalicIcon />}
                title={t("editor.italic")}
                onClick={() => doUpdate(toolbar.italic!, !italic)}
              />
            )}
            {underline !== null && (
              <ToolbarButton
                active={!!underline}
                icon={<FormatUnderlinedIcon />}
                title={t("editor.underline")}
                onClick={() => doUpdate(toolbar.underline!, !underline)}
              />
            )}
            <DropdownControl
              label={t("editor.textAlign")}
              icon={alignIconsMap[textAlign as TextAlign]}
              value={textAlign as TextAlign}
              items={getSchema<OneOf[]>(`${toolbar.textAlign}/oneOf`).map(({ const: align }) => ({
                value: align,
                label: alignIconsMap[align as TextAlign]
              }))}
              onChange={(value) => doUpdate(toolbar.textAlign!, value)}
              noSelectionText
            />
            {fontFamily !== null && (
              <DropdownControl
                label={t("editor.fontFamily")}
                icon={<FormatFontIcon />}
                value={fontFamily}
                items={getSchema<string[]>(`${toolbar.fontFamily}/enum`).map((font) => ({
                  value: font,
                  sx: { fontFamily: font }
                }))}
                onChange={(value) => doUpdate(toolbar.fontFamily!, value)}
              />
            )}
            {fontSize !== null && (
              <InputNumberControl
                icon={<FormatFontSizeIcon />}
                label={t("editor.fontSize")}
                min={10}
                max={100}
                step={1}
                value={Number(fontSize?.toString().replace(/[^\d]+$/, ""))}
                onChange={(value) => doUpdate(toolbar.fontSize!, `${value}pt`)}
                withSlider
              />
            )}
          </EditorButtonGroup>
        )}

      {/* Positioning controls */}
      {toolbar && (positioning !== null || fitting !== null) && (
        <EditorButtonGroup>
          {positioning !== null && (
            <DropdownControl
              label={t("editor.positioning")}
              icon={<ControlCameraIcon />}
              value={positioning}
              items={getSchema<OneOf[]>(`${toolbar.positioning}/oneOf`).map(({ const: value, title: label }) => ({
                value,
                label
              }))}
              onChange={(value) => doUpdate(toolbar.positioning!, value)}
            />
          )}
          {fitting !== null && (
            <DropdownControl
              label={t("editor.fitting")}
              icon={<SettingsOverscanIcon />}
              value={fitting}
              items={getSchema<OneOf[]>(`${toolbar.fitting}/oneOf`).map(({ const: value, title: label }) => ({
                value,
                label
              }))}
              onChange={(value) => doUpdate(toolbar.fitting!, value)}
            />
          )}
        </EditorButtonGroup>
      )}

      <AssetPickerModal
        {...assetPickerOpen}
        open={!!assetPickerOpen}
        onClose={handleCloseAssetPicker}
        onUpload={handleUploadAsset}
      />
    </>
  );
}
