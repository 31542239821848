import { type EditorComponentDropItem } from "@app/types";
import { type GamePluginDto } from "@shared/api-client";
import { type Asset, ASSET_PROTOCOL, AssetType, replaceValue } from "@shared/game-engine";
import { type PluginManifest } from "@shared/utils/plugins";

export function extendTemplatesFromAssets(
  component: GamePluginDto,
  manifest: PluginManifest,
  gameAssets: Asset[],
  usedAssetIds: string[] = []
): EditorComponentDropItem[] {
  if (!manifest.features.useAssetTemplates) {
    return [];
  }

  const items: EditorComponentDropItem[] = [];
  const templateDefaults = manifest.templateDefaults || {};

  Object.keys(manifest.features.useAssetTemplates).forEach((assetTypeStr) => {
    const assetType = Object.values(AssetType).includes(assetTypeStr as AssetType) ? (assetTypeStr as AssetType) : null;

    const template = assetType && manifest.features.useAssetTemplates?.[assetType];

    if (!assetType || !template) {
      console.warn(`Invalid asset type: ${assetTypeStr}`);
      return;
    }

    gameAssets.forEach((asset) => {
      if (asset.type !== assetType) {
        return;
      }

      // Create a new example with the asset
      items.push({
        name: asset.name || component.name || manifest.name,
        thumbnail: template.thumbnail || manifest.thumbnail,
        asset,
        component,
        manifest,
        template: {
          ...templateDefaults,
          ...template,
          properties: template ? replaceValue(template.properties || {}, ASSET_PROTOCOL, asset.path) : {}
        },
        inUse: usedAssetIds.includes(asset.path.replace(ASSET_PROTOCOL, ""))
      });
    });
  });

  return items;
}
