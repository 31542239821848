function hexToRGB(color: string): { r: number; g: number; b: number } {
  // Remove the hash from the color string
  const colorNoHash = color.slice(1);

  // Convert the color to RGB
  const r = parseInt(colorNoHash.slice(0, 2), 16);
  const g = parseInt(colorNoHash.slice(2, 4), 16);
  const b = parseInt(colorNoHash.slice(4, 6), 16);

  return { r, g, b };
}

function toHex(value: number): string {
  const hex = value.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

function rgbToHex(r: number, g: number, b: number): string {
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

function darken(color: string, amount: number): string {
  let { r, g, b } = hexToRGB(color);

  // Reduce the brightness
  r = Math.max(Math.round(r * (1 - amount)), 0);
  g = Math.max(Math.round(g * (1 - amount)), 0);
  b = Math.max(Math.round(b * (1 - amount)), 0);

  // Convert the color back to hexadecimal
  return rgbToHex(r, g, b);
}

function lighten(color: string, amount: number): string {
  let { r, g, b } = hexToRGB(color);

  // Increase the brightness
  r = Math.min(Math.round(r + (255 - r) * amount), 255);
  g = Math.min(Math.round(g + (255 - g) * amount), 255);
  b = Math.min(Math.round(b + (255 - b) * amount), 255);

  // Convert the color back to hexadecimal
  return rgbToHex(r, g, b);
}

export const colors = {
  transparent: "transparent",
  deepPurple: "#25006b", // *
  darkPurple: darken("#6b36ff", 0.5), // output is #25006b
  purple: "#6b36ff", // *
  brightPurple: "#752fc3", // *
  orange: "#ffc200", // *
  darkOrange: darken("#ffc200", 0.3), // output is #b38e00
  gray: "#606060", // *
  lightGray: lighten("#606060", 0.5), // output is #b0b0b0
  brightGray: "#c0c0c0", // *
  white: "#FFFFFF",
  // whiteBone: "#FAFAFA",
  whiteBone: "#F9FAFB",
  black: "#000000",
  cyan: "#2a78b8",
  green: "#00A86B",
  darkGreen: darken("#00A86B", 0.5), // output is #005436
  red: "#E62211",
};
