import Masonry from "react-responsive-masonry";

import type { EditorComponentDropItem } from "@app/types";
import { ComponentPickerItem } from "@app/editor";
import { useEffect, useRef, useState } from "react";

// Future use. Native Masonry!
// const ComponentListBox = styled(Box)(() => ({
//   display: "grid",
//   gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
//   gridTemplateRows: "masonry" // unsupported yet!
// }));

interface Props {
  items: EditorComponentDropItem[];
  onSelect?: (id: string, data: EditorComponentDropItem) => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
}

export function ComponentPickerList(props: Props) {
  const { items, onSelect, onDragStart, onDragEnd } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [columns, setColumns] = useState(1);

  useEffect(() => {
    // listen width changes
    const observer = new ResizeObserver(() => {
      const width = containerRef.current?.clientWidth || 0;
      const newColumns = Math.floor(width / 120);
      setColumns(newColumns);
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={containerRef}>
      <Masonry columnsCount={columns}>
        {items.map((item, index) => (
          <ComponentPickerItem
            key={`${item.component.uuid}-${item.asset?.path || index}`}
            item={item}
            onSelect={onSelect}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          />
        ))}
      </Masonry>
    </div>
  );
}
