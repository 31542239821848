import { Box, styled } from "@mui/material";

export const UploadBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  color: theme.palette.divider,
  marginBottom: theme.spacing(2),

  "&:hover": {
    backgroundColor: theme.palette.action.focus
  },

  "&.dragging": {
    backgroundColor: theme.palette.action.hover
  }
}));
