import { useCallback } from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  RankedTester,
  isStringControl,
  rankWith,
} from "@jsonforms/core";

import { FieldWrapper } from "../common/FieldWrapper";
import { isCustomControl } from "../utils";
import { DropZone } from "../common/DropZone";
import { DesirableConfig } from "../config";
import { Icons } from "../common/Icons";

interface Props extends ControlProps {
  data: string;
  path: string;
  handleChange: (path: string, value: string) => void;
}
export function AssetPickerRenderer({
  path,
  label,
  description,
  handleChange,
  data,
  visible,
  uischema,
  ...restProps
}: Props) {
  const config = restProps.config as DesirableConfig;
  const pluginType = config.context?.manifest?.type;
  const pluginId = config.context?.manifest?.id;

  const url =
    data &&
    config.context?.editorContext?.renderAssetUrl(data, {
      pluginType,
      pluginId,
    });

  const handleOpenAssetPicker = useCallback(() => {
    config.context?.modals?.setAssetPickerOpen?.({
      multiple: false,
      onSubmit: (assets) => {
        config.context?.modals?.setAssetPickerOpen?.(undefined);
        assets[0] && handleChange(path, assets[0].path);
      },
      mimeType: { asset: [uischema.options?.mimeType] },
    });
  }, [config.context?.modals, handleChange, path, uischema.options?.mimeType]);

  if (!visible) {
    return null;
  }

  return (
    <FieldWrapper label={label} description={description} uiSchema={uischema}>
      <DropZone
        className={url ? "hasImage" : ""}
        onClick={handleOpenAssetPicker}
      >
        {url && <img src={url} />}
        {!url && Icons.add}
      </DropZone>
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(15, (uiSchema, schema, context) => {
  return (
    isCustomControl(uiSchema, "AssetPicker") &&
    isStringControl(uiSchema, schema, context)
  );
});

export default {
  tester,
  renderer: withJsonFormsControlProps(AssetPickerRenderer),
};
