import { TextField } from "@mui/material";

import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  RankedTester,
  isStringControl,
  rankWith,
} from "@jsonforms/core";
import { FieldWrapper } from "../common/FieldWrapper";
import { useEffect, useState } from "react";

interface Props extends ControlProps {
  data: string;
  path: string;
  handleChange: (path: string, value: string) => void;
}

export function TextFieldRenderer(props: Props) {
  const {
    label,
    description,
    data,
    path,
    visible,
    uischema,
    enabled,
    errors,
    handleChange,
  } = props;

  const [currentValue, setCurrentValue] = useState<string>("");
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    if (!hasFocus) {
      setCurrentValue(data ? String(data) : "");
    }
  }, [data, hasFocus]);

  if (!visible) {
    return null;
  }

  return (
    <FieldWrapper
      label={label}
      description={description}
      uiSchema={uischema}
      errors={errors}
    >
      <TextField
        fullWidth
        value={currentValue}
        onChange={(ev) => {
          setCurrentValue(ev.target.value);
          handleChange(path, ev.target.value);
        }}
        onFocus={() => setHasFocus(true)}
        onBlur={() => {
          handleChange(path, currentValue);
          setHasFocus(false);
        }}
        multiline={uischema.options?.multi ? true : false}
        minRows={3}
        disabled={!enabled}
      />
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(10, (uiSchema, schema, context) => {
  return isStringControl(uiSchema, schema, context) ? true : false;
});

export default {
  tester,
  renderer: withJsonFormsControlProps(TextFieldRenderer),
};
