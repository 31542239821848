import {
  HorizontalLayout,
  LayoutProps,
  RankedTester,
  rankWith,
  uiTypeIs,
} from "@jsonforms/core";
import { renderLayoutElements } from "@jsonforms/material-renderers";
import { withJsonFormsLayoutProps } from "@jsonforms/react";

import isEmpty from "lodash/isEmpty";

import { Grid } from "@mui/material";

export const MaterialHorizontalLayoutRenderer = ({
  uischema,
  renderers,
  cells,
  schema,
  path,
  enabled,
  visible,
}: LayoutProps) => {
  const layout = uischema as HorizontalLayout;

  if (isEmpty(layout.elements) || !visible) {
    return null;
  }

  return (
    <Grid container direction="row" alignItems="flex-start" spacing={2}>
      {renderLayoutElements(
        layout.elements,
        schema,
        path,
        enabled,
        renderers,
        cells
      )}
    </Grid>
  );
};

const tester: RankedTester = rankWith(10, uiTypeIs("HorizontalLayout"));

export default {
  tester,
  renderer: withJsonFormsLayoutProps(MaterialHorizontalLayoutRenderer),
};
