import { TextField } from "@mui/material";

import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  RankedTester,
  isNumberControl,
  rankWith,
} from "@jsonforms/core";
import { FieldWrapper } from "../common/FieldWrapper";
import { useEffect, useState } from "react";

interface Props extends ControlProps {
  data: number;
  path: string;
  handleChange: (path: string, value: number) => void;
}

export function NumberFieldRenderer({
  label,
  description,
  data,
  path,
  visible,
  uischema,
  enabled,
  handleChange,
}: Props) {
  const [currentValue, setCurrentValue] = useState<string>("");
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    if (!hasFocus) {
      setCurrentValue(data !== undefined ? String(data) : "");
    }
  }, [data, hasFocus]);

  if (!visible) {
    return null;
  }

  return (
    <FieldWrapper label={label} description={description} uiSchema={uischema}>
      <TextField
        fullWidth
        value={currentValue}
        onChange={(ev) => {
          setCurrentValue(ev.target.value);
          handleChange(path, Number(ev.target.value) || 0);
        }}
        onFocus={() => setHasFocus(true)}
        onBlur={() => {
          handleChange(path, Number(currentValue) || 0);
          setHasFocus(false);
        }}
        type="number"
        disabled={!enabled}
      />
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(10, (uiSchema, schema, context) => {
  return isNumberControl(uiSchema, schema, context) ? true : false;
});

export default {
  tester,
  renderer: withJsonFormsControlProps(NumberFieldRenderer),
};
