import { useEffect, type DetailedHTMLProps, type ImgHTMLAttributes, useState } from "react";
import { Box, CircularProgress } from "@mui/material";

import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import { useViewportTools } from "@app/hooks";

interface CustomProps {
  onLoad?: (img: HTMLImageElement, ev: Event) => void;
  onError?: (img: HTMLImageElement) => void;
  retries?: number;
}

type Props = Omit<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "onLoad" | "onError"> &
  CustomProps;

export function Image(props: Props) {
  const { onLoad, onError, retries = 0, ...restProps } = props;

  const { ref, isVisible } = useViewportTools<HTMLImageElement>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(0);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    if (!props.src) {
      setLoading(false);
      setError(true);
      return;
    }

    const img = new window.Image();
    img.onload = (ev: Event) => {
      setLoading(false);
      onLoad?.(img, ev);
    };
    img.onerror = () => {
      console.warn(`[image] Failed to load "${props.src}"`);

      // Retry N times
      if (retry >= retries) {
        setError(true);
        setLoading(false);
        onError?.(img);
        return;
      }

      setTimeout(() => setRetry((r) => r + 1), 1000);
    };
    img.src = props.src;
  }, [onError, onLoad, props.src, retry, isVisible, retries]);

  return (
    <Box ref={ref}>
      {loading && <CircularProgress />}
      {error && <BrokenImageIcon fontSize='large' sx={{ opacity: 0.3 }} />}
      {!loading && !error && <img {...restProps} />}
    </Box>
  );
}
