import cloneDeep from "lodash/cloneDeep";

import type { GetTranslationCb } from "@app/contexts/LanguageProvider";
import type { JsonSchema, JsonUISchema, ModalTabsLayout } from "@shared/form-builder/types";
import { type GameElement, GameElementCategory } from "@shared/game-engine";
import type { PluginManifest } from "@shared/utils/plugins";

import { getElementSchema } from "./element.schema";
import { getGameDataSchema } from "./game.data.schema";
import { type GameDataInfoDto } from "@shared/api-client";

export function getElementPropertiesSchema(
  manifest: PluginManifest,
  currentElement: GameElement | undefined,
  gameInfo: GameDataInfoDto | undefined,
  t: GetTranslationCb
): JsonSchema {
  const schema = cloneDeep(manifest.schema) || { type: "object", properties: {} };

  if (!schema.properties) {
    schema.properties = {};
  }

  if (!schema.properties.__element) {
    schema.properties.__element = getElementSchema(manifest, currentElement, gameInfo, t);
  }

  if (!schema.properties.__game) {
    schema.properties.__game = getGameDataSchema(gameInfo);
  }

  return schema;
}

export function getElementPropertiesUISchema(manifest: PluginManifest, t: GetTranslationCb): JsonUISchema | false {
  const uiSchema: ModalTabsLayout = {
    type: "ModalTabsLayout",
    tabs: []
  };

  // Lock specific properties
  if (manifest.category === GameElementCategory.LOCK) {
    uiSchema.tabs.push({
      title: t("editor.lock").toString(),
      icon: "puzzle",
      elements: [
        {
          type: "Control",
          scope: "#/properties/__elemenet/properties/lockId",
          options: {
            errors: {
              "must NOT be valid": "El ID de la cerradura ya está en uso"
            }
          }
        },
        {
          type: "Control",
          scope: "#/properties/__element/properties/solutions"
        }
      ]
    });
  }

  // Item specific properties
  if (manifest.category === GameElementCategory.ITEM) {
    uiSchema.tabs.push({
      title: t("editor.item").toString(),
      icon: "inventory",
      elements: [
        {
          type: "Control",
          scope: "#/properties/__element/properties/itemId",
          options: {
            errors: {
              "must NOT be valid": "El ID del objeto ya está en uso"
            }
          }
        }
      ]
    });
  }

  if (manifest.uiSchema && manifest.uiSchema.type === "ModalTabsLayout") {
    // Extend ModalTabsLayout
    // uiSchema.tabs.push(...manifest.uiSchema.tabs);
    manifest.uiSchema.tabs.forEach((tab) => {
      // Extend tabs
      const existing = uiSchema.tabs.findIndex((t) => t.title === tab.title);
      if (existing === -1) {
        uiSchema.tabs.push(tab);
      } else {
        // Replace elements
        uiSchema.tabs[existing].elements = tab.elements;
      }
    });
  } else if (manifest.uiSchema && manifest.uiSchema.type === "ModalLayout") {
    // Convert ModalLayout into ModalTabsLayout
    if (manifest.uiSchema.tabs) {
      // Tabs defined
      uiSchema.tabs = manifest.uiSchema.tabs;
    } else if (manifest.uiSchema.elements) {
      // No tabs defined
      uiSchema.tabs = [
        { title: t("common.general").toString(), icon: "settings", elements: manifest.uiSchema.elements }
      ];
    }
  } else if (manifest.uiSchema !== false) {
    // No ModalLayout defined
    uiSchema.tabs.push({
      title: t("common.general").toString(),
      icon: "settings",
      elements: manifest.uiSchema ? [manifest.uiSchema] : []
    });
  }

  if (!uiSchema.tabs.length) {
    return false;
  }

  return uiSchema;
}
