import { useEffect } from "react";
import { GameElementCategory } from "@shared/game-engine";

import { ComponentPicker, useEditor } from "@app/editor";
import { OverlayTour } from "@app/components/tour/OverlayTour";

const SUBTYPE_LIST = [GameElementCategory.UI];

export default function EditorLocks() {
  const { setMode, level } = useEditor();

  useEffect(() => {
    if (level?.data?.overlay) {
      setMode("overlay");
    }

    return () => {
      setMode("level");
    };
  }, [level?.data?.overlay, setMode]);

  return (
    <>
      <ComponentPicker subtypes={SUBTYPE_LIST} mode='flatten' search />
      <OverlayTour />
    </>
  );
}
