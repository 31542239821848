import { useContext } from "react";

import { BackendContext } from "@app/contexts";

/**
 * Helper hook to access the backend and user information
 *
 * @returns
 */
export const useBackend = () => {
  const { backend, setAuthToken, clearAuthToken, user, ready, offline, refresh, authToken } =
    useContext(BackendContext);

  if (!backend) {
    // Backend not ready yet
    throw new Promise((resolve) => {
      setTimeout(resolve, 1);
    });
  }

  /**
   * Authenticates the user and stores the token in the local storage if rememberMe is true
   *
   * @param email The email to authenticate the user
   * @param password The password to authenticate the user
   * @param rememberMe If the token should be stored in the local storage
   */
  const login = async (email: string, password: string, rememberMe: boolean): Promise<void> => {
    const response = await backend.auth.post("/login", { email, password });
    setAuthToken(response.token, rememberMe);
  };

  /**
   * Logs the user out and removes the token from the local storage
   */
  const logout = (): void => {
    clearAuthToken();
  };

  return {
    ...backend,
    loggedInUser: user,
    isAdmin: ["admin"].includes(user?.role || ""),
    isBackendReady: ready && !offline,
    offline,
    login,
    logout,
    refresh,
    authToken,
    setAuthToken
  };
};
