import { useCallback, useEffect, useMemo, useState } from "react";

import { createLogger } from "@shared/utils/logging";
import type { GameElement, GameLayerContent } from "@shared/game-engine";
import type { GameDataInfoDto, GameManagerApiPath } from "@shared/api-client";

import { useBackend, useTranslation } from "@app/hooks";

import type { ElementUpdate, GameLayerContentWithHistory, UpdateFlags, UpdateOptions } from "./types";
import { findAndUpdate } from "./helpers";
import { useEditorHistory } from "./useEditorHistory";

const { info: log } = createLogger("useLayerContentData", { canLog: () => window.__debug__ });

const AUTOSAVE_DELAY = 1000;

type LayerContentType = "level" | "overlay";

const apiPaths: Record<LayerContentType, GameManagerApiPath> = {
  level: "/:uuid/rev/:version/levels/:levelId",
  overlay: "/:uuid/rev/:version/overlays/:overlayId"
};

const idField: Record<LayerContentType, string> = {
  level: "levelId",
  overlay: "overlayId"
};

interface Props {
  type: LayerContentType;
  uuid: string;
  version?: string;
  id?: string;
  autosave?: boolean;
  info?: GameDataInfoDto;
  onCreateSuccess?: (id: string) => void;
  onRenameSuccess?: (id: string, fromId: string) => void;
  onRemoveSuccess?: (id: string) => void;
  onSaveSuccess?: (id: string) => void;
  onLoadSuccess?: (id: string, data: GameLayerContentWithHistory) => void;
  onRequestRefreshGameInfo?: () => void;
  onError?: (error: unknown) => void;
}

export function useLayerContentData(props: Props) {
  const {
    type,
    uuid,
    version,
    id,
    autosave = false,
    info,
    onError,
    onCreateSuccess,
    onRenameSuccess,
    onRemoveSuccess,
    onLoadSuccess,
    onSaveSuccess,
    onRequestRefreshGameInfo
  } = props;

  const { ts } = useTranslation();

  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentId, setCurrentId] = useState<string | undefined>();
  const [data, setData] = useState<GameLayerContentWithHistory | undefined>();
  const [pendingElementUpdates, setPendingElementUpdates] = useState<ElementUpdate[]>([]);

  const { gameManager } = useBackend();

  /**
   * Load the current game data
   */
  const load = useCallback(
    async (type: LayerContentType, uuid: string, version: string, id: string) => {
      setIsLoading(true);

      try {
        if (!version) {
          setIsDirty(false);
          return;
        }

        log(`Loading ${type} data`);

        const data = await gameManager.get({
          path: apiPaths[type],
          params: { uuid, version, [idField[type]]: id }
        });

        return data;
      } catch (error) {
        onError?.(error);
      } finally {
        setIsLoading(false);
      }
    },
    [gameManager, onError]
  );

  /**
   * Save a specific level
   *
   * @param id The layer id to save
   * @returns A promise that resolves to true if the save was successful
   */
  const save = useCallback(
    async (isSwappingLayerId = false) => {
      if (!version || !currentId || !data) {
        return;
      }

      log(`Saving ${type} data`, uuid, version, id);
      setIsSaving(true);

      try {
        await gameManager.put(
          { path: apiPaths[type], params: { uuid, version, [idField[type]]: currentId } },
          {
            ...data,
            _updatedAt: Date.now()
          }
        );

        log(`${type} data saved succesfully!`);

        if (!isSwappingLayerId) {
          setIsDirty(false);
          onSaveSuccess?.(currentId);
        }

        return true;
      } catch (error) {
        onError?.(error);
        return false;
      } finally {
        setIsSaving(false);
      }
    },
    [currentId, data, gameManager, id, onError, onSaveSuccess, type, uuid, version]
  );

  /**
   * Update the whole level data locally
   *
   * @param updates The updates to apply
   */
  const update = useCallback(
    (updates: Partial<GameLayerContent>, options: UpdateOptions = {}) => {
      log(`Updating ${type} data...`, currentId, options);
      const { replace = false, silent = false, refreshThumbnail = true, historyUpdatedAt = Date.now() } = options;

      setData((data) => {
        const modifiers: Partial<GameLayerContentWithHistory> = {
          // Update the history timestamp
          historyUpdatedAt: silent && data ? data.historyUpdatedAt : historyUpdatedAt
        };

        if (refreshThumbnail) {
          modifiers._thumbnailOutdated = true;
        }

        return replace
          ? { ...(updates as GameLayerContent), ...modifiers }
          : data && { ...data, ...updates, ...modifiers };
      });

      setIsDirty(true);
    },
    [currentId, type]
  );

  /**
   * Create a new layer
   */
  const create = useCallback(
    async (newId: string, data: Partial<GameLayerContent> & { copyFrom?: string } = {}): Promise<void> => {
      if (!version || !data || !newId) {
        return;
      }

      if (type === "level") {
        if (info?.levels?.find((level) => level === newId)) {
          throw new Error(ts("editor.errors.levelAlreadyExists", { level: newId }));
        }
      }

      if (type === "overlay") {
        if (info?.overlays?.find((overlay) => overlay === newId)) {
          throw new Error(ts("editor.errors.overlayAlreadyExists", { overlay: newId }));
        }
      }

      log(`Creating ${type}...`, newId);

      try {
        setIsLoading(true);
        await gameManager.put({ path: apiPaths[type], params: { uuid, version, [idField[type]]: newId } }, data);
        onCreateSuccess?.(newId);
        onRequestRefreshGameInfo?.();
      } catch (error) {
        onError?.(error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      gameManager,
      info?.levels,
      info?.overlays,
      onCreateSuccess,
      onError,
      onRequestRefreshGameInfo,
      ts,
      type,
      uuid,
      version
    ]
  );

  /**
   * Rename a layer
   */
  const rename = useCallback(
    async (id: string, newId: string) => {
      if (!version) {
        return;
      }

      log(`Renaming ${type}...`, id);

      try {
        setIsLoading(true);
        await gameManager.put(
          { path: apiPaths[type], params: { uuid, version, [idField[type]]: newId } },
          { renameFrom: id }
        );
        onRenameSuccess?.(id, newId);
        onRequestRefreshGameInfo?.();
      } catch (error) {
        onError?.(error);
      } finally {
        setIsLoading(false);
      }
    },
    [gameManager, onError, onRequestRefreshGameInfo, onRenameSuccess, type, uuid, version]
  );

  /**
   * Delete a level
   */
  const remove = useCallback(
    async (id: string) => {
      if (!version) {
        return;
      }

      log(`Removing ${type}...`, id);

      try {
        setIsLoading(true);
        await gameManager.delete({
          path: apiPaths[type],
          params: { uuid, version, [idField[type]]: id }
        });

        onRemoveSuccess?.(id);
        onRequestRefreshGameInfo?.();
      } catch (error) {
        onError?.(error);
      } finally {
        setIsLoading(false);
      }
    },
    [gameManager, onError, onRequestRefreshGameInfo, onRemoveSuccess, type, uuid, version]
  );

  /**
   * Update a specific element. Defer the update until the next render in
   * order to batch updates.
   *
   * @param id The element id
   * @param updates The updates to apply
   */
  const updateElement = useCallback((id: string, updates: Partial<GameElement>, options: UpdateOptions = {}) => {
    log("Updating element...", id, updates);
    const { replace = false } = options;
    setPendingElementUpdates((currentUpdates) => [
      ...currentUpdates,
      { ...updates, id, _replace: replace } as ElementUpdate
    ]);
  }, []);

  /**
   * Update a specific element
   *
   * @param id The element id
   * @param updates The updates to apply
   */
  const removeElements = useCallback((ids: string[]) => {
    log("Removing elements...", ids);
    ids.forEach((id) => {
      setPendingElementUpdates((currentUpdates) => [...currentUpdates, { id, _delete: true }]);
    });
  }, []);

  /**
   * Update a specific element
   *
   * @param id The element id
   * @param updates The updates to apply
   */
  const orderElements = useCallback((ids: string[], order: number) => {
    log("Ordering elements...", ids, order);
    ids.forEach((id) => {
      setPendingElementUpdates((currentUpdates) => [...currentUpdates, { id, _order: order }]);
    });
  }, []);

  const history = useEditorHistory(data ? `${type}-${currentId}` : undefined, data, update);

  // ID Switch
  useEffect(() => {
    let cancelled = false;
    setCurrentId(undefined);
    setData(undefined);

    if (!id || !version) {
      return;
    }

    load(type, uuid, version, id).then((data) => {
      if (cancelled) {
        return;
      }

      setCurrentId(id);
      setData(data as GameLayerContentWithHistory);

      // Discard whatever is waiting to be updated,
      // because it doesn't belong to the new layer
      setPendingElementUpdates([]);

      setIsDirty(false);
      onLoadSuccess?.(id, data as GameLayerContentWithHistory);
    });

    return () => {
      cancelled = true;
    };
  }, [id, load, onLoadSuccess, type, uuid, version]);

  // Auto-save on id change
  useEffect(() => {
    if (!isDirty || !autosave) {
      return;
    }

    if (id !== currentId && !isSaving) {
      save(true);
    }
  }, [autosave, currentId, id, isDirty, isSaving, save]);

  // Apply pending updates
  useEffect(() => {
    if (!currentId || !data || pendingElementUpdates.length === 0) {
      return;
    }

    // This object will be mutated by the findAndUpdate function
    // Not proud of this. But required for readability and performance
    const flags: UpdateFlags = {
      refreshGameInfo: false
    };

    log("Applying pending updates...", pendingElementUpdates.length);

    const updatedData = {
      layout: findAndUpdate(data.layout || [], pendingElementUpdates, flags)
    };

    update(updatedData);
    setPendingElementUpdates([]);

    // Trigger tree reload
    if (flags.refreshGameInfo) {
      log("Action/Condition changes found, reloading tree...");
      onRequestRefreshGameInfo?.();
    }
  }, [data, currentId, onRequestRefreshGameInfo, pendingElementUpdates, update]);

  // Auto-save level changes debounced
  useEffect(() => {
    if (!isDirty || isLoading) {
      return;
    }

    const timeout = setTimeout(() => {
      log("Autosaving level data...");
      save();
    }, AUTOSAVE_DELAY);

    return () => {
      clearTimeout(timeout);
    };
  }, [isDirty, isLoading, save]);

  return useMemo(
    () => ({
      id: currentId,
      data,
      isDirty,
      isLoading,
      isSaving,

      reload: async () => {
        version && currentId && (await load(type, uuid, version, currentId));
      },
      save,

      // Layer scope
      update,
      create,
      rename,
      remove,

      // Element scope
      updateElement,
      removeElements,
      orderElements,

      history
    }),
    [
      currentId,
      data,
      isDirty,
      isLoading,
      isSaving,
      update,
      create,
      rename,
      remove,
      updateElement,
      removeElements,
      orderElements,
      history,
      version,
      load,
      type,
      uuid,
      save
    ]
  );
}
