import { Box, type SxProps, Tooltip, Typography, styled } from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";

const FieldGroupBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
  marginBottom: theme.spacing(2)
}));

const HeadBox = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  alignItems: "stretch",
  justifyContent: "space-between"
}));

interface Props {
  icon?: React.ReactNode;
  label?: React.ReactNode;
  info?: React.ReactNode;
  children: React.ReactNode;
  sx?: SxProps;
}

export function FieldGroup({ icon, label, children, info, sx }: Props) {
  return (
    <FieldGroupBox sx={sx}>
      {icon && <Box flex='0 0 30px'>{icon}</Box>}
      <Box flex='1' display='flex' flexDirection='column'>
        {(info || label) && (
          <HeadBox>
            {label && (
              <Typography variant='overline' flexGrow={1}>
                {label}
              </Typography>
            )}
            {info && (
              <Tooltip title={info}>
                <InfoOutlinedIcon />
              </Tooltip>
            )}
          </HeadBox>
        )}
        <Box flex={1} display='flex' flexDirection='column'>
          {children}
        </Box>
      </Box>
    </FieldGroupBox>
  );
}
