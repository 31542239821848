import { Box, styled } from "@mui/material";

import UfitoCoheteImg from "@shared/branding/imagery/art/ufito-cohete.png";

const StyledBox = styled(Box)({
  textAlign: "center",
  padding: "3rem",
  fontSize: "1.3rem",
  "& img": {
    width: "450px",
    maxWidth: "100%",
    height: "auto",
    margin: "0 0 2rem 0"
  },
  overflow: "hidden"
});

export default function AccountPlansPage() {
  return (
    <StyledBox>
      <img src={UfitoCoheteImg} />

      <h1>¡Sigue disfrutando de tu plan FREE!</h1>

      <p>
        Gracias a ser uno de los primeros en probar UFOLAB, por ahora puedes seguir disfrutando de todas las
        funcionalidades de forma totalmente gratuita.
      </p>
    </StyledBox>
  );
}
