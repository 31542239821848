import type { JsonSchema } from "@shared/form-builder/types";
import type { PluginManifest } from "@shared/utils/plugins";
import { type GameElement, GameElementCategory, SUPPORTED_LANGUAGES } from "@shared/game-engine";

import { type GetTranslationCb } from "@app/contexts";

import { getActionsSchema } from "./actions.schema";
import { getTransitionsSchema } from "./transitions.schema";
import { getTranslationsSchema } from "./translations.schema";
import { GameElementStageSchema } from "./element.stage.schema";

import { type GameDataInfoDto } from "@shared/api-client";

export function getElementSchema(
  manifest: PluginManifest,
  currentElement: GameElement | undefined,
  gameInfo: GameDataInfoDto | undefined,
  t: GetTranslationCb
): JsonSchema {
  const schema: JsonSchema = {
    type: "object",
    properties: {
      ...getActionsSchema(manifest.actions || {}).properties,
      transitions: getTransitionsSchema(),
      translations: manifest.features?.translationFields?.length
        ? getTranslationsSchema(manifest.features?.translationFields, SUPPORTED_LANGUAGES)
        : {},
      stage: GameElementStageSchema,
      properties: manifest.schema
    }
  };

  // Lock specific properties
  if (manifest.category === GameElementCategory.LOCK) {
    const forbiddenKeyIds = new Set(gameInfo?.keys.filter((key) => key !== currentElement?.lockId) || []);

    schema.properties!.lockId = {
      type: "string",
      title: t("fields.gameElement.lockId").toString(),
      description: t("fields.gameElement.lockIdDescription").toString(),
      default: "",
      generateId: "key-",
      not:
        forbiddenKeyIds.size > 0
          ? {
              enum: Array.from(forbiddenKeyIds)
            }
          : undefined
    };

    schema.properties!.solutions = {
      type: "array",
      title: t("fields.gameElement.solutions").toString(),
      description: t("fields.gameElement.solutionsDescription").toString(),
      items: {
        type: "string"
      },
      default: [""]
    };
  }

  // Item specific properties
  if (manifest.category === GameElementCategory.ITEM) {
    const forbiddenItemIds = new Set(gameInfo?.items.filter((item) => item !== currentElement?.itemId) || []);

    schema.properties!.itemId = {
      type: "string",
      title: t("fields.itemId").toString(),
      description: t("fields.gameElement.itemIdDescription").toString(),
      default: "",
      generateId: "item-",
      not:
        forbiddenItemIds.size > 0
          ? {
              enum: Array.from(forbiddenItemIds)
            }
          : undefined
    };
  }

  return schema;
}
