import { useCallback, useEffect, useState } from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlProps, RankedTester } from "@jsonforms/core";
import random from "lodash/random";

import { type GameAssetDto } from "@shared/api-client/dtos/game/GameAssetDto";

import { FieldWrapper } from "../common/FieldWrapper";
import { isArrayOfType, isCustomControl, rankWith } from "../utils";
import { Icons } from "../common/Icons";
import {
  DraggableImageItem,
  DroppableBox,
  StyledBox,
  ImageItem,
  ImageList,
  Item,
} from "../common/DraggableAssetList";

export interface Props extends ControlProps {
  data: Item[];
  path: string;
  handleChange: (path: string, value: Item[]) => void;
}

export function AssetListFieldRenderer(props: Props) {
  const {
    label,
    description,
    data = [],
    visible,
    uischema,
    config,
    handleChange,
    path,
  } = props;

  const [assetsToAdd, setAssetsToAdd] = useState<GameAssetDto[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [currentDrag, setCurrentDrag] = useState<[number, number]>(); // Sorting

  const handleOpenAssetPicker = useCallback(() => {
    config.context?.modals?.setAssetPickerOpen?.({
      mimeType: {
        image: ["image/*"],
      },
      multiple: true,
      onSubmit: (assets: GameAssetDto[]) => {
        setAssetsToAdd(assets);
      },
    });
  }, [config.context?.modals]);

  // Add
  useEffect(() => {
    if (assetsToAdd.length > 0) {
      const newItems = assetsToAdd.map((asset) => ({
        id:
          asset.name?.replace(/\s/g, "-").toLowerCase() ||
          random(0, 1000).toString().padStart(4, "0"),
        asset: asset.path,
        name: asset.name,
      }));

      handleChange(path, [...data, ...newItems]);
      setAssetsToAdd([]);
    }
  }, [assetsToAdd, data, handleChange, path]);

  // Delete
  const handleDeleteAsset = useCallback(
    (itemToDelete: Item) => {
      const newData = data.filter((item) => item !== itemToDelete);
      props.handleChange(props.path, newData);
    },
    [data, props]
  );

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMove = useCallback((dragIndex: number, hoverIndex: number) => {
    setCurrentDrag([dragIndex, hoverIndex]);
  }, []);

  const createDeleteHandler = useCallback(
    (item: Item) => () => handleDeleteAsset(item),
    [handleDeleteAsset]
  );

  // Actual sort
  useEffect(() => {
    if (isDragging || !currentDrag) {
      return;
    }

    const [dragIndex, hoverIndex] = currentDrag;

    // Shortcut. No change
    if (dragIndex === hoverIndex) {
      setCurrentDrag(undefined);
      return;
    }

    const newOrder: Item[] = [];
    for (let i = 0; i < Math.max(data.length, hoverIndex + 1); i++) {
      if (i === dragIndex) {
        continue;
      }

      if (i === hoverIndex) {
        newOrder.push(data[dragIndex]);
      }

      newOrder.push(data[i]);
    }

    // Update the level order
    handleChange(path, newOrder.filter(Boolean));
    setCurrentDrag(undefined);
  }, [currentDrag, data, isDragging, handleChange, path]);

  if (!visible) {
    return null;
  }

  const isDraggable = !!uischema.options?.draggableId;

  return (
    <>
      <FieldWrapper label={label} description={description} uiSchema={uischema}>
        <ImageList>
          {data.map((item, index) => {
            return isDraggable ? (
              <DraggableImageItem
                key={`${item.id}-${index}`}
                item={item}
                index={index}
                uischema={uischema}
                config={config}
                hoverLeft={
                  // Do not indicate if same level is dragged over itself
                  currentDrag?.[1] === index &&
                  currentDrag?.[0] !== index &&
                  currentDrag?.[0] + 1 !== index
                }
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragMove={handleMove}
              />
            ) : (
              <ImageItem
                key={`${item.id}-${index}`}
                item={item}
                config={config}
                onRemove={createDeleteHandler(item)}
              />
            );
          })}
          {!isDragging && (
            <StyledBox onClick={handleOpenAssetPicker} className="overable">
              {Icons.add}
            </StyledBox>
          )}
          {isDragging && (
            <DroppableBox
              uischema={uischema}
              className="delete"
              onDrop={handleDeleteAsset}
              hoverLeft={currentDrag && currentDrag[1] > data.length - 1}
            >
              {Icons.trash}
            </DroppableBox>
          )}
        </ImageList>
      </FieldWrapper>
    </>
  );
}

const tester: RankedTester = rankWith(20, (uiSchema, schema, context) => {
  return (
    isCustomControl(uiSchema, "AssetList") &&
    isArrayOfType("object", uiSchema, schema, context)
  );
});

export default {
  tester,
  renderer: withJsonFormsControlProps(AssetListFieldRenderer),
};
