import { useEffect } from "react";

import { useAppContext, useTranslation } from "@app/hooks";
import { ToDo } from "@app/components";

export default function Dashboard() {
  const { setPageTitle } = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    setPageTitle(t("dashboard.title"));
    return () => setPageTitle("");
  }, [setPageTitle, t]);

  return <ToDo />;
}
