import { type ReactNode } from "react";
import { Select as MuiSelect, type SelectProps, type SelectVariants, styled } from "@mui/material";

import { FieldGroup } from "@app/components";

const StyledSelect = styled(MuiSelect)(({ theme }) => {
  return {
    backgroundColor: theme.palette.background.paper
  };
});

interface Props extends Omit<SelectProps, "label" | "variant"> {
  label?: ReactNode;
  labelIcon?: ReactNode;
  separatedLabel?: boolean;
  variant?: SelectVariants; // for some reason this is required in SelectProps
}

export function SelectField({ separatedLabel = true, label = "", labelIcon, ...props }: Props) {
  return (
    <FieldGroup icon={labelIcon} label={separatedLabel ? label : undefined}>
      <StyledSelect
        label={separatedLabel ? "" : label}
        aria-label={separatedLabel ? label?.toString() : undefined}
        fullWidth
        native // this is required for the inputProps to work. TODO: figure out how to make this work without native
        {...props}
      />
    </FieldGroup>
  );
}
