import { Box, Container, useTheme, alpha } from "@mui/material";
import { Outlet } from "react-router-dom";

import { colors } from "@shared/branding/colors";
import TheUFOLabLogo from "@shared/branding/theufolab/ufolab-full-blue.png";
import BackgroundImg from "@shared/branding/imagery/art/space.png";

export default function LoginPage() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: colors.deepPurple,
        backgroundImage: `url(${BackgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "40% 50%",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh"
      }}
    >
      <Container>
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            overflow: "auto",
            padding: theme.spacing(5, 0)
          }}
        >
          <Box
            sx={{
              flex: "1 0 336px",
              "@media (max-width: 1024px)": {
                display: "none"
              }
            }}
          />
          <Box
            sx={{
              flex: "0 0 336px",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              justifyContent: "center",
              padding: theme.spacing(15),
              boxSizing: "content-box",
              backgroundColor: alpha(colors.white, 0.8),
              border: `4px solid ${colors.deepPurple}`,
              borderRadius: theme.spacing(4),
              backdropFilter: "blur(10px)",
              color: colors.black
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 6, mt: -2 }}>
              <img src={TheUFOLabLogo} alt='TheUFOLab' height='80' />
            </Box>

            <Outlet />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
