import { withJsonFormsControlProps } from "@jsonforms/react";
import { LabelProps, RankedTester, rankWith } from "@jsonforms/core";
import { Label } from "../types";
import { MDViewer } from "@shared/markdown/MDViewer";
import { FieldWrapper } from "../common/FieldWrapper";

interface Props extends LabelProps {
  data: string;
  path: string;
  handleChange: (path: string, value: string) => void;
}

export function MarkdownRenderer({ visible, uischema }: Props) {
  const layout = uischema as Label;

  return (
    visible && (
      <FieldWrapper uiSchema={uischema}>
        <MDViewer content={layout.text || ""} />
      </FieldWrapper>
    )
  );
}

const tester: RankedTester = rankWith(
  10,
  (uiSchema) => uiSchema.type === "Text"
);

export default {
  tester,
  renderer: withJsonFormsControlProps(MarkdownRenderer),
};
