import { type ReactNode } from "react";
import { Box, type SxProps, styled, Paper } from "@mui/material";

const DEFAULT_SIZE = 300;

const Wrapper = styled(Paper)(({ theme }) => ({
  flex: "0 0 auto",

  boxSizing: "border-box",
  overflowX: "hidden",
  overflowY: "auto",
  scrollbarColor: `${theme.palette.primary.dark} transparent`,

  transition: theme.transitions.create(["width", "height"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
}));

const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  boxSizing: "border-box"
}));

interface Props {
  children: ReactNode;
  elevation?: number;
  open?: boolean;
  horizontal?: boolean;
  maximized?: boolean;
  size?: number | string;
}

export function CollapsableDrawer({
  children,
  open = false,
  horizontal = false,
  size = DEFAULT_SIZE,
  elevation,
  maximized = false
}: Props) {
  const wrapperSx: SxProps = horizontal
    ? { height: open ? size : 0, width: "100%" }
    : { width: open ? size : 0, height: "100%" };

  const contentSx: SxProps = horizontal ? { height: size, width: "100%" } : { width: size, height: "100%" };

  if (maximized) {
    contentSx.padding = 0;
  }

  return (
    <Wrapper sx={wrapperSx} square elevation={elevation}>
      <Content sx={contentSx}>{children}</Content>
    </Wrapper>
  );
}
