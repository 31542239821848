import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  styled,
  Divider,
  ListItem,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  alpha,
  IconButton,
  Paper
} from "@mui/material";

import { useTranslation } from "@app/hooks";
import { useNavigate } from "@app/router";
import { useAppContext } from "@app/hooks";
import { DrawerBottomMenuItems } from "@app/config";

import TheUFOLabImg from "@shared/branding/theufolab/ufolab-full-white.png";
import ShipImg from "@shared/branding/theufolab/ship-white.png";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import type { MenuItem } from "@app/types";

const DrawerBox = styled(Paper)(({ theme }) => ({
  position: "relative",
  flex: "0 0 auto",
  display: "flex",
  overflowX: "hidden",
  overflowY: "auto",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0, 4, 0),
  boxSizing: "border-box",
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.contrastText,
  height: "100vh",
  transition: "width 0.3s",
  width: 88,
  ".Logo": {
    transition: "transform 0.3s",
    transform: "translateX(4px)"
  },
  "&.expanded": {
    width: 202,
    ".Logo": {
      transform: "translateX(-73px)"
    }
  },
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)"
}));

const LogoBox = styled(Box)(({ theme }) => ({
  flex: "0 0 1px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
  padding: theme.spacing(0, 2)
}));

const Logo = styled("img")(() => ({
  width: "auto",
  height: "28px",
  margin: "0px 18px 0 7px"
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(4)})`,
  height: 1,
  backgroundColor: theme.palette.primary.contrastText,
  margin: theme.spacing(3, 2),
  boxSizing: "border-box",
  opacity: 0.5
}));

const MenuBox = styled(Box)(({ theme }) => ({
  flex: "1 1 auto",
  width: "100%",
  whiteSpace: "nowrap",

  transition: theme.transitions.create("transform", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen
  }),

  "> *": {
    width: "100%",
    display: "inline-block",
    verticalAlign: "top"
  }
}));

const StyledMenu = styled(List)(({ theme }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  width: "100%",
  padding: theme.spacing(0, 2),
  boxSizing: "border-box",

  ".MuiButtonBase-root": {
    whiteSpace: "nowrap",
    width: "240px",
    display: "flex",
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: alpha(theme.palette.primary.light, 0.75)
    },
    "&.Mui-selected": {
      color: theme.palette.primary.contrastText,
      backgroundColor: alpha(theme.palette.primary.light, 0.5),
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.light, 0.75)
      }
    },
    ".MuiSvgIcon-root": {
      color: theme.palette.primary.contrastText
    },
    "&.highlight": {
      color: theme.palette.secondary.main,

      ".MuiSvgIcon-root": {
        color: theme.palette.secondary.main
      }
    },
    "&.no-active": {
      color: theme.palette.primary.contrastText,
      backgroundColor: "transparent"
    }
  },
  "&.collapsed": {
    ".MuiButtonBase-root": {
      whiteSpace: "wrap",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    ".MuiListItemIcon-root": {
      minWidth: "auto"
    },
    ".MuiTypography-root": {
      fontSize: "0.7rem"
    }
  }
}));

const DrawerToggler = styled(IconButton)(({ theme }) => ({
  opacity: 0,
  alignSelf: "center",
  flex: "0 0 1px",
  textAlign: "center",
  position: "absolute",
  right: 0,
  top: "100%",
  transform: "translateY(-100%) translateX(20%)",
  backgroundColor: "transparent",
  color: theme.palette.primary.contrastText,
  padding: 0,
  "&:hover": {
    opacity: 1,
    backgroundColor: alpha(theme.palette.primary.dark, 0.75)
  }
}));

export function DashboardDrawer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, setDrawerMode } = useAppContext();
  const location = useLocation();
  const [currentMenu, setCurrentMenu] = useState(0);
  const { isCurrentRoute } = useAppContext();

  // Keep focus in current menu
  useEffect(() => {
    // auto-focus on the last menu
    const totalMenus = state.menus.filter(Boolean).length;
    const currentMenuIndex = totalMenus - 1;

    if (currentMenuIndex >= 0) {
      setCurrentMenu(currentMenuIndex);
    } else {
      // Clamp to available menus
      setCurrentMenu((current) => Math.max(0, Math.min(current, totalMenus - 1)));
    }
  }, [location, state.menus]);

  const createClickHandler = useCallback(
    (menuItem: MenuItem, menuIndex?: number) => (e: React.MouseEvent) => {
      const { link, externalLink, onClick } = menuItem;

      if (!onClick && !link && !externalLink) {
        return;
      }

      e.preventDefault();

      if (onClick) {
        onClick();
      }

      if (link && menuIndex !== undefined) {
        navigate(link, { params: state.menuParams[menuIndex] as never });
      }

      if (externalLink) {
        window.open(externalLink, "_blank");
      }
    },
    [navigate, state.menuParams]
  );

  return (
    <DrawerBox className={state.drawerMode} square>
      <LogoBox onClick={() => navigate("/")} className='Logo'>
        <Logo src={ShipImg} alt='UFO Icon' sx={{ flex: "0 0 1px", height: "28px" }} />
        <Logo src={TheUFOLabImg} alt='The UFO Lab' sx={{ flex: "0 0 1px", height: "50px" }} />
      </LogoBox>
      <StyledDivider sx={{ flex: "0 0 1px" }} />
      <MenuBox sx={{ transform: `translateX(-${currentMenu * 100}%)` }}>
        {state.menus.filter(Boolean).map((menu, menuIndex) => (
          <StyledMenu key={menuIndex} className={state.drawerMode}>
            {menu.map((menuItem) => {
              const { key, link, externalLink, label, icon, onClick, className, active } = menuItem;

              return (
                <ListItem disablePadding key={key}>
                  <ListItemButton
                    disabled={!link && !onClick && !externalLink}
                    selected={active || (link ? isCurrentRoute(link) : false)}
                    onClick={createClickHandler(menuItem, menuIndex)}
                    className={className}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    {label && <ListItemText primary={t(label)} />}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </StyledMenu>
        ))}
      </MenuBox>
      <Box flex={1} />
      <StyledMenu className={state.drawerMode}>
        {DrawerBottomMenuItems.map((menuItem) => {
          const { key, link, externalLink, label, icon, onClick, className, active } = menuItem;

          return (
            <ListItem disablePadding key={key}>
              <ListItemButton
                disabled={!link && !externalLink && !onClick}
                selected={active || (link ? isCurrentRoute(link) : false)}
                onClick={createClickHandler(menuItem)}
                className={className}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                {label && <ListItemText primary={t(label)} />}
              </ListItemButton>
            </ListItem>
          );
        })}
      </StyledMenu>
      <DrawerToggler
        color='info'
        onClick={() => {
          setDrawerMode(state.drawerMode === "expanded" ? "collapsed" : "expanded");
        }}
      >
        {state.drawerMode === "expanded" ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
      </DrawerToggler>
    </DrawerBox>
  );
}
