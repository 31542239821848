import { useEditor } from "@app/editor";
import { Navigate } from "@app/router";

export default function ProjectEditMainPage() {
  const { uuid, version } = useEditor();

  if (!version) {
    // Redirect to main page
    return <Navigate to='/dashboard/projects/:uuid' params={{ uuid }} replace />;
  }

  // Redirect to editor page
  return <Navigate to='/dashboard/projects/:uuid/v/:version/editor/text' params={{ uuid, version }} replace />;
}
