import isEmpty from "lodash/isEmpty";
import {
  LayoutProps,
  RankedTester,
  UISchemaElement,
  rankWith,
} from "@jsonforms/core";
import { renderLayoutElements } from "@jsonforms/material-renderers";
import { withJsonFormsLayoutProps } from "@jsonforms/react";

import { ModalTabs, TabPanel } from "../common/ModalTabs";

import { isLayoutType } from "../utils";
import { ModalTabsLayout } from "../types";
import { Icons } from "../common/Icons";

export const ModalTabsLayoutRenderer = ({
  uischema,
  renderers,
  cells,
  schema,
  path,
  enabled,
  visible,
}: LayoutProps) => {
  const layout = uischema as ModalTabsLayout;

  if (isEmpty(layout.tabs) || !visible) {
    return null;
  }

  const tabs = layout.tabs.map((tab) => (
    <TabPanel
      key={tab.title}
      id={tab.title}
      title={tab.title}
      icon={tab.icon ? Icons[tab.icon] : undefined}
    >
      {renderLayoutElements(
        tab.elements as UISchemaElement[],
        schema,
        path,
        enabled,
        renderers,
        cells
      )}
    </TabPanel>
  ));

  return (
    <ModalTabs activeTab={layout.tabs?.[0].title || "main"}>{tabs}</ModalTabs>
  );
};

const tester: RankedTester = rankWith(20, (uiSchema) => {
  return isLayoutType(uiSchema, "ModalTabsLayout");
});

export default {
  tester,
  renderer: withJsonFormsLayoutProps(ModalTabsLayoutRenderer),
};
