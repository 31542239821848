import { type ReactNode } from "react";
import { Box, Typography, styled, AppBar, Toolbar, type SxProps } from "@mui/material";

import { AvatarMenu } from "@app/components";

const HeaderBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.info.main,
  boxShadow: "2px 0px 4px rgba(0,0,0,0.2)",
  h2: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightLight
  }
}));

const HeaderToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(3)
}));

interface Props {
  title: ReactNode;
  children?: ReactNode;
  sx?: SxProps;
}

export function DashboardHeader({ title, children, sx }: Props) {
  return (
    <Box sx={sx} zIndex={1}>
      <HeaderBar position='static'>
        <HeaderToolbar>
          <Typography variant='h2' mr={8}>
            {title}
          </Typography>
          <Box flex='1'>{children}</Box>
          <AvatarMenu />
        </HeaderToolbar>
      </HeaderBar>
    </Box>
  );
}
