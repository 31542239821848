import { CardContent, Typography, useTheme } from "@mui/material";

import { useTranslation } from "@app/hooks";
import { ProjectCardWrapper } from "@app/components";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

interface Props {
  onClick?: () => void;
}

export function CreateBlankCard({ onClick }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ProjectCardWrapper onClick={onClick}>
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: theme.palette.divider,
          borderRadius: "8px",
          margin: theme.spacing(2)
        }}
      >
        <AddCircleOutlineOutlinedIcon sx={{ fontSize: "64px", opacity: 0.5 }} />
        <Typography variant='h6'>{t("create.createGame")}</Typography>
        <Typography variant='subtitle1'>{t("create.blankTemplate")}</Typography>
      </CardContent>
    </ProjectCardWrapper>
  );
}
