import { alpha, ButtonGroup, Paper, styled, Table, TableCell, TableRow, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

import { type GameRevisionDto } from "@shared/api-client";

import { Button, SectionTitle } from "@app/components";
import { useBackend, useTranslation } from "@app/hooks";
import { useEditor } from "@app/editor";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import { useNavigate } from "@app/router";
import { GameStatus } from "@app/types";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  td: {
    border: "none"
  },
  "td:first-of-type": {
    borderRadius: "8px 0 0 8px"
  },
  "td:last-of-type": {
    borderRadius: "0 8px 8px 0"
  },

  "&.public": {
    backgroundColor: alpha(theme.palette.success.light, 0.3)
  },
  "&.draft": {},
  "&.archived": {
    color: theme.palette.text.disabled
  },
  "&.current": {
    td: {
      fontWeight: "bold",
      backgroundColor: alpha(theme.palette.primary.main, 0.1)
    }
  },
  ".MuiSvgIcon-root": {
    fontSize: "1rem"
  }
}));

export default function ProjectRevisionsPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const { gameManager } = useBackend();
  const { gameDefinition, version, setVersion } = useEditor();
  const [revisions, setRevisions] = useState<GameRevisionDto[]>([]);

  const load = useCallback(() => {
    if (!gameDefinition) {
      return;
    }

    gameManager
      .getList({ path: "/:uuid/rev", params: { uuid: gameDefinition.uuid } })
      .then((revisions) => setRevisions(revisions.items))
      .catch(console.error);
  }, [gameDefinition, gameManager]);

  const createRevision = useCallback(
    (from?: number) => {
      if (!gameDefinition) {
        return;
      }

      gameManager.post({ path: "/:uuid/rev", params: { uuid: gameDefinition.uuid } }, { from }).then((revision) => {
        setVersion(revision.version.toString());
        load();
      });
    },
    [gameDefinition, gameManager, load, setVersion]
  );

  const setRevision = useCallback(
    (version: string) => {
      if (!gameDefinition) {
        return;
      }

      setVersion(version);
      navigate("/dashboard/projects/:uuid/v/:version/editor", { params: { uuid: gameDefinition.uuid, version } });
    },
    [gameDefinition, navigate, setVersion]
  );

  const setCurrentRevision = useCallback(
    (version: string) => {
      if (!gameDefinition) {
        return;
      }

      gameManager
        .post(
          {
            path: "/:uuid/rev/:version/publish",
            params: { uuid: gameDefinition.uuid, version }
          },
          {}
        )
        .then(() => load());
    },
    [gameDefinition, gameManager, load]
  );

  useEffect(() => {
    load();
  }, [load]);

  const sectionTitleActions = useMemo(
    () => [
      {
        key: "create",
        icon: <AddIcon />,
        label: t("editProject.revisions.create").toString(),
        onClick: () => createRevision()
      }
    ],
    [createRevision, t]
  );

  if (!revisions) {
    return null;
  }

  const isPublic = gameDefinition?.status === GameStatus.PUBLISHED;

  return (
    <Paper sx={{ padding: theme.spacing(8) }} elevation={0}>
      <SectionTitle title={t("editProject.revisions.title")} actions={sectionTitleActions} />
      <Table>
        <thead>
          <StyledTableRow>
            <TableCell>{t("editProject.revisions.version")}</TableCell>
            <TableCell></TableCell>
            <TableCell>{t("editProject.revisions.createdAt")}</TableCell>
            <TableCell>{t("editProject.revisions.updatedAt")}</TableCell>
            <TableCell></TableCell>
          </StyledTableRow>
        </thead>
        <tbody>
          {revisions.map((revision) => {
            const classes = [
              revision.isCurrent ? "public" : "",
              revision.isDraft ? "draft" : "",
              revision.isArchived ? "archived" : "",
              version && revision.version === Number(version) ? "current" : ""
            ].filter(Boolean);

            return (
              <StyledTableRow key={revision.version} className={classes.join(" ")}>
                <TableCell>{revision.version}</TableCell>
                <TableCell>
                  {revision.isCurrent && isPublic ? t("editProject.revisions.public") : null}
                  {revision.isCurrent && !isPublic ? t("editProject.revisions.private") : null}
                  {revision.isDraft ? t("editProject.revisions.draft") : null}
                  {revision.isArchived ? t("editProject.revisions.archived") : null}
                  {version && revision.version === Number(version) ? ` - ${t("editProject.revisions.current")}` : null}
                </TableCell>
                <TableCell>{dayjs(revision.createdAt).format(t("common.dateTimeFormat").toString())}</TableCell>
                <TableCell>{dayjs(revision.updatedAt).fromNow()}</TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  <ButtonGroup>
                    {!version || Number(version) !== revision.version ? (
                      <Button variant='contained' size='small' onClick={() => setRevision(revision.version.toString())}>
                        <EditIcon />
                      </Button>
                    ) : null}
                    {!revision.isCurrent ? (
                      <Button
                        variant='contained'
                        size='small'
                        color='error'
                        onClick={() => setCurrentRevision(revision.version.toString())}
                      >
                        <UploadIcon />
                      </Button>
                    ) : null}
                    {/* <Button variant='contained' size='small'>
                        <EyeIcon />
                      </Button> */}
                  </ButtonGroup>
                </TableCell>
              </StyledTableRow>
            );
          })}
        </tbody>
      </Table>
    </Paper>
  );
}
