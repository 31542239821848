import { useBackend } from "@app/hooks";
import { Navigate } from "@app/router";

export default function LogoutPage() {
  const { logout } = useBackend();

  logout();

  return <Navigate to='/' reloadDocument />;
}
