import type { VerticalLayout, JsonSchema, JsonUISchema } from "@shared/form-builder";
import { type TransitionAnimation, transitions } from "@shared/game-player/utils/animations";

export function getTransitionSchema(animationName: keyof TransitionAnimation): JsonSchema {
  return {
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Tipo de animación",
        oneOf: Object.keys(transitions).map((key) => ({
          const: key,
          title: transitions[key][animationName].name
        }))
      },
      duration: {
        type: "number",
        title: "Duración",
        description: "Duración de la animación en segundos",
        default: 1,
        minimum: 0
      },
      delay: {
        type: "number",
        title: "Retraso",
        description: "Tiempo de espera en segundos",
        default: 0,
        minimum: 0
      },
      easing: {
        type: "string",
        oneOf: [
          { const: "linear", title: "Lineal" },
          { const: "ease", title: "Suave" },
          { const: "ease-in", title: "Entrada suave" },
          { const: "ease-out", title: "Salida suave" },
          { const: "ease-in-out", title: "Entrada y salida suave" }
        ],
        title: "Suavizado",
        description:
          "Función de suavizado de la animación. La animación se ejecutará mas rápido o mas lento según avanza en el tiempo dependiendo de la función seleccionada.",
        default: "ease"
      },
      repeat: {
        type: "number",
        title: "Repetir",
        description: "Número de veces que se repite la animación. 0 significa repetir indefinidamente.",
        default: 1,
        minimum: 0
      }
    }
  };
}

export function getTransitionsSchema(): JsonSchema {
  return {
    type: "object",
    title: "Transiciones",
    properties: {
      in: {
        title: "Entrada",
        description: "Animación al activar el elemento",
        ...getTransitionSchema("in")
      },
      out: {
        title: "Salida",
        description: "Animación al desactivar el elemento",
        ...getTransitionSchema("out")
      }
    }
  };
}

export const getTransitionAnimationUISchema = (baseScope: string = "#"): VerticalLayout => ({
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: `${baseScope}/properties/name`
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: `${baseScope}/properties/duration`
        },
        {
          type: "Control",
          scope: `${baseScope}/properties/delay`
        }
      ],
      rule: {
        effect: "HIDE",
        condition: {
          scope: `${baseScope}/properties/name`,
          schema: {
            anyOf: [{ not: { type: "string" } }, { const: "" }]
          }
        }
      }
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: `${baseScope}/properties/easing`
        },
        {
          type: "Control",
          scope: `${baseScope}/properties/repeat`
        }
      ],
      rule: {
        effect: "HIDE",
        condition: {
          scope: `${baseScope}/properties/name`,
          schema: {
            anyOf: [{ not: { type: "string" } }, { const: "" }]
          }
        }
      }
    }
  ]
});

export const getTransitionsUISchema = (baseScope: string = "#"): JsonUISchema => ({
  type: "ModalTabsLayout",
  tabs: [
    {
      title: "Aparecer",
      icon: "in",
      elements: getTransitionAnimationUISchema(`${baseScope}/properties/in`).elements
    },

    {
      title: "Desaparecer",
      icon: "out",
      elements: getTransitionAnimationUISchema(`${baseScope}/properties/out`).elements
    }
  ]
});
