import { Box, type SxProps, alpha, styled, useTheme } from "@mui/material";

import { type GameScreenSettings } from "@shared/game-engine";

import { EDITOR_ACTION_AREA, EDITOR_SAFE_AREA } from "@app/config";

interface Props {
  screen: GameScreenSettings;
}

const GuideBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  boxSizing: "border-box",
  border: `1px dashed ${theme.palette.action.disabled}`,
  pointerEvents: "none",
  zIndex: 1
}));

export function Guidelines(props: Props) {
  const { screen } = props;
  const theme = useTheme();

  const fullBox: SxProps = {
    left: 0,
    top: 0,
    width: screen.width,
    height: screen.height,
    borderColor: theme.palette.common.black
  };

  const safeArea: SxProps = EDITOR_SAFE_AREA
    ? {
        left: screen.width * EDITOR_SAFE_AREA,
        top: screen.height * EDITOR_SAFE_AREA,
        width: screen.width * (1 - EDITOR_SAFE_AREA * 2),
        height: screen.height * (1 - EDITOR_SAFE_AREA * 2),
        borderColor: alpha(theme.palette.divider, 0.3),
        mixBlendMode: "difference"
      }
    : null;

  const actionArea: SxProps = EDITOR_ACTION_AREA
    ? {
        position: "absolute",
        left: screen.width * EDITOR_ACTION_AREA,
        top: screen.height * EDITOR_ACTION_AREA,
        width: screen.width * (1 - EDITOR_ACTION_AREA * 2),
        height: screen.height * (1 - EDITOR_ACTION_AREA * 2),
        borderColor: alpha(theme.palette.primary.main, 0.3),
        mixBlendMode: "difference"
      }
    : null;

  return (
    <GuideBox sx={fullBox}>
      {safeArea && <GuideBox sx={safeArea} />}
      {actionArea && <GuideBox sx={actionArea} />}
    </GuideBox>
  );
}
