import { useCallback, useMemo, useState } from "react";

import { DropdownControl, ToolbarButton, useEditor } from "@app/editor";
import { useAppContext, useTranslation } from "@app/hooks";

import CaretDown from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import TrashIcon from "@mui/icons-material/Delete";
import CopyIcon from "@mui/icons-material/FileCopy";
import UIIcon from "@mui/icons-material/Layers";

import { DEFAULT_LEVEL } from "@shared/game-engine";

import { EditorButtonGroup } from "./EditorButtonGroup";
import { Prompt } from "@app/components";

const NEW_KEYWORD = "**new**";

interface Props {
  advanced?: boolean;
}

export function OverlayToolbarButtons(props: Props) {
  const { advanced } = props;
  // To be used within the form context
  const { notify, confirm } = useAppContext();
  const { editor, setMode, level, overlay, gameDataInfo } = useEditor();
  const { t, ts } = useTranslation();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [cloneOverlay, setCloneOverlay] = useState<string | undefined>();
  const [renameOverlay, setRenameOverlay] = useState<string | undefined>();

  const isLevelLoaded = level?.data && level?.data !== undefined; // Double check for making TS happy

  const handleToggleOverlay = useCallback(() => {
    setMode(editor.mode === "overlay" ? "level" : "overlay");
  }, [editor.mode, setMode]);

  const handleCloneOverlay = useCallback(
    async (id: string) => {
      if (!id) return;

      try {
        await overlay?.create(id, { copyFrom: cloneOverlay });
        level?.update({ overlay: id });
        setShowCreateModal(false);
      } catch (error) {
        notify(t("editor.errors.createOverlayError", { error }), "error");
      }
    },
    [cloneOverlay, level, notify, overlay, t]
  );

  const createDefaultOverlay = useCallback(async () => {
    const id = DEFAULT_LEVEL.overlay || "default";

    try {
      await overlay?.create(id);
    } catch (error) {
      // ignore
    }

    level?.update({ overlay: id });
    setMode("overlay");
  }, [level, setMode, overlay]);

  const handleRenameOverlay = useCallback(
    async (newId: string) => {
      if (!newId || !renameOverlay) return;

      try {
        await overlay?.rename(renameOverlay, newId);
        setRenameOverlay(undefined);
      } catch (error) {
        notify(t("editor.errors.renameLevelError", { error }), "error");
      }
    },
    [renameOverlay, overlay, notify, t]
  );

  const handleDeleteOverlay = useCallback(
    async (id: string) => {
      try {
        const isReferenced = false; // TODO

        await confirm({
          title: t("editor.deleteOverlay"),
          content: t(isReferenced ? "editor.deleteOverlayConfirmWithWarning" : "editor.deleteOverlayConfirm", { id })
        });

        await overlay?.remove(id);
      } catch (error) {
        if (!error) {
          // User cancelled
          return;
        }

        notify(t("editor.deleteError", { error }), "error");
      }
    },
    [confirm, t, overlay, notify]
  );

  const handleChangeOverlay = useCallback(
    async (newId: string) => {
      if (newId === NEW_KEYWORD) {
        setCloneOverlay(undefined);
        setShowCreateModal(true);
        return;
      }

      if (newId === level?.data?.overlay) {
        return;
      }

      level?.update({ overlay: newId });
    },
    [level]
  );

  const overlayValues = useMemo(
    () => [
      { value: "", label: ts("editor.overlayNone") },
      ...(gameDataInfo?.overlays.map((overlay) => ({ value: overlay, label: overlay })) || []),
      { value: NEW_KEYWORD, label: ts("editor.createOverlay") }
    ],
    [gameDataInfo, ts]
  );

  return (
    <>
      <EditorButtonGroup>
        {isLevelLoaded && level.data?.overlay && (
          <ToolbarButton
            title={t("editor.toggleOverlay")}
            icon={<UIIcon />}
            active={editor.mode === "overlay"}
            onClick={handleToggleOverlay}
            color='error'
          />
        )}
        {isLevelLoaded && !level.data?.overlay && (
          <ToolbarButton
            title={t("editor.createOverlay")}
            icon={<UIIcon />}
            active={editor.mode === "overlay"}
            onClick={createDefaultOverlay}
            color='error'
          />
        )}
      </EditorButtonGroup>
      <EditorButtonGroup>
        {advanced && isLevelLoaded && (
          <DropdownControl
            icon={<CaretDown />}
            label={t("fields.gameLevel.overlay")}
            value={level.data?.overlay || ""}
            items={overlayValues}
            onChange={handleChangeOverlay}
          />
        )}
        {advanced && isLevelLoaded && level.data?.overlay && (
          <ToolbarButton
            title={t("editor.renameOverlay")}
            icon={<EditIcon />}
            onClick={() => {
              setRenameOverlay(level.data?.overlay);
            }}
          />
        )}
        {advanced && isLevelLoaded && level.data?.overlay && (
          <ToolbarButton
            title={t("editor.cloneOverlay")}
            icon={<CopyIcon />}
            onClick={() => {
              setCloneOverlay(level.data?.overlay);
              setShowCreateModal(true);
            }}
          />
        )}
        {advanced && isLevelLoaded && level.data?.overlay && (
          <ToolbarButton
            title={t("editor.deleteOverlay")}
            icon={<TrashIcon color='error' />}
            onClick={() => {
              level.data?.overlay && handleDeleteOverlay(level.data?.overlay);
            }}
          />
        )}
      </EditorButtonGroup>

      <Prompt
        open={showCreateModal}
        title={cloneOverlay ? t("editor.cloneOverlay").toString() : t("editor.createOverlay").toString()}
        message={
          cloneOverlay ? t("editor.cloneOverlayMessage", { id: cloneOverlay }) : t("editor.createOverlayMessage")
        }
        onSubmit={handleCloneOverlay}
        onCancel={() => {
          setShowCreateModal(false);
          setCloneOverlay(undefined);
        }}
        confirmText={cloneOverlay ? t("common.clone").toString() : t("common.create").toString()}
      />

      <Prompt
        open={!!renameOverlay}
        title={t("editor.renameOverlay").toString()}
        message={t("editor.renameOverlayMessage", { id: renameOverlay })}
        initialValue={renameOverlay}
        onSubmit={handleRenameOverlay}
        onCancel={() => setRenameOverlay(undefined)}
        confirmText={t("common.rename").toString()}
      />
    </>
  );
}
