import { Typography, useTheme } from "@mui/material";
import { Link } from "@app/router";

import { Button } from "@app/components";
import { useTranslation } from "@app/hooks";

export default function PasswordRecoveryPage() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 2, 0) }}>
        {t("passwordRecovery.success")}
      </Typography>

      <Typography variant='body2' sx={{ marginBottom: theme.spacing(6) }}>
        {t("passwordRecovery.successMessage")}
      </Typography>

      <Link to='/auth/login'>
        <Button variant='contained' color='primary' fullWidth>
          {t("passwordRecovery.continue")}
        </Button>
      </Link>
    </>
  );
}
