import { useRef, useEffect, useState, type CSSProperties } from "react";

interface Props {
  content: string;
  style?: CSSProperties;
  onChange?: (content: string) => void;
  onCancel?: () => void;
  onSubmit?: (content: string) => void;
}

export function MDEditor(props: Props) {
  const containerRef = useRef<HTMLTextAreaElement>(null);
  const [currentContent, setCurrentContent] = useState<string>(props.content);

  useEffect(() => {
    const element = containerRef.current;

    if (element) {
      element.focus();

      const keyboardEventHandler = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          props.onCancel?.();
        } else if (event.key === "Enter" && event.metaKey) {
          props.onSubmit?.(element.value);
        }
      };

      const resizeHandler = () => {
        element.style.height = "auto";
        element.style.height = element.scrollHeight + "px";
      };

      resizeHandler();

      element.addEventListener("keydown", keyboardEventHandler);
      element.addEventListener("input", resizeHandler);

      return () => {
        element?.removeEventListener("keydown", keyboardEventHandler);
        element?.removeEventListener("input", resizeHandler);
      };
    }
  }, [containerRef, currentContent, props]);

  useEffect(() => {
    setCurrentContent(props.content);
  }, [props.content]);

  return (
    <textarea
      rows={1}
      ref={containerRef}
      style={{
        display: "block",
        userSelect: "all",
        pointerEvents: "all",
        width: "100%",
        height: "auto",
        background: "transparent",
        padding: 0,
        margin: 0,
        border: "none",
        ...props.style,
      }}
      value={currentContent}
      onChange={(event) => {
        setCurrentContent(event.target.value || "");
        props.onChange?.(event.target.value || "");
      }}
    />
  );
}
