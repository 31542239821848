import { Box } from "@mui/material";

import { ErrorContent, type ErrorContentProps } from ".";

export function PageError(props: ErrorContentProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "15% 0"
      }}
    >
      <ErrorContent {...props} />
    </Box>
  );
}
