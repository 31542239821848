import { ButtonGroup, useTheme } from "@mui/material";

import {
  LayoutProps,
  RankedTester,
  rankWith,
  type UISchemaElement,
  uiTypeIs,
} from "@jsonforms/core";
import isEmpty from "lodash/isEmpty";
import { ToolbarLayout } from "../types";
import { withJsonFormsLayoutProps } from "@jsonforms/react";
import { renderLayoutElements } from "@jsonforms/material-renderers";

export const ToolbarLayoutRenderer = ({
  uischema,
  renderers,
  cells,
  schema,
  path,
  enabled,
  visible,
}: LayoutProps) => {
  const theme = useTheme();
  const layout = uischema as ToolbarLayout;

  if (isEmpty(layout.buttons) || !visible) {
    return null;
  }

  return (
    <ButtonGroup sx={{ margin: theme.spacing(0, 1) }}>
      {renderLayoutElements(
        layout.buttons as UISchemaElement[],
        schema,
        path,
        enabled,
        renderers,
        cells
      )}
    </ButtonGroup>
  );
};

const tester: RankedTester = rankWith(10, uiTypeIs("ToolbarLayout"));

export default {
  tester,
  renderer: withJsonFormsLayoutProps(ToolbarLayoutRenderer),
};
