import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { useBackend } from "@app/hooks";

import { Navigate } from "@app/router";
import { PageError, PageLoading } from "@app/components";

export default function DashboardLayout() {
  const { isBackendReady, loggedInUser } = useBackend();

  if (!isBackendReady) {
    return null;
  }

  if (!loggedInUser) {
    // Require a user
    return (
      <Navigate
        // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
        to={{
          pathname: "/auth/login",
          search: `?redirectTo=${window.location.pathname}`
        }}
        replace={true}
      />
    );
  }

  return (
    <ErrorBoundary FallbackComponent={PageError}>
      <Suspense fallback={<PageLoading />}>
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  );
}
