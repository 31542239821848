import { useCallback, useMemo, type ReactNode } from "react";

import {
  Modal as MuiModal,
  type ModalProps as MuiModalProps,
  Box,
  styled,
  IconButton,
  Typography
} from "@mui/material";

// Direct import for avoiding circular dependency
import { Button } from "@app/components/ui/Button";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface ModalProps extends Omit<MuiModalProps, "children" | "title"> {
  children: ReactNode;
  title: ReactNode;
  width?: number | string;
}

interface HeaderImageProps {
  src: string;
  height?: string | number;
  actions: { label: React.ReactNode; onClick: () => Promise<unknown> }[];
}

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -45%)",
  maxWidth: "min(90vw, 980px)",
  maxHeight: "90%",
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  outline: "none",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column"
}));

const TitleBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  alignContent: "center",
  margin: theme.spacing(1, 2, 0, 2),
  padding: theme.spacing(3, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,

  h6: {
    fontSize: "1rem",
    fontWeight: "bold"
  }
}));

const ContentBox = styled("div")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(4, 6),
  overflow: "auto"
}));

const ImageBox = styled("div")(({ theme }) => ({
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  padding: theme.spacing(4),
  boxSizing: "border-box"
}));

const ActionButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  borderColor: theme.palette.common.white
}));

export function BannerModal(props: ModalProps) {
  const { children, width, title, ...rest } = props;
  const { onClose } = rest;

  const close = useCallback(
    (event: React.MouseEvent) => {
      if (onClose) {
        onClose(event, "escapeKeyDown");
      }
    },
    [onClose]
  );

  return (
    <MuiModal {...rest}>
      <ModalBox className={rest.open ? "MuiModal-open" : ""} sx={{ width }}>
        <TitleBox>
          <Typography variant='h4'>{title}</Typography>
          <IconButton onClick={close}>
            <HighlightOffIcon />
          </IconButton>
        </TitleBox>
        <ContentBox>{children}</ContentBox>
      </ModalBox>
    </MuiModal>
  );
}

export function HeaderImage({ src, height = "20vh", actions }: HeaderImageProps) {
  const sx = useMemo(
    () => ({
      backgroundImage:
        actions.length > 0
          ? `linear-gradient(180deg, rgba(0, 0, 0, 0), 50%, rgba(0, 0, 0, .6)), url(${src})`
          : `url(${src})`,
      height
    }),
    [actions.length, height, src]
  );

  return (
    <ImageBox sx={sx}>
      {actions.map((action, index) => (
        <ActionButton key={index} onClick={action.onClick} size='small'>
          {action.label}
        </ActionButton>
      ))}
    </ImageBox>
  );
}

BannerModal.HeaderImage = HeaderImage;
