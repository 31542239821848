import { useMemo, type ReactNode } from "react";
import { TextField as MuiTextField, type TextFieldProps } from "@mui/material";

import { FieldGroup } from "@app/components";

interface Props extends Omit<TextFieldProps, "label"> {
  label?: ReactNode;
  labelIcon?: ReactNode;
  separatedLabel?: boolean;
}

export function TextField({ separatedLabel = true, label = "", labelIcon, inputProps, ...props }: Props) {
  const { startAdornment, endAdornment, ...restInputProps } = inputProps || {};

  const memoizedInputProps = useMemo(() => ({ startAdornment, endAdornment }), [startAdornment, endAdornment]);

  return (
    <FieldGroup icon={labelIcon} label={separatedLabel ? label : undefined}>
      <MuiTextField
        label={separatedLabel ? "" : label}
        aria-label={separatedLabel ? label?.toString() : undefined}
        fullWidth
        inputProps={restInputProps}
        InputProps={memoizedInputProps}
        {...props}
      />
    </FieldGroup>
  );
}
