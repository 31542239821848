import { TextField, Popper, Box } from "@mui/material";
import { HexColorPicker } from "react-colorful";

import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  RankedTester,
  isStringControl,
  rankWith,
} from "@jsonforms/core";
import { FieldWrapper } from "../common/FieldWrapper";
import { useEffect, useRef, useState } from "react";
import { isCustomControl } from "../utils";

interface Props extends ControlProps {
  data: number;
  path: string;
  handleChange: (path: string, value: string) => void;
}

export function ColorPickerRenderer({
  label,
  description,
  data,
  path,
  visible,
  uischema,
  handleChange,
}: Props) {
  const anchorEl = useRef<HTMLDivElement>(null);
  const [currentValue, setCurrentValue] = useState<string>(String(data));
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    setCurrentValue(String(data));
  }, [data]);

  useEffect(() => {
    if (!visible) {
      setHasFocus(false);
    }
  }, [visible]);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (!hasFocus) {
        return;
      }

      if (anchorEl.current && !anchorEl.current.contains(e.target as Node)) {
        handleChange(path, currentValue);
        setHasFocus(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [path, currentValue, handleChange, hasFocus]);

  if (!visible) {
    return null;
  }

  return (
    <FieldWrapper
      label={label}
      description={description}
      uiSchema={uischema}
      ref={anchorEl}
    >
      <TextField
        fullWidth
        value={currentValue}
        onChange={(ev) => {
          setCurrentValue(ev.target.value);
          handleChange(path, ev.target.value);
        }}
        onFocus={() => setHasFocus(true)}
        onBlur={() => {
          handleChange(path, currentValue);
        }}
        InputProps={{
          endAdornment: (
            <Box
              sx={{
                width: "60px",
                height: "40px",
                marginRight: "8px",
                backgroundColor: currentValue,
                borderRadius: "4px",
                boxShadow: "1px 1px 3px rgba(0,0,0,0.5) inset",
                cursor: "pointer",
              }}
              onClick={() => {
                setHasFocus(!hasFocus);
              }}
            />
          ),
        }}
      />
      <Popper
        anchorEl={anchorEl.current}
        placement="bottom-end"
        open={hasFocus}
        disablePortal
        sx={{ p: 2, zIndex: 1 }}
      >
        <HexColorPicker color={currentValue} onChange={setCurrentValue} />
      </Popper>
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(15, (uiSchema, schema, context) => {
  return (
    isCustomControl(uiSchema, "ColorPicker") &&
    isStringControl(uiSchema, schema, context)
  );
});

export default {
  tester,
  renderer: withJsonFormsControlProps(ColorPickerRenderer),
};
