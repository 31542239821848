import { type SxProps, Box } from "@mui/material";
import { useCallback, useState, useEffect, type ReactNode } from "react";
import {
  useForm,
  type UseFormRegister,
  type UseFormSetError,
  type FormState,
  type Path,
  type FieldValues,
  type Control,
  type UseFormSetValue
} from "react-hook-form";

import { useTranslation } from "@app/hooks";

export type FormErrors<Data extends FieldValues> = {
  [K in keyof Data]?: ReactNode | string;
} & { root?: ReactNode | string };

interface FormProps<Data extends FieldValues> {
  children: (props: {
    control: Control<Data>;
    register: UseFormRegister<Data>;
    setError: UseFormSetError<Data>;
    setValue: UseFormSetValue<Data>;
    formState: FormState<Data>;
    errors: FormErrors<Data>;
    submitting: boolean;
  }) => React.ReactNode;
  initialData?: Data;
  onChange?: (data: Data) => void;
  onSubmit: (data: Data) => Promise<boolean>;
  errors?: FormErrors<Data>;
  sx?: SxProps;
}

export function Form<Data extends FieldValues>(props: FormProps<Data>) {
  const { onSubmit, onChange, initialData, errors: apiErrors, sx } = props;
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);
  const [normalizedErrors, setNormalizedErrors] = useState<FormErrors<Data>>({});

  const { register, handleSubmit, formState, setError, control, setValue, getValues, trigger } = useForm<Data>({
    mode: "onChange",
    reValidateMode: "onChange",
    values: initialData
  });

  useEffect(() => {
    if (apiErrors) {
      Object.keys(apiErrors).forEach((key) => {
        const typedKey = key as Path<Data>;
        setError(typedKey, {
          type: "manual",
          message: apiErrors[typedKey]?.toString()
        });
      });
    }
  }, [apiErrors, setError]);

  useEffect(() => {
    const errors = {} as FormErrors<Data>;

    Object.keys(formState.errors).forEach((key) => {
      const typedKey = key as keyof Data | "root";
      const error = formState.errors[typedKey];

      // @ts-expect-error: TODO: figure this out
      errors[typedKey] = error?.message || (error?.type && t(`forms.error.${error.type}`)) || t("forms.errors");
    }, {} as FormErrors<Data>);

    setNormalizedErrors(errors);
  }, [formState.errors, t]);

  const handleChange = useCallback(() => {
    if (onChange) {
      onChange(getValues());
    }
  }, [getValues, onChange]);

  const submit = useCallback(
    (data: Data) => {
      setSubmitting(true);

      onSubmit(data).finally(() => {
        // TODO: Fixme. This is not triggered when the promise is resolved.
        setSubmitting(false);
      });
    },
    [onSubmit]
  );

  return (
    <Box sx={sx}>
      <form noValidate onSubmit={handleSubmit(submit)} onBlur={() => trigger()} onChange={handleChange}>
        {props.children({ control, register, setError, setValue, formState, errors: normalizedErrors, submitting })}
      </form>
    </Box>
  );
}
