import { type CSSProperties, useEffect, useState } from "react";

import { type GameElement } from "@shared/game-engine";
import { getTransforms } from "@shared/game-player/utils/styles";
import { MDEditor } from "@shared/markdown/MDEditor";

interface Props {
  gameElement: GameElement;
  language: string;
  textField: string;
  onSubmit?: (value: string) => void;
  onChange?: (value: string) => void;
  onCancel?: () => void;
}

export function StageMDEditor(props: Props) {
  const [currentContent, setCurrentContent] = useState<string>("");

  const propValue = props.gameElement.translations?.[props.language]?.[props.textField];

  useEffect(() => {
    setCurrentContent(propValue || "");
  }, [propValue]);

  const wrapperStyle: CSSProperties = {
    position: "absolute",
    zIndex: 1,
    boxSizing: "border-box",
    outline: "8px solid #ffd700",
    ...getTransforms(props.gameElement.stage)
  };

  const properties = props.gameElement.properties || {};

  // Includes support for legacy fontStyle property
  const isItalic =
    properties.italic !== undefined
      ? properties.italic
      : ["bold-italic", "italic"].includes(properties.fontStyle as string);
  const isBold =
    properties.bold !== undefined ? properties.bold : ["bold-italic", "bold"].includes(properties.fontStyle as string);

  // Assumed that the game element is a text element
  const editorStyle: CSSProperties = {
    fontFamily: (properties.fontFamily as string) || "Arial",
    fontSize: (properties.fontSize as number) || 16,
    lineHeight: (properties.lineHeight as string) || "1em",
    fontStyle: isItalic ? "italic" : "normal",
    fontWeight: isBold ? "bold" : "normal",
    textDecoration: properties.underline ? "underline" : "none",
    color: (properties.color as string) || undefined,
    textAlign: (properties.textAlign as CSSProperties["textAlign"]) || "left",
    verticalAlign: (properties.verticalAlign as string) || "top",
    resize: "none",
    outline: "none"
  };

  return (
    <div
      style={wrapperStyle}
      // Prevent bubbling up so select & shortcut keys works
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <MDEditor
        content={currentContent}
        style={editorStyle}
        onSubmit={props.onSubmit}
        onCancel={props.onCancel}
        onChange={(value) => {
          setCurrentContent(value);
          props.onChange?.(value);
        }}
      />
    </div>
  );
}
