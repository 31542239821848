import { Switch, useTheme } from "@mui/material";

import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  RankedTester,
  isBooleanControl,
  rankWith,
} from "@jsonforms/core";
import { FieldWrapper } from "../common/FieldWrapper";

interface Props extends ControlProps {
  data: boolean;
  path: string;
  handleChange: (path: string, value: boolean) => void;
}

export function CheckboxFieldRenderer({
  label,
  description,
  data,
  path,
  handleChange,
  visible,
  uischema,
}: Props) {
  const theme = useTheme();

  if (!visible) {
    return null;
  }

  return (
    <FieldWrapper
      label={label}
      description={description}
      placement="header"
      uiSchema={uischema}
    >
      <Switch
        sx={{ margin: theme.spacing(-2, 0) }}
        checked={data}
        onChange={(ev) => handleChange(path, ev.target.checked)}
      />
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(10, (uiSchema, schema, context) => {
  return isBooleanControl(uiSchema, schema, context) ? true : false;
});

export default {
  tester,
  renderer: withJsonFormsControlProps(CheckboxFieldRenderer),
};
