import { useState } from "react";
import { Alert, InputAdornment, useTheme, IconButton } from "@mui/material";

import { useTranslation } from "@app/hooks";
import { Button, TextField, type FormErrors, Form } from "@app/components";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

export interface PasswordResetFormData {
  password: string;
}

interface PasswordResetFormProps {
  errors?: FormErrors<PasswordResetFormData>;
  onSubmit: (data: PasswordResetFormData) => Promise<boolean>;
}

export const PasswordResetForm = (props: PasswordResetFormProps) => {
  const { onSubmit, errors } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form onSubmit={onSubmit} errors={errors}>
      {({ register, submitting, formState: { errors, isValid } }) => (
        <>
          <TextField
            type={showPassword ? "text" : "password"}
            variant='outlined'
            margin='normal'
            required
            id='passwordReset-password'
            aria-label={t("passwordReset.password").toString()}
            placeholder={t("passwordReset.password").toString()}
            autoComplete='new-password'
            autoFocus
            error={!!errors.password}
            aria-errormessage={errors.password?.message}
            inputProps={{
              ...register("password", { required: true }),
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailOutlinedIcon color='info' />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label={t("passwordReset.showPassword").toString()}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityIcon color='info' /> : <VisibilityOffIcon color='info' />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          {errors.root && (
            <Alert sx={{ marginTop: theme.spacing(1) }} severity='error'>
              {errors.root.message}
            </Alert>
          )}

          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            sx={{ marginTop: theme.spacing(4) }}
            loading={submitting}
            disabled={!isValid}
          >
            {t("passwordReset.submit")}
          </Button>
        </>
      )}
    </Form>
  );
};
