import { type Accept, useDropzone } from "react-dropzone";

import { useTranslation } from "@app/hooks";

import AddIcon from "@mui/icons-material/AddCircleOutline";
import { UploadBox } from "@app/components";

interface Props {
  accept?: Accept;
  onDrop?: (files: File[]) => void;
}

export function ComponentUpload(props: Props) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.accept,
    onDrop: props.onDrop
  });

  const { t } = useTranslation();

  return (
    <UploadBox {...getRootProps()} className={isDragActive ? "dragging" : ""}>
      <AddIcon fontSize='large' />
      {t("editor.upload")}
      <input {...getInputProps()} />
    </UploadBox>
  );
}
