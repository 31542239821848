import { type ReactNode, useEffect, useRef, useState } from "react";
import { Box, Button, Menu, MenuItem, styled, Tooltip, type SxProps } from "@mui/material";
import { ToolbarButton } from "../ToolbarButton";

interface Item {
  label?: ReactNode;
  value: string;
  sx?: SxProps;
}

interface Props {
  label: ReactNode;
  value: string;
  items: Item[];
  icon?: React.ReactNode;
  noSelectionText?: boolean;
  onChange?: (value: string) => void;
}

const StyledButton = styled(Button)(() => ({
  lineHeight: 1,
  height: "100%"
}));

export function DropdownControl(props: Props) {
  const anchorEl = useRef<HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState<string>(props.value);

  useEffect(() => {
    setCurrent(props.value);
  }, [props.value]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleUpdate = (value: string) => {
    setCurrent(value);
    props.onChange?.(value);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const button = props.noSelectionText ? (
    <ToolbarButton icon={props.icon} title={props.label} onClick={handleClick} />
  ) : (
    <StyledButton
      variant='contained'
      color='info'
      aria-label={props.label?.toString()}
      aria-controls='dropdown-menu'
      aria-haspopup='true'
      onClick={handleClick}
      startIcon={props.icon}
    >
      {props.items.find((item) => item.value === current)?.label || current}
    </StyledButton>
  );

  return (
    <Box ref={anchorEl}>
      {props.label ? (
        <Tooltip title={props.label.toString()} placement='top'>
          <span>{button}</span>
        </Tooltip>
      ) : (
        button
      )}
      <Menu anchorEl={anchorEl.current} open={open} onClose={handleClose} keepMounted>
        {props.items.map((item, index) => (
          <MenuItem key={index} onClick={() => handleUpdate(item.value)} selected={item.value === current} sx={item.sx}>
            {item.label || item.value}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
