import { Box, styled } from "@mui/material";

import { useTranslation } from "@app/hooks";

import TrashIcon from "@mui/icons-material/Delete";
import { forwardRef } from "react";

const ComponentTrashBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.error.light}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  color: theme.palette.error.light,
  marginBottom: theme.spacing(2),
  opacity: 0.5,

  "&:hover": {
    backgroundColor: theme.palette.action.focus
  },

  "&.dragging": {
    backgroundColor: theme.palette.action.hover
  }
}));

export const ComponentTrash = forwardRef((_, ref) => {
  const { t } = useTranslation();

  return (
    <ComponentTrashBox ref={ref}>
      <TrashIcon fontSize='large' color='error' />
      {t("common.remove")}
    </ComponentTrashBox>
  );
});
