import { useTranslation } from "@app/hooks";
import { Box, Typography, useTheme } from "@mui/material";

import UfitoImg from "@shared/branding/imagery/art/ufito-cientifico-1.png";

export function ToDoTiny() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: theme.spacing(4),
        color: theme.palette.text.secondary,
        textAlign: "center"
      }}
    >
      <img src={UfitoImg} width={60} />
      <Typography align='center' mt={2}>
        {t("todo.tiny")}
      </Typography>
    </Box>
  );
}
