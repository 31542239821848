import {
  type TesterContext,
  rankWith as originalRankWith,
  hasType,
  resolveSchema,
  UISchemaElement,
} from "@jsonforms/core";

import type { JsonSchema, JsonUISchema } from "./types";
import isEmpty from "lodash/isEmpty";

/**
 * Check if the given UI schema element is a custom control.
 *
 * @param uiSchema
 * @param controlName
 * @returns
 */
export function isCustomControl(
  uiSchema: JsonUISchema | UISchemaElement,
  controlName: string
): boolean {
  return uiSchema.options?.customControl === controlName;
}

/**
 * Check if the given UI schema element is a custom layout.
 *
 * @param uiSchema
 * @param type
 * @returns
 */
export function isLayoutType(
  uiSchema: JsonUISchema | UISchemaElement,
  type: string
) {
  return uiSchema.type === type;
}

/**
 * Check if the given UI schema element is an array of a specific type.
 *
 * @param type
 * @param uiSchema
 * @param schema
 * @param context
 * @returns
 */
export function isArrayOfType(
  type: "string" | "number" | "boolean" | "object" | "array",
  uiSchema: JsonUISchema | UISchemaElement,
  schema: JsonSchema,
  context: TesterContext
): boolean {
  if (uiSchema.type !== "Control") {
    return false;
  }

  if (!("scope" in uiSchema) || isEmpty(uiSchema.scope)) {
    return false;
  }

  let currentDataSchema = schema;
  if (hasType(schema, "object")) {
    currentDataSchema = resolveSchema(
      schema,
      uiSchema.scope,
      context?.rootSchema
    ) as JsonSchema;
  }

  if (currentDataSchema === undefined) {
    // additional properties?
    const additionalPropertiesScope = uiSchema.scope
      .split(/\//g)
      .slice(0, -2) // remove /properties/propName
      .concat("additionalProperties")
      .join("/");

    currentDataSchema = resolveSchema(
      schema,
      additionalPropertiesScope,
      context?.rootSchema
    ) as JsonSchema;

    if (!currentDataSchema) {
      return false;
    }
  }

  if (!hasType(currentDataSchema, "array")) {
    return false;
  }

  if (
    !currentDataSchema.items ||
    Array.isArray(currentDataSchema.items) || // Ignore tuples
    !hasType(currentDataSchema.items, type)
  ) {
    return false;
  }

  return true;
}

/**
 * Check if the given UI schema element is a file upload control (base64 encoded).
 *
 * @param uiSchema
 * @param schema
 * @param context
 * @returns
 */
export function isFileUploadControl(
  uiSchema: JsonUISchema,
  schema: JsonSchema,
  context: TesterContext
) {
  if (uiSchema.type !== "Control") {
    return false;
  }

  if (!("scope" in uiSchema) || isEmpty(uiSchema.scope)) {
    return false;
  }

  let currentDataSchema = schema;
  if (hasType(schema, "object")) {
    currentDataSchema = resolveSchema(
      schema,
      uiSchema.scope,
      context?.rootSchema
    ) as JsonSchema;
  }

  if (!hasType(currentDataSchema, "string")) {
    return false;
  }

  if (currentDataSchema.format !== "data-url") {
    return false;
  }

  return true;
}

/**
 * Rank function with fixed types.
 *
 * @param rank
 * @param tester
 * @returns
 */
export function rankWith(
  rank: number,
  tester: (
    uiSchema: JsonUISchema,
    schema: JsonSchema,
    context: TesterContext
  ) => boolean
) {
  return originalRankWith(rank, tester as never);
}
