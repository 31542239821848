import { useMemo } from "react";
import { Checkbox as MuiCheckbox, type CheckboxProps, lighten, useTheme } from "@mui/material";

interface Props extends CheckboxProps {
  error?: boolean;
  inputProps?: Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "ref"> & {
    ref?: React.Ref<HTMLInputElement>;
  };
}

export const Checkbox = (props: Props) => {
  const { error, sx, inputProps, ...restProps } = props;
  const { onChange, ...restInputProps } = inputProps || {};
  const theme = useTheme();

  const sxCheckbox = useMemo(
    () => ({ ...sx, background: error ? lighten(theme.palette.error.light, 0.85) : "transparent" }),
    [error, sx, theme.palette.error.light]
  );

  return <MuiCheckbox onChange={onChange} {...restProps} inputProps={restInputProps} sx={sxCheckbox} />;
};
