import { useState, useCallback } from "react";
import { withJsonFormsArrayLayoutProps } from "@jsonforms/react";
import {
  ArrayLayoutProps,
  createDefaultValue,
  isPrimitiveArrayControl,
  RankedTester,
  rankWith,
} from "@jsonforms/core";

import { DeleteDialog } from "@jsonforms/material-renderers";

import { FieldWrapper } from "../common/FieldWrapper";
import { IconButton } from "@mui/material";
import { Icons } from "../common/Icons";
import { MaterialTableControl } from "../common/MaterialTableControl";

export function ArrayLayoutRenderer(props: ArrayLayoutProps) {
  const [open, setOpen] = useState(false);
  const [targetPath, setTargetPath] = useState<string | undefined>(undefined);
  const [rowData, setRowData] = useState<number | undefined>(undefined);

  const {
    removeItems,
    visible,
    uischema,
    label,
    path,
    description,
    schema,
    rootSchema,
    addItem,
  } = props;

  const openDeleteDialog = useCallback(
    (p: string, rowIndex: number) => {
      setOpen(true);
      setTargetPath(p);
      setRowData(rowIndex);
    },
    [setOpen, setTargetPath, setRowData]
  );

  const deleteCancel = useCallback(() => setOpen(false), [setOpen]);

  const deleteConfirm = useCallback(() => {
    if (!targetPath) {
      return;
    }

    const p = targetPath.substring(0, targetPath.lastIndexOf("."));
    p && rowData !== undefined && removeItems?.(p, [rowData])();
    setOpen(false);
  }, [removeItems, rowData, targetPath]);

  const deleteClose = useCallback(() => setOpen(false), [setOpen]);

  if (!visible) {
    return null;
  }

  return (
    <FieldWrapper
      label={label}
      description={description}
      uiSchema={uischema}
      actions={
        <IconButton
          onClick={addItem(path, createDefaultValue(schema, rootSchema))}
          size="small"
        >
          {Icons.add}
        </IconButton>
      }
    >
      <MaterialTableControl {...props} openDeleteDialog={openDeleteDialog} />
      <DeleteDialog
        open={open}
        onCancel={deleteCancel}
        onConfirm={deleteConfirm}
        onClose={deleteClose}
        acceptText={props.translations.deleteDialogAccept || ""}
        declineText={props.translations.deleteDialogDecline || ""}
        title={props.translations.deleteDialogTitle || ""}
        message={props.translations.deleteDialogMessage || ""}
      />
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(10, isPrimitiveArrayControl);

export default {
  tester,
  renderer: withJsonFormsArrayLayoutProps(ArrayLayoutRenderer),
};
