import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Routes } from "@generouted/react-router"; // Do this lazy once we optimize the import distribution
import TagManager from "react-gtm-module";
import { ErrorBoundary } from "react-error-boundary";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";

import { defaultTheme } from "@app/theme";
import { AppContextProvider, BackendProvider, LanguageProvider } from "@app/contexts";
import { FullScreenError, FullScreenLoading } from "@app/components";

import { createAnimationsStyleTag } from "@shared/game-player/utils/animations";

import "@shared/branding/fonts.css";
import "./index.css";

// Setup dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

// Setup TagManager
TagManager.initialize({
  gtmId: import.meta.env.VITE_GTM_ID,
  auth: import.meta.env.VITE_GTM_AUTH,
  preview: import.meta.env.VITE_GTM_PREVIEW,
  dataLayer: {
    environment: import.meta.env.MODE
  }
});

// Animations
createAnimationsStyleTag();

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <StrictMode>
  <ErrorBoundary FallbackComponent={FullScreenError}>
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Suspense fallback={<FullScreenLoading />}>
        <BackendProvider
          apiBaseUrl={import.meta.env.VITE_BACKEND_BASEURL || "/"}
          publicApiBaseUrl={import.meta.env.VITE_PUBLIC_BACKEND_BASEURL}
        >
          <LanguageProvider>
            <SnackbarProvider>
              <AppContextProvider>
                <Routes />
              </AppContextProvider>
            </SnackbarProvider>
          </LanguageProvider>
        </BackendProvider>
      </Suspense>
    </ThemeProvider>
  </ErrorBoundary>
  // </StrictMode>
);
