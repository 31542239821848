import { type MergeWithCustomizer } from "lodash";
import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";

/**
 * Merge two objects deeply, without mutating the original objects.
 *
 * @param value
 * @param changes
 * @returns
 */
export function mergeDeep<T>(value: T, changes: Partial<T>): T {
  return merge(JSON.parse(JSON.stringify(value)), changes);
}

/**
 * Merge two objects deeply, without mutating the original objects and using a customizer.
 *
 * @param value
 * @param changes
 * @param customizer
 * @returns
 */
export function mergeDeepWith<T>(value: T, changes: Partial<T>, customizer: MergeWithCustomizer): T {
  return mergeWith(JSON.parse(JSON.stringify(value)), changes, customizer);
}

/**
 * Merge two game data objects deeply, without mutating the original objects
 * and keeping the original merge behavior for objects.
 *
 * This replaces arrays and primitive values instead of merging them.
 *
 * @param value
 * @param changes
 * @returns
 */
export function mergeGameData<T>(value: T, changes: Partial<T>): T {
  return mergeDeepWith(value, changes, (objValue, srcValue) => {
    if (typeof objValue === "object" && !Array.isArray(objValue)) {
      // Keep the original merge behavior for objects
      return undefined;
    }

    return srcValue;
  });
}
