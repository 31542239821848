import { Box, Link, styled, Typography, useTheme } from "@mui/material";

import { Button } from "@app/components/ui/Button";
import { Modal } from "@app/components/layout/Modal";
import { usePersistedState, useTranslation } from "@app/hooks";
import SchoolIcon from "@mui/icons-material/School";

import Ufito from "@shared/branding/imagery/art/ufito-cohete.png";
const UFO_ACADEMY_URL =
  "https://academy.theufolab.com/?utm_source=dashboard&utm_medium=internal_link&utm_campaign=onboarding";

const ChatBotIcon = ({ size = 16 }: { size: number }) => {
  const theme = useTheme();

  return (
    <Box
      component='span'
      sx={{
        display: "inline-block",
        width: `${size}px`,
        height: `${size}px`,
        marginLeft: 1,
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        padding: theme.spacing(1.5),
        boxSizing: "padding-box"
      }}
    >
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800' height='100%' width='100%' role='img'>
        <path
          fill='#fff'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M400 26.2c-193.3 0-350 156.7-350 350 0 136.2 77.9 254.3 191.5 312.1 15.4 8.1 31.4 15.1 48.1 20.8l-16.5 63.5c-2 7.8 5.4 14.7 13 12.1l229.8-77.6c14.6-5.3 28.8-11.6 42.4-18.7C672 630.6 750 512.5 750 376.2c0-193.3-156.7-350-350-350zm211.1 510.7c-10.8 26.5-41.9 77.2-121.5 77.2-79.9 0-110.9-51-121.6-77.4-2.8-6.8 5-13.4 13.8-11.8 76.2 13.7 147.7 13 215.3.3 8.9-1.8 16.8 4.8 14 11.7z'
        ></path>
      </svg>
    </Box>
  );
};

const ImageBox = styled("div")(({ theme }) => ({
  background: "linear-gradient(90deg, #2a78b8 0%, #752fc3 100%)",
  boxSizing: "border-box",
  color: "#fff",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  width: "100%",
  height: "380px",
  fontSize: "32pt",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: theme.spacing(18),
  lineHeight: 1.1,
  fontFamily: "SourceSansPro, sans-serif",
  fontWeight: 600,
  marginBottom: theme.spacing(4),

  img: {
    height: "270px",
    transform: "rotate(-20deg)"
  }
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
  fontSize: "1.2em",
  marginBottom: theme.spacing(6),
  lineHeight: 1.1,
  textAlign: "left"
}));

export function WelcomeTour() {
  const [seen, setSeen] = usePersistedState("welcome-tour", false, "local");
  const { t } = useTranslation();

  return (
    <Modal open={!seen} onClose={() => setSeen(true)} title={t("tours.welcome.title")}>
      <ImageBox>
        {t("tours.welcome.image")}
        <img src={Ufito} alt='Ufito' />
      </ImageBox>
      <Box display='flex' flexDirection='row' alignItems='flex-start' gap={6} p={6}>
        <Box>
          <StyledParagraph paragraph sx={{ fontSize: "1.6em" }}>
            {t("tours.welcome.content1")}
          </StyledParagraph>
          <StyledParagraph paragraph>
            {t("tours.welcome.content2")}
            <ChatBotIcon size={32} />
          </StyledParagraph>
        </Box>
        <Box>
          <Link href={UFO_ACADEMY_URL} target='_blank'>
            <Button variant='contained' startIcon={<SchoolIcon />}>
              {t("menu.ufoacademy")}
            </Button>
          </Link>
        </Box>
      </Box>
    </Modal>
  );
}
