import { Avatar, type AvatarProps, useTheme } from "@mui/material";

import DefaultUserIcon from "@app/assets/user.svg";

export function DefaultAvatar(props: AvatarProps) {
  const theme = useTheme();

  return (
    <Avatar
      {...props}
      alt='User'
      src={DefaultUserIcon}
      sx={{
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(1.5),
        boxSizing: "border-box",
        ...props.sx
      }}
    />
  );
}
