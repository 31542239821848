import { Box, Button, Popper, Slider, styled, Tooltip } from "@mui/material";
import { type ReactNode, useCallback, useEffect, useRef, useState } from "react";

interface Props {
  icon?: ReactNode;
  label?: ReactNode;
  value: number;
  min?: number;
  max?: number;
  multiplier?: number;
  step?: number;
  onChange: (value: number) => void;
  withSlider?: boolean;
  inputOnFocus?: boolean;
}

const StyledButton = styled(Button)(({ theme }) => ({
  input: {
    width: "40px",
    height: "100%",
    padding: "0 0.5rem",
    margin: 0,
    border: "none",
    fontSize: "1rem",
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
    textAlign: "center",
    MozAppearance: "textfield",
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0
    },
    "&:focus": {
      outline: "none",
      background: theme.palette.background.paper
    },
    transition: theme.transitions.create(["width", "margin"], { duration: theme.transitions.duration.shortest })
  }
}));

export function InputNumberControl(props: Props) {
  const { value, min = 0, max, step, multiplier = 1, onChange, withSlider, icon, label, inputOnFocus } = props;

  const anchorEl = useRef<HTMLElement>(null);
  const [current, setCurrent] = useState<number>(value);
  const [open, setOpen] = useState(false);

  const isValid = useCallback(
    (num: number) => {
      if (isNaN(num)) {
        return false;
      }

      if (min !== undefined && num < min) {
        return false;
      }

      if (max !== undefined && num > max) {
        return false;
      }

      return true;
    },
    [min, max]
  );

  const round = useCallback(
    (value: number) => {
      if (!step || isNaN(step) || step <= 0) {
        return value;
      }

      return Math.round(value / step) * step;
    },
    [step]
  );

  const consolidate = useCallback(() => {
    if (isValid(current)) {
      onChange(round(Number(current)) / multiplier);
    } else {
      // cancel
      setCurrent(value * multiplier);
    }
  }, [current, isValid, multiplier, onChange, round, value]);

  const handleFocus = useCallback(() => {
    setOpen(true);
  }, []);

  const handleBlur = useCallback(() => {
    setOpen(false);
    consolidate();
  }, [consolidate]);

  const handleChange = useCallback(
    (value: number) => {
      setCurrent(value);

      if (isValid(value)) {
        onChange(round(value) / multiplier);
      }
    },
    [isValid, multiplier, onChange, round]
  );

  useEffect(() => {
    setCurrent(value * multiplier);
  }, [multiplier, value]);

  // Handle outside click
  useEffect(() => {
    if (!withSlider || !open) {
      return;
    }

    document.addEventListener("click", handleBlur);

    return () => {
      document.removeEventListener("click", handleBlur);
    };
  }, [handleBlur, open, withSlider]);

  const button = (
    <StyledButton variant='contained' color='info' startIcon={icon} onClick={handleFocus} sx={{ height: "100%" }}>
      <input
        type='number'
        value={current}
        step={step}
        min={min}
        max={max}
        onChange={(e) => handleChange(e.target.valueAsNumber)}
        onFocus={handleFocus}
        onBlur={!withSlider ? handleBlur : undefined}
        onKeyDown={(event) => event.stopPropagation()}
        onKeyUp={(event) => event.stopPropagation()}
        style={
          inputOnFocus && !open
            ? {
                width: 0,
                margin: "-.9rem"
              }
            : {}
        }
      />
    </StyledButton>
  );

  return (
    <Box ref={anchorEl} onClick={(e) => e.stopPropagation()} sx={{ height: "100%" }}>
      {label ? (
        <Tooltip title={label.toString()} placement='top'>
          <span>{button}</span>
        </Tooltip>
      ) : (
        button
      )}
      {withSlider && (
        <Popper anchorEl={anchorEl.current} open={open} disablePortal sx={{ p: 2 }}>
          <Slider
            value={current}
            step={step}
            min={min}
            max={max}
            sx={{ width: 140 }}
            onChange={(_e, value) => setCurrent(value instanceof Array ? value[0] : value)}
            onChangeCommitted={(_e, value) => handleChange(value instanceof Array ? value[0] : value)}
            disableSwap
            size='small'
          />
        </Popper>
      )}
    </Box>
  );
}
