import { useCallback, useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "@app/router";

import { PasswordResetForm, type PasswordResetFormData } from "@app/components";
import { useQuery, useBackend, useTranslation } from "@app/hooks";

export default function PasswordResetPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { auth } = useBackend();
  const query = useQuery();
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const handleSubmit = useCallback(
    async (data: PasswordResetFormData): Promise<boolean> => {
      setError("");
      try {
        await auth.post("/update-password", {
          token: query.get("token"),
          email: query.get("email"),
          password: data.password
        });

        navigate("/auth/password-reset-success");
        return true;
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError(t("passwordReset.error").toString());
        }

        return false;
      }
    },
    [auth, navigate, query, t]
  );

  return (
    <>
      <Typography variant='h5' sx={{ display: "block", margin: theme.spacing(2, 0, 0, 0) }}>
        {t("passwordReset.title")}
      </Typography>

      <Typography variant='body1' sx={{ margin: theme.spacing(2, 0) }}>
        {t("passwordReset.message")}
      </Typography>

      <PasswordResetForm errors={error ? { root: error } : undefined} onSubmit={handleSubmit} />

      <Typography textAlign='center' sx={{ marginTop: theme.spacing(2) }}>
        <Link to='/auth/login'>{t("passwordReset.backToLogin")}</Link>
      </Typography>
    </>
  );
}
