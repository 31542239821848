import { type ReactNode } from "react";

import { Modal as MuiModal, Typography, IconButton, useTheme, Fade } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

// Reuse components from shared/form-builder
import { ModalBox, ModalButtonBar, ModalContentBox, TitleBox } from "@shared/form-builder/common/PropertiesModal";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const SLOT_PROPS = {
  backdrop: {
    timeout: 500
  }
};

interface Props {
  title?: ReactNode;
  children?: ReactNode;
  open?: boolean;
  onClose?: () => void;
  withTabs?: boolean;
  footer?: ReactNode;
}

export function Modal(props: Props) {
  const theme = useTheme();

  return (
    <MuiModal
      open={props.open || false}
      onClose={props.onClose}
      onKeyDown={(e) => {
        // Avoid propagate events to the editor
        e.stopPropagation();
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={SLOT_PROPS}
    >
      <Fade in={props.open}>
        <ModalBox className={props.open ? "MuiModal-open" : ""}>
          <TitleBox>
            <Typography variant='h5'>{props.title}</Typography>
            <IconButton onClick={props.onClose}>
              <HighlightOffIcon />
            </IconButton>
          </TitleBox>
          <ModalContentBox sx={{ p: props.withTabs ? 0 : theme.spacing(4) }}>{props.children}</ModalContentBox>
          {props.footer && <ModalButtonBar sx={{ marginTop: 0 }}>{props.footer}</ModalButtonBar>}
        </ModalBox>
      </Fade>
    </MuiModal>
  );
}
