import { type ReactNode } from "react";
import { Grid, Typography, useTheme } from "@mui/material";

import { type GameDefinitionDto } from "@shared/api-client";

import { ProjectCard, type ProjectCardProps, SectionTitle } from "@app/components";
import { type MenuItem } from "@app/types";

import UfitoImg from "@shared/branding/imagery/art/ufito-cohete.png";

export function ProjectItem(props: ProjectCardProps) {
  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <ProjectCard {...props} />
    </Grid>
  );
}

interface Props {
  title?: ReactNode;
  games: GameDefinitionDto[];
  actions?: MenuItem[];
  emtpyMessage?: ReactNode;
  itemHoverIcon?: ReactNode;
  onSelect?: (game: GameDefinitionDto) => void;
  onEdit?: (game: GameDefinitionDto) => void;
  onDelete?: (game: GameDefinitionDto) => void;
  onClone?: (game: GameDefinitionDto) => void;
  onOpenBanner?: (game: GameDefinitionDto) => void;
}

export function ProjectList({
  games,
  title,
  emtpyMessage,
  actions,
  itemHoverIcon,
  onSelect,
  onEdit,
  onDelete,
  onClone,
  onOpenBanner
}: Props) {
  const theme = useTheme();

  return (
    <>
      {title && <SectionTitle title={title} actions={actions} />}

      <Grid container spacing={2}>
        {games.map((game) => (
          <ProjectItem
            key={game.uuid}
            game={game}
            onClick={() => onSelect?.(game)}
            onEdit={onEdit ? () => onEdit(game) : undefined}
            onDelete={onDelete ? () => onDelete(game) : undefined}
            onClone={onClone ? () => onClone(game) : undefined}
            onOpenBanner={onOpenBanner ? () => onOpenBanner(game) : undefined}
            hoverIcon={itemHoverIcon}
          />
        ))}
        {!games.length && (
          <Grid item xs={12} textAlign='center' marginTop={theme.spacing(8)}>
            <img src={UfitoImg} height='100px' />
            <Typography variant='body2'>{emtpyMessage}</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}
