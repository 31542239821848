import type { GameDataInfoDto } from "@shared/api-client";
import type { JsonSchema, JsonUISchema } from "@shared/form-builder";

export const getGameTimerSchema = (gameInfo?: GameDataInfoDto): JsonSchema => ({
  type: "object",
  title: "Temporizador",
  properties: {
    startLevelId: {
      type: "string",
      title: "Comenzar en el nivel:",
      description: "El reloj comenza a contar desde el momento que el jugador entra en la escena seleccionada",
      oneOf:
        gameInfo?.levels.sort().map((level) => ({
          const: level,
          title: level
        })) || []
    },
    duration: {
      type: "number",
      title: "Duración del juego en minutos",
      minimum: 1
    }
  }
});

export const GameTimerUISchema: JsonUISchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/startLevelId"
    },
    {
      type: "Control",
      scope: "#/properties/duration",
      rule: {
        effect: "HIDE",
        condition: {
          scope: "#/properties/startLevelId",
          schema: {
            anyOf: [{ not: { type: "string" } }, { const: "" }]
          }
        }
      }
    } as JsonUISchema // Why??
  ]
};
