import { Box, Button, Popper, styled, Tooltip } from "@mui/material";
import { type ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";

interface Props {
  icon?: ReactNode;
  label?: ReactNode;
  value: string;
  onChange: (value: string) => void;
  inputOnFocus?: boolean;
}

const StyledButton = styled(Button)(({ theme }) => ({
  input: {
    width: "78px",
    height: "100%",
    padding: "0 0.5rem",
    margin: 0,
    border: "none",
    fontSize: "1rem",
    backgroundColor: "transparent",
    borderRadius: "4px",
    boxShadow: "1px 1px 3px rgba(0,0,0,0.5) inset",
    color: theme.palette.text.primary,
    textAlign: "center",
    "&:focus": {
      outline: "none",
      background: theme.palette.background.paper
    },
    transition: theme.transitions.create(["width", "margin", "color", "backgroundColor"], {
      duration: theme.transitions.duration.shortest
    })
  }
}));

export function InputColorControl(props: Props) {
  const { value, onChange, icon, inputOnFocus, label } = props;

  const debounceRef = useRef<unknown>();
  const anchorEl = useRef<HTMLElement>(null);
  const [current, setCurrent] = useState<string>(value);
  const [open, setOpen] = useState(false);

  const isValid = useCallback((color: string) => {
    if (!color || !/^#[0-9A-F]{6}$/i.test(color)) {
      return false;
    }

    return true;
  }, []);

  const consolidate = useCallback(() => {
    if (isValid(current)) {
      onChange(current);
    } else {
      // cancel
      setCurrent(value);
    }
  }, [current, isValid, onChange, value]);

  const handleFocus = useCallback(() => {
    setOpen(true);
  }, []);

  const handleBlur = useCallback(() => {
    setOpen(false);
    consolidate();
  }, [consolidate]);

  const handleChange = useCallback(
    (value: string) => {
      setCurrent(value);

      if (isValid(value)) {
        onChange(value);
      }
    },
    [isValid, onChange]
  );

  const debouncedHandleChange = useCallback(
    (value: string) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current as number);
      }
      debounceRef.current = setTimeout(() => handleChange(value), 100);
    },
    [handleChange]
  );

  useEffect(() => {
    setCurrent(value);
  }, [value]);

  // Handle outside click
  useEffect(() => {
    if (!open) {
      return;
    }

    document.addEventListener("click", handleBlur);

    return () => {
      document.removeEventListener("click", handleBlur);
    };
  }, [open, handleBlur]);

  const button = (
    <StyledButton variant='contained' color='info' startIcon={icon} onClick={handleFocus} sx={{ height: "100%" }}>
      <input
        type='text'
        value={current.toUpperCase()}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={handleFocus}
        onKeyDown={(event) => event.stopPropagation()}
        onKeyUp={(event) => event.stopPropagation()}
        style={
          inputOnFocus && !open
            ? {
                width: "26px",
                backgroundColor: current,
                color: "transparent"
              }
            : {
                boxShadow: "none"
              }
        }
      />
    </StyledButton>
  );

  return (
    <Box ref={anchorEl} onClick={(e) => e.stopPropagation()} sx={{ height: "100%" }}>
      {label ? (
        <Tooltip title={label.toString()} placement='top'>
          <span>{button}</span>
        </Tooltip>
      ) : (
        button
      )}
      <Popper anchorEl={anchorEl.current} placement='bottom-start' open={open} disablePortal sx={{ p: 2 }}>
        <HexColorPicker color={current} onChange={debouncedHandleChange} />
      </Popper>
    </Box>
  );
}
