import type { JsonSchema, JsonUISchema } from "@shared/form-builder";
import { type GameCategoryDto } from "@shared/api-client";
import { type GetTranslationStringCb } from "@app/contexts";

export function getGameDefinitionSchema(categories: GameCategoryDto[], t: GetTranslationStringCb): JsonSchema {
  return {
    type: "object",
    properties: {
      fgImage: {
        type: "string",
        title: t("fields.gameDefinition.fgImage"),
        format: "data-url"
      },
      bgImage: {
        type: "string",
        title: t("fields.gameDefinition.bgImage"),
        format: "data-url"
      },
      name: {
        type: "string",
        title: t("fields.gameDefinition.name")
      },
      description: {
        type: "string",
        title: t("fields.gameDefinition.description")
      },
      category: {
        type: "string",
        title: t("fields.gameDefinition.category"),
        enum: categories.length > 0 ? categories.map((category) => category.name) : undefined
      }
    },
    required: ["name", "description", "category"]
  };
}

export function getGameDefinitionUISchema(t: GetTranslationStringCb): JsonUISchema {
  return {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/name",
        options: {
          icon: "edit",
          flatStyle: true
        }
      },
      {
        type: "Control",
        scope: "#/properties/description",
        options: {
          icon: "edit",
          flatStyle: true,
          multi: true
        }
      },
      {
        type: "Control",
        scope: "#/properties/category",
        options: {
          icon: "category",
          flatStyle: true
        }
      },
      {
        type: "HorizontalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/fgImage",
            options: {
              icon: "photo",
              flatStyle: true,
              info: t("fields.gameDefinition.fgImageDescription")
            }
          },
          {
            type: "Control",
            scope: "#/properties/bgImage",
            options: {
              icon: "photo",
              flatStyle: true,
              info: t("fields.gameDefinition.bgImageDescription")
            }
          }
        ]
      }
    ]
  };
}
