import type { JsonSchema, JsonUISchema } from "@shared/form-builder";

export const GameThemeSchema: JsonSchema = {
  type: "object",
  properties: {
    text: {
      type: "string",
      title: "Color de texto"
    },
    background: {
      type: "string",
      title: "Color de fondo"
    }
  }
};

export const GameThemeUISchema: JsonUISchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/text",
          options: {
            customControl: "ColorPicker",
            icon: "color",
            flatStyle: true
          }
        },
        {
          type: "Control",
          scope: "#/properties/background",
          options: {
            customControl: "ColorPicker",
            icon: "color",
            flatStyle: true
          }
        }
      ]
    }
  ]
};
