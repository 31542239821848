import { type ReactNode, useCallback, useContext, useMemo } from "react";
import { useSnackbar } from "notistack";
import { type ConfirmOptions, useConfirm } from "material-ui-confirm";
import { type AlertColor } from "@mui/material";
import { useMatches } from "react-router-dom";

import { AppContext } from "@app/contexts";
import { type DrawerMode, type MenuItem } from "@app/types";
import { useTranslation } from "@app/hooks";

type ExtendedAlertColor = AlertColor | "🦸‍♂️";

/**
 * Helper for easy access to global app actions in the context.
 * Not meant to handle reading the state, only for dispatching actions.
 *
 * @returns
 */
export function useAppContext() {
  const { dispatch, state } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const actualConfirm = useConfirm();
  const pathMatches = useMatches();

  const pushMenu = useCallback(
    (level: number, menu: MenuItem[], params = {}) => {
      dispatch({ type: "pushMenu", payload: { level, menu, params } });
    },
    [dispatch]
  );

  const popMenu = useCallback(
    (level: number) => {
      dispatch({ type: "popMenu", payload: level });
    },
    [dispatch]
  );

  const setPageTitle = useCallback(
    (title: ReactNode = "") => {
      dispatch({ type: "setPageTitle", payload: title });
    },
    [dispatch]
  );

  const setHeaderContent = useCallback(
    (content: ReactNode = null) => {
      dispatch({ type: "setHeaderContent", payload: content });
    },
    [dispatch]
  );

  const expandView = useCallback(
    (expand: boolean) => {
      dispatch({ type: "setCanvasMode", payload: expand });
    },
    [dispatch]
  );

  const setDrawerMode = useCallback(
    (mode: DrawerMode) => {
      dispatch({ type: "setDrawerMode", payload: mode });
    },
    [dispatch]
  );

  const enableSuperPowers = useCallback(
    (enable: boolean) => {
      dispatch({ type: "enableSuperPowers", payload: enable });
    },
    [dispatch]
  );

  const currentMatchedRoute = useMemo(
    () => `/${pathMatches[pathMatches.length - 1].id.replace(/\[(\w+)\]/g, ":$1")}`,
    [pathMatches]
  );

  const isCurrentRoute = useCallback(
    (path: string, exact = false) => {
      return pathMatches.some((match) => {
        const convertedPath = path.replace(/:(\w+)/g, "[$1]").replace(/^\//g, "");
        return exact ? convertedPath === match.id : match.id.startsWith(convertedPath);
      });
    },
    [pathMatches]
  );

  // Not an AppContext feature, but a global notification helper
  const notify = useCallback(
    (message: ReactNode, type: ExtendedAlertColor = "info", autoHide: number | false = 5000) => {
      if (!message) {
        return;
      }

      const sp = type === "🦸‍♂️";

      // remove empty nodes
      if (Array.isArray(message)) {
        message = message.filter((m) => m);
      }

      enqueueSnackbar((sp ? `${type} ` : "") + message.toString(), {
        variant: sp ? "success" : type,
        autoHideDuration: autoHide || null,
        anchorOrigin: { vertical: "top", horizontal: "center" },
        hideIconVariant: sp,
        style: sp ? { backgroundColor: "purple", color: "#fff", fontWeight: "bold" } : undefined
      });
    },
    [enqueueSnackbar]
  );

  const confirm = useCallback(
    (options: ConfirmOptions) =>
      actualConfirm({
        confirmationText: t("common.confirm"),
        cancellationText: t("common.cancel"),
        ...options
      }),
    [actualConfirm, t]
  );

  return {
    expandView,
    setDrawerMode,
    setPageTitle,
    setHeaderContent,
    pushMenu,
    popMenu,
    notify,
    confirm,
    currentMatchedRoute,
    isCurrentRoute,
    enableSuperPowers,
    superPowers: state.superPowers,
    state
  };
}
