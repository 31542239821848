import { ApiRestClient } from "../ApiRest";
import { UserDto } from "../dtos";

type Routes = {
  "/": UserDto;
};

export class UserApiClient extends ApiRestClient<Routes> {
  constructor(baseUrl: string) {
    super(baseUrl.replace(/\/$/, "") + "/me");
  }
}
