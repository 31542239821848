import type { JsonSchema, JsonUISchema } from "@shared/form-builder";

export const GameElementStageSchema: JsonSchema = {
  type: "object",
  properties: {
    x: { title: "Posición X", type: "number", default: 0 },
    y: { title: "Posición Y", type: "number", default: 0 },
    originX: { title: "Posición de origen X", type: "number", minimum: 0, maximum: 1, default: 0.5 },
    originY: { title: "Posición de origen Y", type: "number", minimum: 0, maximum: 1, default: 0.5 },
    width: { title: "Ancho", type: ["number", "null"], minimum: 0, default: null },
    height: { title: "Alto", type: ["number", "null"], minimum: 0, default: null },
    scale: { title: "Escala", type: "number", minimum: 0, default: 1 },
    rotation: { title: "Rotación", type: "number", minimum: 0, maximum: 360, default: 0 },
    opacity: { title: "Opacidad", type: "number", minimum: 0, maximum: 1, default: 1 }
  }
};

export const GameElementStageUISchema: JsonUISchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        { type: "Control", scope: "#/properties/x" },
        { type: "Control", scope: "#/properties/y" }
      ]
    },
    {
      type: "HorizontalLayout",
      elements: [
        { type: "Control", scope: "#/properties/width" },
        { type: "Control", scope: "#/properties/height" }
      ]
    },
    // {
    //   type: "HorizontalLayout",
    //   elements: [
    //     { type: "Control", scope: "#/properties/originX" },
    //     { type: "Control", scope: "#/properties/originY" }
    //   ]
    // },
    {
      type: "HorizontalLayout",
      elements: [
        { type: "Control", scope: "#/properties/scale" },
        { type: "Control", scope: "#/properties/rotation" },
        { type: "Control", scope: "#/properties/opacity" }
      ]
    }
  ]
};
