import { ApiRestClient } from "../ApiRest";
import { GamePluginDto } from "../dtos";

type Routes = {
  "/": GamePluginDto;
};

export class PluginManagerApiClient extends ApiRestClient<Routes> {
  constructor(baseUrl: string) {
    super(baseUrl.replace(/\/$/, "") + "/plugins");
  }
}
