import { useDropzone } from "react-dropzone";

import { withJsonFormsControlProps } from "@jsonforms/react";
import { ControlProps, RankedTester, rankWith } from "@jsonforms/core";
import { FieldWrapper } from "../common/FieldWrapper";
import { useEffect, useState } from "react";
import { isFileUploadControl } from "../utils";
import type { JsonSchema, JsonUISchema } from "../types";
import { Icons } from "../common/Icons";
import { DropZone } from "../common/DropZone";

interface Props extends ControlProps {
  data: string;
  path: string;
  handleChange: (path: string, value: string) => void;
}

const handleFileDrop = async (
  files: File[],
  onLoad: (reader: FileReader) => void
) => {
  const file = files[0];
  const reader = new FileReader();
  reader.onload = () => onLoad(reader);
  reader.readAsDataURL(file);
};

export function FileUploadFieldRenderer({
  label,
  description,
  data,
  path,
  visible,
  uischema,
  handleChange,
}: Props) {
  const [currentValue, setCurrentValue] = useState<string>("");

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) =>
      handleFileDrop(files, (reader) => {
        handleChange(path, reader.result?.toString() || "");
      }),
  });

  useEffect(() => {
    setCurrentValue(data !== undefined ? data : "");
  }, [data]);

  if (!visible) {
    return null;
  }

  const classes: string[] = [
    isDragActive ? "dropping" : "",
    currentValue ? "hasImage" : "",
  ].filter(Boolean);

  return (
    <FieldWrapper label={label} description={description} uiSchema={uischema}>
      <DropZone className={classes.join(" ")} {...getRootProps()}>
        {currentValue && <img src={currentValue} />}
        {!currentValue && (
          <>
            {Icons.photo}
            <p>Arrastra y suelta o haz click para buscar</p>
          </>
        )}
      </DropZone>
      <input {...getInputProps()} />
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(20, (uischema, schema, context) =>
  isFileUploadControl(uischema as JsonUISchema, schema as JsonSchema, context)
);

export default {
  tester,
  renderer: withJsonFormsControlProps(FileUploadFieldRenderer),
};
