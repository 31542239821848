import { useMemo, type FC, type ReactNode } from "react";
import dayjs from "dayjs";
import {
  Avatar,
  Badge,
  type BadgeOrigin,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Typography,
  useTheme
} from "@mui/material";

import { type UserDto } from "@shared/api-client";
import { DefaultAvatar } from "@app/components";
import { useTranslation } from "@app/hooks";

import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckIcon from "@mui/icons-material/Check";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

// [icon, spacing]
const SIZES = {
  small: [32, 1],
  medium: [64, 1],
  large: [128, 2]
};

const ANCHOR_BADGE: BadgeOrigin = { vertical: "top", horizontal: "right" };
const SX_POINTER = { cursor: "pointer" };

const StyledTypography = styled(Typography)({
  lineHeight: "1em"
});

const StyledListItem = styled(ListItem)({ margin: 0, padding: 0 });
const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({ minWidth: 0, marginRight: theme.spacing(1) }));
const StyledListItemText = styled(ListItemText)({ margin: 0 });

interface Props {
  vertical?: boolean;
  size?: "small" | "medium" | "large";
  user: UserDto;
  card?: boolean;
  uploadNewAvatar?: () => void;
}

export function ProfileWidget(props: Props) {
  const { user, uploadNewAvatar, vertical, size = "medium", card } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const data: { Icon?: FC; text?: ReactNode }[] = [
    {
      text: (
        <StyledTypography variant='h6'>
          {user.firstName} {user.lastName}
        </StyledTypography>
      )
    },
    { text: <Typography variant='button'>({user.username})</Typography> },
    {
      text: t("common.memberSince", {
        date: user.createdAt ? dayjs(user.createdAt).format(t("common.dateFormat").toString()) : t("common.unknown")
      })
    },
    user.verified
      ? { Icon: CheckIcon, text: t("account.profile.verified") }
      : { Icon: MoreHorizIcon, text: t("account.profile.notVerified") }
  ];

  const sxPaper = useMemo(
    () =>
      card
        ? {
            padding: theme.spacing(SIZES[size][1]),
            width: SIZES[size][0] * (vertical ? 1.5 : 3)
          }
        : {
            backgroundColor: "transparent"
          },
    [card, size, theme, vertical]
  );

  const sxBadgeContent = useMemo(
    () => ({
      bgcolor: theme.palette.background.paper,
      borderRadius: "50%",
      padding: theme.spacing(SIZES[size][1] / 2),
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: theme.palette.primary.main
    }),
    [size, theme]
  );

  const sxAvatar = useMemo(
    () => ({
      width: SIZES[size][0],
      height: SIZES[size][0],
      marginBottom: vertical ? theme.spacing(SIZES[size][1]) : 0
    }),
    [size, theme, vertical]
  );

  const sxDefaultAvatar = useMemo(
    () => ({
      width: SIZES[size][0],
      height: SIZES[size][0],
      padding: theme.spacing(SIZES[size][1] * 2),
      marginBottom: vertical ? theme.spacing(SIZES[size][1]) : 0
    }),
    [size, theme, vertical]
  );

  const sxMenuList = useMemo(
    () => ({ flexGrow: 1, padding: 0, marginLeft: vertical ? 0 : theme.spacing(SIZES[size][1] * 2) }),
    [size, theme, vertical]
  );

  return (
    <Paper elevation={0} sx={sxPaper}>
      <Box display='flex' flexDirection={vertical ? "column" : "row"} alignItems='center'>
        <Badge
          overlap='circular'
          anchorOrigin={ANCHOR_BADGE}
          onClick={uploadNewAvatar}
          sx={SX_POINTER}
          badgeContent={
            uploadNewAvatar ? (
              <Box sx={sxBadgeContent}>
                <ModeEditOutlineOutlinedIcon />
              </Box>
            ) : null
          }
        >
          {user?.avatar ? <Avatar src={user.avatar} sx={sxAvatar} /> : <DefaultAvatar sx={sxDefaultAvatar} />}
        </Badge>
        <List sx={sxMenuList}>
          {data.map(
            (item, index) =>
              item.text && (
                <StyledListItem key={index}>
                  {item.Icon && (
                    <StyledListItemIcon>
                      <item.Icon />
                    </StyledListItemIcon>
                  )}
                  <StyledListItemText primary={item.text} />
                </StyledListItem>
              )
          )}
        </List>
      </Box>
    </Paper>
  );
}
