import { type ReactNode } from "react";
import { TextareaAutosize, type TextareaAutosizeProps, styled } from "@mui/material";

import { FieldGroup } from "@app/components";

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => {
  return {
    boxSizing: "border-box",
    width: "100%",
    padding: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.grey[400]}`,
    "&:focus": {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
      outline: "none"
    }
  };
});

interface Props extends Omit<TextareaAutosizeProps, "label"> {
  label?: ReactNode;
  labelIcon?: ReactNode;
  separatedLabel?: boolean;
  inputProps?: Omit<
    React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    "ref"
  > & {
    ref?: React.Ref<HTMLTextAreaElement>;
  };
}

export function TextareaField({ separatedLabel = true, label = "", labelIcon, inputProps, ...props }: Props) {
  const { ref, ...restInputProps } = inputProps || {};

  return (
    <FieldGroup icon={labelIcon} label={separatedLabel ? label : undefined}>
      <StyledTextarea aria-label={label?.toString()} {...props} {...restInputProps} ref={ref} />
    </FieldGroup>
  );
}
