import { useCallback, useState } from "react";

interface Meta extends Record<string, unknown> {
  ts: number;
}

interface Options {
  pasteOnce?: boolean;
}

interface ClipboardData<T = unknown> {
  meta: Meta;
  content: T;
  pasteOnce: boolean;
  pasteCount: number;
}

type CopiedValue = string | null;

type CopyFn = (input: unknown, meta?: Partial<Meta>, options?: Options) => boolean;
type getDataFn = <T>() => ClipboardData<T> | null;
type hasDataFn = () => boolean;

export function useClipboard(): [CopyFn, getDataFn, hasDataFn] {
  const [data, setData] = useState<CopiedValue>();
  const [pasteCount, setPasteCount] = useState(0);
  const [pasteOnce, setPasteOnce] = useState(false);

  const copy: CopyFn = useCallback((input: unknown, meta: Partial<Meta> = {}, options: Options = {}) => {
    const dataStr = JSON.stringify({ meta: { ...meta, ts: Date.now() }, content: input });
    setData(dataStr);
    setPasteOnce(!!options.pasteOnce);
    setPasteCount(0);
    return true;
  }, []);

  const getData: getDataFn = useCallback(<T>(): ClipboardData<T> | null => {
    if (!data) {
      return null;
    }

    try {
      const parsed = JSON.parse(data) as ClipboardData<T>;

      if (pasteOnce) {
        setData(null);
        setPasteOnce(false);
      }

      setPasteCount(pasteCount + 1);
      return { ...parsed, pasteOnce, pasteCount: pasteCount + 1 };
    } catch (_error) {
      // ignore
    }

    return null;
  }, [data, pasteCount, pasteOnce]);

  const hasData = useCallback(() => !!data, [data]);

  return [copy, getData, hasData];
}
