import type { JsonSchema, ModalTabsLayout, TabElement } from "@shared/form-builder";
import type { PluginManifest } from "@shared/utils/plugins";

export const getLevelSupportedActions = () => ({
  enter: { title: "Entrar en la escena" },
  leave: { title: "Salir de la escena" }
});

export function getActionsSchema(actionsMap: PluginManifest["actions"] = {}): JsonSchema {
  return {
    type: "object",
    properties: {
      condition: {
        type: "string",
        title: "Condición",
        description: "Condición que se tiene que cumplir para que el elemento esté activo"
      },

      actions: {
        type: "object",
        title: "Acciones",
        description: "Acciones a realizar con este elemento",
        default: {},
        properties: Object.keys(actionsMap).reduce(
          (acc, actionId) => {
            acc[actionId] = {
              type: "array",
              title: actionsMap[actionId].title,
              description: actionsMap[actionId].description,
              default: [],
              items: {
                type: "object",
                title: "Acción",
                description: "Acción a realizar",
                properties: {
                  command: {
                    type: "string",
                    title: "Comando",
                    description: "Comando a ejecutar"
                  },
                  payload: {
                    type: "string",
                    title: "Datos",
                    description: "Datos adicionales para la acción"
                  }
                }
              }
            };
            return acc;
          },
          {} as Record<string, JsonSchema>
        )
      }
    }
  };
}

export function getActionsUISchema(
  actionsMap: PluginManifest["actions"] = {},
  forbiddenActions: string[],
  advanced = false
): ModalTabsLayout {
  return {
    type: "ModalTabsLayout",
    tabs: [
      ...(Object.keys(actionsMap).map((actionId) => ({
        title: actionsMap[actionId].title,
        elements: [
          {
            type: "Control",
            scope: `#/properties/actions/properties/${actionId}`,
            options: {
              customControl: "ActionPicker2",
              forbiddenActions,
              advanced
            }
          }
        ]
      })) as TabElement[]),
      ...(advanced
        ? ([
            {
              title: "Condiciones",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/condition"
                }
              ]
            }
          ] as TabElement[])
        : [])
    ]
  };
}
