import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Box, Tab, Tabs, styled } from "@mui/material";

import { useAppContext, useTranslation } from "@app/hooks";
import { type Path, useNavigate } from "@app/router";

const TabContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, "15%")
}));

export default function CreatePage() {
  const { setPageTitle, currentMatchedRoute } = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle(t("account.title"));
    return () => setPageTitle("");
  }, [setPageTitle, t]);

  const handleTabChange = (_event: React.SyntheticEvent, value: Path) => {
    navigate(value);
  };

  return (
    <Box>
      <Tabs centered onChange={handleTabChange} value={currentMatchedRoute}>
        <Tab
          label={t("account.profile.title")}
          value='/dashboard/account/profile'
          aria-controls='account-tab-profile'
        />
        <Tab label={t("account.plan.title")} value='/dashboard/account/plan' aria-controls='account-tab-plan' />
      </Tabs>

      <TabContent>
        <Outlet />
      </TabContent>
    </Box>
  );
}
