import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  ControlProps,
  RankedTester,
  isStringControl,
  rankWith,
} from "@jsonforms/core";
import { FieldWrapper } from "../common/FieldWrapper";
import { isCustomControl } from "../utils";
import styled from "@emotion/styled";

interface Props extends ControlProps {
  data: string;
  path: string;
  handleChange: (path: string, value: string) => void;
}

const StyledVideo = styled("video")({
  width: "100%",
  height: "300px",
  borderRadius: "4px",
});

export function VideoPreviewRenderer({
  label,
  description,
  data,
  visible,
  uischema,
  config,
}: Props) {
  if (!visible) {
    return null;
  }
  const pluginType = config.context?.manifest?.type;
  const pluginId = config.context?.manifest?.id;
  const url = config.context.editorContext?.renderAssetUrl(data, {
    pluginType,
    pluginId,
  });

  return (
    <FieldWrapper label={label} description={description} uiSchema={uischema}>
      <StyledVideo src={url} controls controlsList="nofullscreen" />
    </FieldWrapper>
  );
}

const tester: RankedTester = rankWith(15, (uiSchema, schema, context) => {
  return (
    isCustomControl(uiSchema, "VideoPreview") &&
    isStringControl(uiSchema, schema, context)
  );
});

export default {
  tester,
  renderer: withJsonFormsControlProps(VideoPreviewRenderer),
};
