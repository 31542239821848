import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, SxProps } from "@mui/material";
import { JsonForms } from "@jsonforms/react";
import { UISchemaElement } from "@jsonforms/core";
import type {
  JsonFormsRendererRegistryEntry,
  JsonFormsCellRendererRegistryEntry,
} from "@jsonforms/core";

import {
  materialRenderers,
  materialCells,
} from "@jsonforms/material-renderers";

import { JsonSchema, JsonUISchema } from "./types";

import Label from "./renderers/Label";
import Markdown from "./renderers/Markdown";

import TextControl from "./renderers/Text";
import NumberControl from "./renderers/Number";
import CheckboxControl from "./renderers/Checkbox";
import SelectControl from "./renderers/Select";
import HorizontalLayout from "./renderers/HorizontalLayout";
import ActionPicker2Control from "./renderers/ActionPicker2";
import ColorPickerControl from "./renderers/ColorPicker";
import ArrayControl from "./renderers/Array";
import FileControl from "./renderers/File";
import AudioPreviewControl from "./renderers/AudioPreview";
import VideoPreviewControl from "./renderers/VideoPreview";
import SliderControl from "./renderers/Slider";
import AssetPickerControl from "./renderers/AssetPicker";
import AssetListControl from "./renderers/AssetList";
import AssetSolutionListControl from "./renderers/AssetSolutionList";

import ModalLayout from "./renderers/ModalLayout";
import TabsLayout from "./renderers/TabsLayout";
import ToolbarLayout from "./renderers/ToolbarLayout";
import ModalTabsLayout from "./renderers/ModalTabsLayout";

import { getAjv } from "./ajv";

const customRenderers: JsonFormsRendererRegistryEntry[] = [
  Label,
  Markdown,
  TextControl,
  NumberControl,
  CheckboxControl,
  SelectControl,
  ActionPicker2Control,
  ColorPickerControl,
  AssetListControl,
  AssetSolutionListControl,
  HorizontalLayout,
  ModalLayout,
  TabsLayout,
  ToolbarLayout,
  ModalTabsLayout,
  ArrayControl,
  FileControl,
  AudioPreviewControl,
  VideoPreviewControl,
  SliderControl,
  AssetPickerControl,
];

const customCells: JsonFormsCellRendererRegistryEntry[] = [];

const ALL_RENDERERS = [...materialRenderers, ...customRenderers];
const ALL_CELLS = [...materialCells, ...customCells];

type FormData = unknown;

interface Props<T extends FormData> {
  sx?: SxProps;
  data: T;
  schema: JsonSchema;
  uiSchema?: JsonUISchema;
  onChange?: (data: T) => void;
  contextData?: Record<string, unknown>;
  live?: boolean;
}

export function Form<T extends FormData>({
  sx,
  data,
  schema,
  uiSchema,
  onChange,
  contextData = {},
  live,
}: Props<T>) {
  const [currentData, setCurrentData] = useState<T>(data);
  const [nextChangeFromOutside, setNextChangeFromOutside] = useState(true);

  useEffect(() => {
    if (!live) return;

    setCurrentData(data);
    setNextChangeFromOutside(true);
  }, [data, live]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key !== "Esc") {
      event.stopPropagation();
    }
  }, []);

  const currentSx = useMemo(
    () => ({
      ".MuiInputBase-root": {
        mb: 1,
      },
      ...sx,
    }),
    [sx]
  );

  const config = useMemo(
    () => ({
      context: { ...contextData, data: currentData },
      showUnfocusedDescription: true,
      trim: true,
      restrict: true,
    }),
    [contextData, currentData]
  );

  // TODO
  const i18n = useMemo(
    () => ({
      locale: "es",
    }),
    []
  );

  const handleChange = useCallback(
    ({ data }: { data: T }) => {
      // avoid first trigger on load
      if (nextChangeFromOutside) {
        setNextChangeFromOutside(false);
        return;
      }

      setCurrentData(data);
      onChange?.(data);
    },
    [nextChangeFromOutside, onChange]
  );

  return (
    <Box sx={currentSx} onKeyDown={handleKeyDown}>
      <JsonForms
        i18n={i18n}
        schema={schema}
        uischema={uiSchema as UISchemaElement} // TODO: Fix types. Check types.ts file
        data={currentData}
        renderers={ALL_RENDERERS}
        cells={ALL_CELLS}
        config={config}
        onChange={handleChange}
        ajv={getAjv()}
      />
    </Box>
  );
}
