import { CardContent, Chip, Typography, useTheme } from "@mui/material";

import { CompoundImage, ProjectCardWrapper } from "@app/components";
import { type GameDefinitionDto } from "@shared/api-client";
import { GameStatus } from "@app/types";
import { useTranslation } from "@app/hooks";

interface Props {
  template: GameDefinitionDto;
  onClick: () => void;
}

export function TemplateCard({ template, onClick }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ProjectCardWrapper onClick={onClick}>
      <CompoundImage bg={template.bgImage} fg={template.fgImage} title={template.name} />
      {template.status === GameStatus.DRAFT && (
        <Chip className='badge' label={t("create.comingSoon")} variant='filled' color='info' size='small' />
      )}
      <div className='CardFooter'>
        <CardContent>
          <Typography variant='h6' mb={theme.spacing(1)}>
            {template.name}
          </Typography>
          <Typography variant='subtitle1'>{template.category || "-"}</Typography>
        </CardContent>
      </div>
    </ProjectCardWrapper>
  );
}
