import { createContext, isValidElement, useCallback, useEffect, useState, type ReactNode } from "react";
import { get } from "object-path";
import reactStringReplace from "react-string-replace";
import { FullScreenLoading } from "@app/components";

import dayjs from "dayjs";
import "dayjs/locale/es"; // TODO: import dinamically

export const defaultLanguage = "es";

export type GetTranslationCb = (
  key: string,
  replacements?: Record<string, unknown>,
  defaultValue?: string
) => NonNullable<ReactNode>;

export type GetTranslationStringCb = (
  key: string,
  replacements?: Record<string, unknown>,
  defaultValue?: string
) => string;

interface LanguageContextType {
  language: string;
  loading: boolean;
  setLanguage: (language: string) => void;
  getTranslation: GetTranslationCb;
  getTranslationString: GetTranslationStringCb;
  getTranslationJsx: (key: string, defaultValue?: string) => NonNullable<ReactNode>;
}

interface LanguageProviderProps {
  initialLanguage?: string;
  children: React.ReactNode;
}

export const LanguageContext = createContext<LanguageContextType>({
  language: defaultLanguage,
  loading: true,
  setLanguage: () => {
    throw new Error("setLanguage not ready");
  },
  getTranslation: (key: string) => key,
  getTranslationString: (key: string) => key,
  getTranslationJsx: (key: string) => key
});

export const LanguageProvider = ({ children, initialLanguage = defaultLanguage }: LanguageProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState<string>(initialLanguage);
  const [translations, setTranslations] = useState<Record<string, unknown>>({});

  useEffect(() => {
    setLoading(true);

    import(`../locales/${language}.tsx`)
      .then((module) => {
        setTranslations(module.default);
        setLoading(false);

        // Setup dayjs locale
        dayjs.locale(defaultLanguage);
      })
      .catch((err) => {
        setTranslations({});
        setLoading(false);
        throw new Error(
          `Failed loading language "${language}": ${err instanceof Error ? err.toString() : JSON.stringify(err)}`
        );
      });
  }, [language, setLanguage]);

  // TODO: Add support for pluralization
  const getTranslation = useCallback(
    (
      key: string,
      replacements: Record<string, unknown> = {},
      defaultValue = `{${key}}`
    ): NonNullable<ReactNode> | string => {
      const translation = get<unknown>(translations, key, defaultValue);

      if (isValidElement(translation)) {
        // If the translation is a React element, return it as is
        return translation;
      }

      if (typeof translation === "function") {
        // If the translation is a function, call it with the replacements
        return translation(replacements);
      }

      if (typeof translation === "string") {
        const translated =
          reactStringReplace(translation, /\{\{([^}]+)\}\}/g, (match: string) => {
            return get(replacements, match, `{${match}}`)?.toString();
          }) || translation;

        return translated;
      }

      return key.toString();
    },
    [translations]
  );

  const getTranslationString = useCallback(
    (key: string, replacements: Record<string, unknown> = {}, defaultValue = `{${key}}`): string => {
      return getTranslation(key, replacements, defaultValue).toString();
    },
    [getTranslation]
  );

  const getTranslationJsx = useCallback(
    (key: string, defaultValue = `{${key}}`): NonNullable<ReactNode> => {
      return get(translations, key, defaultValue);
    },
    [translations]
  );

  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <LanguageContext.Provider
      value={{ language, setLanguage, getTranslation, getTranslationString, getTranslationJsx, loading }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
