import { Box, type SxProps, styled, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  title?: string;
  fg?: string;
  bg?: string;
  sx?: SxProps;
}

const BgBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  backgroundPosition: "center",
  backgroundSize: "cover",
  padding: "5% 10%",

  "&.loaded": {
    animation: "fadeIn 0.5s"
  }
}));

const FgImg = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  objectPosition: "center center"
});

export function CompoundImage({ title, fg, bg, sx }: Props) {
  const [fgLoaded, setFgLoaded] = useState(false);
  const [bgLoaded, setBgLoaded] = useState(false);
  const [retry, setRetry] = useState(0);

  useEffect(() => {
    if (fg) {
      setFgLoaded(false);
      const img = new Image();
      img.onload = () => setFgLoaded(true);
      img.onerror = () => setTimeout(() => setRetry((r) => r + 1), 1000);
      img.src = fg;
    } else {
      setFgLoaded(true);
    }
  }, [fg, retry]);

  useEffect(() => {
    if (bg) {
      setBgLoaded(false);
      const img = new Image();
      img.onload = () => setBgLoaded(true);
      img.onerror = () => setTimeout(() => setRetry((r) => r + 1), 1000);
      img.src = bg;
    } else {
      setBgLoaded(true);
    }
  }, [bg, retry]);

  const classes = ["CompoundImage", "BackgroundImage"];
  if (bgLoaded && fgLoaded) classes.push("loaded");

  return (
    <BgBox sx={{ ...sx, backgroundImage: bg && bgLoaded ? `url(${bg})` : "gray" }} className={classes.join(" ")}>
      {fgLoaded && fgLoaded ? fg && <FgImg src={fg} className='ForegroundImage' alt={title} /> : <CircularProgress />}
    </BgBox>
  );
}
