import type { GameDataInfoDto } from "@shared/api-client";
import type { JsonSchema, JsonUISchema } from "@shared/form-builder";

export const getGameFlowSchema = (gameInfo?: GameDataInfoDto): JsonSchema => ({
  type: "object",
  title: "Flujo del juego",
  properties: {
    initialLevelId: {
      type: "string",
      title: "Nivel de comienzo",
      description: "El juego comenzará en este nivel.",
      oneOf:
        gameInfo?.levels.map((level) => ({
          const: level,
          title: level
        })) || []
    },
    finishLevelId: {
      type: "string",
      title: "Nivel final (ganador)",
      description: "El juego se considerará completado y ganado cuando el jugador llegue a este nivel.",
      oneOf:
        gameInfo?.levels.map((level) => ({
          const: level,
          title: level
        })) || []
    },
    gameOverLevelId: {
      type: "string",
      title: "Nivel de fin de juego",
      description:
        "El juego saltará automáticamente a este nivel cuando se acabe el tiempo (si está definido) y la partida se considerará perdida. Del mismo modo, si el usuario llega a este nivel por otro medio también se considerará como una partida perdida.",
      oneOf:
        gameInfo?.levels.map((level) => ({
          const: level,
          title: level
        })) || []
    }
  },
  required: ["initialLevelId", "finishLevelId"]
});

export const GameFlowUISchema: JsonUISchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/initialLevelId"
    },
    {
      type: "Control",
      scope: "#/properties/finishLevelId"
    },
    {
      type: "Control",
      scope: "#/properties/gameOverLevelId"
    }
  ]
};
