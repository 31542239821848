import { useEffect, useMemo, useState } from "react";

interface Props {
  longTouchTimeout?: number;
  onLongTouch?: (touches: number, event: TouchEvent) => void;
}

export function useDevice(props: Props = {}) {
  const { onLongTouch, longTouchTimeout = 500 } = props;

  const [isTouch, setIsTouch] = useState("ontouchstart" in window);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (isTouch && onLongTouch) {
      const longTouchHandler = (event: TouchEvent) => {
        const touchEnd = () => {
          clearTimeout(timer);
          window.removeEventListener("touchend", touchEnd);
        };

        const timer = setTimeout(() => {
          onLongTouch(event.touches.length, event);
          window.removeEventListener("touchend", touchEnd);
        }, longTouchTimeout);

        window.addEventListener("touchend", touchEnd);
        window.addEventListener("touchmove", touchEnd); // cancel on touchmove
      };

      window.addEventListener("touchstart", longTouchHandler);

      return () => {
        window.removeEventListener("touchstart", longTouchHandler);
      };
    }
  }, [isTouch, longTouchTimeout, onLongTouch]);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleTouch = () => {
      setIsTouch(true);
    };
    window.addEventListener("touchstart", handleTouch);
    return () => window.removeEventListener("touchstart", handleTouch);
  }, []);

  return useMemo(
    () => ({
      isTouch,
      isSmallScreen
    }),
    [isSmallScreen, isTouch]
  );
}
