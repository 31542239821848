import { useEffect, useState, type FormEvent, type ReactNode } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useTheme
} from "@mui/material";

import { useTranslation } from "@app/hooks";

interface Props {
  open: boolean;
  title: string;
  initialValue?: string;
  message: ReactNode;
  onSubmit: (value: string) => void;
  onCancel: () => void;
  fieldLabel?: string;
  confirmText?: string;
  cancelText?: string;
}

export function Prompt(props: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = useState(props.initialValue ?? "");

  const {
    open,
    title,
    message,
    fieldLabel,
    onSubmit,
    onCancel,
    confirmText = t("common.confirm"),
    cancelText = t("common.cancel")
  } = props;

  useEffect(() => {
    setValue(open ? props.initialValue ?? "" : "");
  }, [props.initialValue, open]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      onKeyDown={(e) => e.stopPropagation()}
      PaperProps={{
        component: "form",
        onSubmit: async (e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          onSubmit(value);
          e.currentTarget.reset();
        }
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <Box display='flex' flexDirection='row' p={theme.spacing(0, 4, 2, 4)} columnGap={theme.spacing(4)}>
        <TextField
          autoFocus={open}
          margin='dense'
          label={fieldLabel}
          fullWidth
          name='input'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          autoComplete='off'
          required
        />
      </Box>
      <DialogActions>
        <Button onClick={onCancel} color='primary' variant='text'>
          {cancelText}
        </Button>
        <Button type='submit' color='primary' variant='contained'>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
