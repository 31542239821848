import { withJsonFormsControlProps } from "@jsonforms/react";
import { LabelProps, RankedTester, rankWith } from "@jsonforms/core";
import { FieldWrapper } from "../common/FieldWrapper";
import { Label } from "../types";

interface Props extends LabelProps {
  data: string;
  path: string;
  handleChange: (path: string, value: string) => void;
}

export function LabelRenderer({ visible, uischema }: Props) {
  const layout = uischema as Label;

  return (
    visible && <FieldWrapper description={layout.text} uiSchema={uischema} />
  );
}

const tester: RankedTester = rankWith(
  10,
  (uiSchema) => uiSchema.type === "Label"
);

export default {
  tester,
  renderer: withJsonFormsControlProps(LabelRenderer),
};
