import { createAjv } from "@jsonforms/core";
import { Ajv } from "ajv";

let _ajv: Ajv;

export function getAjv() {
  if (!_ajv) {
    // Create Ajv instance with custom keywords
    // @ts-expect-error: TODO: Returned type is not compatible with Ajv ???
    _ajv = createAjv({
      useDefaults: true,
      coerceTypes: true,
      multipleOfPrecision: 2,
      formats: {
        "data-url":
          /^data:([a-z]+\/[a-z0-9-+.]+)?;base64,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*?)$/,
      },
      keywords: [
        {
          /**
           * Generates a random ID if not set. Requires the "default" keyword to be set as ""
           */
          keyword: "generateId",
          type: "string",
          schemaType: ["boolean", "string"],
          modifying: true,
          validate: (value, data, _schema, ctx) => {
            if (!value || data) {
              // Do not modify if already set
              return true;
            }

            const { parentData, parentDataProperty } = ctx || {};

            if (parentData && parentDataProperty) {
              // "value" is used as prefix
              parentData[parentDataProperty] =
                (typeof value === "string" ? value : "") +
                Math.random().toString(36).toLowerCase().substring(2, 6);
            }

            return true;
          },
        },
      ],
    });
  }

  return _ajv;
}
