import { createRef, useEffect, useState } from "react";

export function useViewportTools<T extends HTMLElement>() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = createRef<T>();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIsVisible(entry.isIntersecting), { threshold: 0.5 });
    const current = ref.current;

    if (current) {
      observer.observe(current);
      return () => observer.unobserve(current);
    }
  }, [ref]);

  return { ref, isVisible };
}
