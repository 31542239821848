import { useBackend } from "@app/hooks";
import { FullScreenLoading } from "@app/components";
import { Navigate } from "@app/router";

export default function HomePage() {
  const { isBackendReady, loggedInUser } = useBackend();

  // TODO: use suspense
  if (!isBackendReady) {
    return <FullScreenLoading />;
  }

  if (loggedInUser) {
    return <Navigate to='/dashboard/create' />; // TODO: go to /dasboard once it's done
  }

  // Redirect to login
  return <Navigate to='/auth/login' />;
}
