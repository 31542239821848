import { useCallback, useMemo, type ReactNode } from "react";

import {
  Button,
  ButtonProps,
  CircularProgress,
  styled,
  SxProps,
  Tooltip,
} from "@mui/material";

interface ToolbarButtonProps {
  icon?: ReactNode;
  title?: ReactNode;
  disabled?: boolean;
  active?: boolean;
  loading?: boolean;
  focusable?: boolean;
  color?: ButtonProps["color"];
  sx?: SxProps;
  onClick?: () => void;
}

const StyledButton = styled(Button)({
  whiteSpace: "nowrap",
});

export function ToolbarButton({
  icon,
  title,
  disabled,
  onClick,
  active,
  loading,
  focusable,
  color,
  sx,
}: ToolbarButtonProps) {
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!focusable) {
        e.preventDefault();
      }

      // Prevent space from triggering a click
      // so it doesn't collide with paning
      if (e.key === " ") {
        e.preventDefault();
      }
    },
    [focusable]
  );

  const sxProps = useMemo(
    () => ({
      ...sx,
      color: active ? "background.secondary" : "text.secondary",
      backgroundColor: active ? "background.secondary" : "background.paper",
      border: "none",
      "&:hover": {
        backgroundColor: "background.secondary",
        color: "background.paper",
      },
    }),
    [active, sx]
  );

  const button = (
    <StyledButton
      variant="contained"
      disabled={disabled}
      tabIndex={focusable ? 0 : -1}
      onClick={!disabled && !loading ? onClick : undefined}
      color={color}
      onKeyDown={handleKeyDown}
      sx={sxProps}
    >
      {!loading ? icon || title : <CircularProgress size={20} />}
    </StyledButton>
  );

  return title && icon ? (
    <Tooltip title={title} placement="top">
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
}
