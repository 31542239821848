import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useParams } from "@app/router";
import { useEditor } from "@app/editor";

export default function DashboardLayout() {
  const { setVersion } = useEditor();
  const { version } = useParams("/dashboard/projects/:uuid/v/:version");

  useEffect(() => {
    setVersion(version);
  }, [setVersion, version]);

  return (
    <>
      <Outlet />
    </>
  );
}
