import pick from "lodash/pick";

import { useEditor } from "@app/editor";
import { useTranslation } from "@app/hooks";
import { getTransitionsSchema, getTransitionsUISchema } from "@app/schemas/transitions.schema";
import { getActionsSchema, getActionsUISchema, getLevelSupportedActions } from "@app/schemas/actions.schema";

import AnimationIcon from "@mui/icons-material/Animation";
import ActionsIcon from "@mui/icons-material/AdsClick";

import { EditorButtonGroup } from "./EditorButtonGroup";
import { ToolbarButtonModalForm } from "./ToolbarButtonModalForm";
import { useMemo } from "react";

interface Props {
  advanced?: boolean;
  forceOpenActions?: boolean;
  forceOpenAnimations?: boolean;
  onCloseProperties?: () => void;
}

export function LevelToolbarButtons(props: Props) {
  const { advanced, forceOpenActions, forceOpenAnimations, onCloseProperties } = props;

  // To be used within the form context
  const editorContext = useEditor();
  const { t, ts } = useTranslation();
  const { level } = editorContext;

  const levelSupportedActions = useMemo(getLevelSupportedActions, []);

  return (
    <>
      <EditorButtonGroup>
        <ToolbarButtonModalForm
          icon={<AnimationIcon color='success' />}
          buttonTitle={ts("editor.levelTransitions")}
          modalTitle={t("editor.levelTransitionsModalTitle")}
          data={level?.data?.transitions || { in: {}, out: {} }}
          schema={getTransitionsSchema()}
          uiSchema={getTransitionsUISchema()}
          onSubmit={async (transitions) => {
            level?.update({ transitions });
            return true;
          }}
          forceOpen={forceOpenAnimations}
          onClose={onCloseProperties}
          formContext={{ t, editorContext }}
        />
        <ToolbarButtonModalForm
          icon={<ActionsIcon color='error' />}
          buttonTitle={ts("editor.levelActions")}
          modalTitle={t("editor.levelActionsModalTitle")}
          data={pick(level?.data, ["actions"])}
          schema={getActionsSchema(levelSupportedActions)}
          uiSchema={getActionsUISchema(levelSupportedActions, ["go-to-level"], advanced)}
          onSubmit={async ({ actions }) => {
            level?.update({ actions });
            return true;
          }}
          forceOpen={forceOpenActions}
          onClose={onCloseProperties}
          formContext={{ t, editorContext }}
        />
      </EditorButtonGroup>
    </>
  );
}
