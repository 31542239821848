import { Navigate } from "@app/router";
import { PageLoading } from "@app/components";
import { useEditor } from "@app/editor";

export default function ProjectEditMainPage() {
  const { uuid, version } = useEditor();

  if (!version) {
    return <PageLoading />;
  }

  // Redirect to editor
  return <Navigate to='/dashboard/projects/:uuid/v/:version' params={{ uuid, version }} replace />;
}
